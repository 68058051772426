.teaser {
    width: 100%;
    position: relative;
    margin: 50px 0 24px;

    .teaser-items {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        width: 100%;
        @include min-screen($screen__ml) {
            width: 75%;
        }
    }

    .teaser-item {
        position: relative;
        width: 100%;
        margin-bottom: 16px;
        @include round-drop-shadow;
        @include min-screen($screen__xs) {
            width: 48%;
        }
        @include min-screen($screen__m) {
            width: 31.5%;
        }
    }

    h3 {
        bottom: 0;
        width: 100%;
        z-index: 10;
        margin: 0;
        @include min-screen($screen__xs) {
            position: absolute;
        }
    }

    .teaser-item-4 h3,
    .teaser-item-5 h3,
    .teaser-item-6 h3,
    .teaser-item-7 h3 {
        z-index: 30;
        background: $color-light-red;
        width: 100%;
        left: auto;
        font-size: $heading__font-size__l;
        @include min-screen($screen__xs) {
            width: 80%;
            left: 10%;
            font-size: $heading__font-size__m;
        }
       
    }

    img {
        z-index: 20;
        position: relative;
        margin: 0 auto;
        width: 100%;
        display: none;
        @include min-screen($screen__xs) {
            display: block;
        }
    }

    .teaser-items-special {
        width: 100%;
        margin-bottom: 30px;
        
        @include min-screen($screen__ml) {
            width: 23%;
            position: absolute;
            right: 0;
            top: 0;
            margin-bottom: 0;
        }
        li {
            width: 100%;
            margin: 0;
        }

        .img-rwd {
            
            @include min-screen($screen__ml) {
                display: none;
            }
            img {
                display: block;
            }
        }

        .img-desktop {
            display: none;
            @include min-screen($screen__ml) {
                display: block;
            }
        }
   
    }
}

.home-page-contact {
    background: $color-light-red;
    color: $color-white;
    margin-bottom: 30px;
    @include min-screen($screen__s) {
        background: $color-light-red url(../images/assets/bgd-home-contact.jpg) center top no-repeat;
        padding-bottom: 10px;
    }
    .newsletter .label {
        display: none;
    }
    .supp-tel {
        display: block;
        margin: -8px 0 0;
        position: relative;
        padding-left: 28px;
        line-height: 2.25em;
        @include min-screen($screen__s) {
            font-size: $heading__font-size__m;
        }
        @include min-screen($screen__xl) {
            font-size: $heading__font-size__xl;
           
        }
        &:before {
            font-size: $heading__font-size__xl;
            margin: -4px 0 0 -10px;
            background: none;
            @include min-screen($screen__xs) {
                margin: 8px 0 0 -10px;
            }
            @include min-screen($screen__s) {
                margin: 8px 0 0 -10px;
                font-size: $p__font-size__l;
            }
            @include min-screen($screen__xl) {
                font-size: $heading__font-size__xl;
                margin: 7px 0 0 -10px;
            }
        }
    }

    h3,
    h5,
    a,
    .validation-advice {
        color: #fff;
    }

    .h2.legend {
        border-color: rgba(255, 255, 255, 0.3);
        width: 100%;
    }

    p {
        font-size: $p__font-size__l;
    }
}

.footer-seo-text {
    @include two-column-text;
    p {
        break-inside: avoid;
    }
}

.home-news {
    margin: 20px 0 60px;
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    @include min-screen($screen__xxs) {
        margin: 30px 0 30px;
    }
    .legend {
        width: 100%;
        .more  {
            display: block;
            margin-top: 5px;
            @include min-screen($screen__xs) {
                display: inline-block;
                margin-left: 10px;
            }
            a {
                font-weight: $font-weight__regular;
                text-decoration: underline;
            }
        }
    }
    .feature {
        width: 100%;
        margin-bottom: 30px;
        padding-bottom: 30px;
        border-bottom: 1px solid $color-border;
        @include min-screen($screen__xxs) {
            width: 48%;
            margin-bottom: 20px;
            display: flex;
            flex-flow: column nowrap;
            align-items: flex-start;
        }
        @include min-screen($screen__m) {
            width: 22.5%;
            border-bottom: none;
        }
        .feature-date {
            font-size: $p__font-size__m;
            margin: 10px 0 5px;
            display: block;
        }
        h3 {
            width: 100%;
        }
        p { 
            width: 100%;
            margin-bottom: 10px;
        }
        .button {
            @include min-screen($screen__xxs) {
                margin-top: auto;
            }
        }
    }
}

.home-page-main {
    .teaser {
        margin: 0px 0 13px;
    }
}