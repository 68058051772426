.home-mediathek-teaser {
    position: relative;
    background: linear-gradient(darken(#f4f4f4, 5%), #f4f4f4);
    position: relative;
    padding-top: 5px;
    hyphens: none;

    h3 {
        font-size: $heading__font-size__l;
        font-weight: $font-weight__semibold;
        line-height: 1.25em;
        margin-bottom: 30px;
        padding-right: 20px;
        span {
            text-transform: uppercase;
            color: $color-blue;
        }
    }
    .btn-minimize {
        position: absolute;
        right: 10px;
        top: 10px;
        z-index: 3;
        cursor: pointer;

        i {
          margin-top: 6px;
        }

        &.icn-active {
          .fa-minus:before {
            content: "\f067" !important; // fa-plus
          };
        }
    }
    .light-headline {
        width: 90%;
        line-height: 1.25em;
        margin: 10px auto 30px;
    }
    .section-articledesigner {
        padding-bottom: 10%;
        position: relative;
        padding-top: 10px;
        z-index: 2;
        @include min-screen($screen__s) {
            &.lazyloaded {
                background: url('../images/assets/bgd-articledesigner-home.png') 20px 90px no-repeat;
            }
            background-size: 35%;
        }
        @include min-screen($screen__xl) {
            background-size: 44%;
        }
        .right-col {
            width: 100%;
            margin-top: 20px;
            text-align: center;
            @include min-screen($screen__s) {
                width: 58%;
                margin-left: 42%;
                text-align: left;
            }
            @include min-screen($screen__xl) {
                width: 46%;
                margin-left: 51%;
            }
            ul {
                margin: 0 auto;
                width: 60%;
                @include min-screen($screen__s) {
                    margin: 0 0 0 52px;
                    width: 70%;
                }
                li {
                    margin-bottom: 15px;
                    position: relative;
                }
            }
            .button {
                @include min-screen($screen__s) {
                    margin: 10px 0 0 50px;
                }
            }
        }
    }
    .section-logopreview {
        background-color: $color-grey3;
        &.lazyloaded {
            background-image: url('../images/assets/bgd-logopreview-home.jpg');
        }
        background-position: left top;
        background-repeat: no-repeat;
        position: relative;
        padding: 5px 0 20px;
        z-index: 1;
        margin-top: -4%;
        @include min-screen($screen__xl) {
            margin-top: -8%;
            &.lazyloaded {
                background-image: url('../images/assets/bgd-mylogo-1.png'), url('../images/assets/bgd-logopreview-home.jpg');
            }
            background-position: 20px 150px, left center;
        }
        .touch & {
            display: none;
        }
        .light-headline {
            @include min-screen($screen__xl) {
                margin-top: 55px;
            }
        }
    }

    #toggle-articledesigner {
        visibility: visible;
        max-height: 1000px;
        -webkit-transition: all 0.3s;
           -moz-transition: all 0.3s;
                transition: all 0.3s;
        opacity: 1;
    }

    &.active {
        #toggle-articledesigner {
            visibility: hidden;
            max-height: 0;
            opacity: 0;
            -webkit-transition: all 0.3s;
               -moz-transition: all 0.3s;
                    transition: all 0.3s;
        }
        .section-logopreview {
            margin-top: -11.5%;
            @include max-screen($screen__xs) {
                margin-top: -13.5%;
            }
            @include max-screen($screen__m) {
                margin-top: -13%;
            }
            @include min-screen($screen__xl) {
                margin-top: -134px;
            }
        }
    }
}
