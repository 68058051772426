/* normal grid product image */
a.product-image {
    position: relative;
    display: block;
    opacity: 1;
}

/* zoom product image ("details") */
a.product-link {
    position: relative;
    display: block;
}

/* fancybox close button */
#logopreview-fancybox-close {
    float: right;
}

/** global logopreview spinner **/
div.logopreview-waiting {
    position: absolute;
    width: 100%;
    height: 100%;
    background: #dfdfdf url("../images/loader-1.gif") no-repeat 50% 50%;
    opacity: 0.5;
    z-index: 10;
}

#toggle-logopreview-box {
    display: none;

    &.active {
        display: block;
    }
}

/* logopreview upload element */
#logopreview-wrapper {
    /** main dropzone **/
    .logopreview-dropzone-wrapper {
        padding: 10px;
        .lazyloaded & {
            background: #fff url('../images/assets/bgd-mylogo-arrow.png') -40px 30px no-repeat;
        }
        width: 100%;
        margin: 0 auto;
        border: 12px solid rgba(1, 1, 1, 0.8);
        border-radius: 15px;
        box-shadow: inset 0 0 20px #c7c3c3;
    }

    #logopreview-dropzone {
        width: 98%;

        margin: 0 auto;
        display: none;
        position: relative;
        display: block !important;
        height: auto;
        border: 6px dashed $color-border;
        padding: 20px 0;
        border-radius: 15px;

        /** uploading spinner */
        div.logopreview-waiting {
            margin-top: -35px;
        }

        h4 {
            text-align: center;
            color: $color-red;
            font-size: $heading__font-size__xl;
        }

        /** uploaded file **/
        img {
            height: auto;
            max-height: 70px;
            max-width: 80%;

            /** grey upload icon **/
            &:before {
                content: "";
                display: block;
                position: absolute;
                width: 24px;
                height: 28px;
                margin-left: -37px;
                margin-top: 2px;
                /*background: url("../images/assets/sprt-logopreview.png") no-repeat 0px 0px;*/
            }
        }

        & > span.logopreview-upload-info {
            display: none;
        }

        /** remove button */
        #logopreview-remove {
            display: none;
            position: absolute;
            bottom: 1px;
            right: -3px;
            cursor: pointer;
            border-radius: 4px;
            color: #666;
            &:hover {
              color: #333;
            }
        }

        /** transparent toggle */
        div#logopreview-use-transparent {
            position: absolute;
            left: 0px;
            bottom: 0px;
            margin-top: 0px;

            input {
                display: none;
            }

            label {
                cursor: pointer;

                &:hover {
                    color: #cd0c1a;
                }
            }

            &.logopreview-use-variant-transparent {
                label {
                    opacity: .7;
                }
            }
        }

        &.logopreview-dropzone-has-file {
            span.logopreview-upload-info {
                display: block;
            }

            h4.logopreview-no-upload-message {
                display: none;
            }

            #logopreview-remove {
                display: block;
            }

            & > span {
                text-align: center;
                overflow: hidden; /* cut very long filenames */

                img {
                    display: inline-block;
                    margin-bottom: 5px;
                }

                span {
                    display: block;
                }

                &:before {
                    display: none;
                }
            }
        }

        &.qq-upload-drop-area-active {
            color: white;
            border-color: white;
            background-color: $color-red;

            /** white upload icon **/
            span:before {
                background-position: 0 -120px;
            }
        }
    }

    /** uploader **/
    #logopreview-upload-handler {
        .button {
            float: none !important;
        }

        ul.qq-upload-list {
            display: none;
        }

        /** native qq uploader drop area **/
        div.qq-upload-drop-area {
            display: none !important;
        }

        div.qq-uploader {
            text-align: center;
            padding-top: 20px;
        }
    }
}

/** 2d css logo images **/

div.logopreview-logo-wrapper {
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;

    div.logopreview-logo {
        position: absolute;
        display: block;
        background-repeat: no-repeat !important;
        background-position: center;
        background-size: contain;
        width: 100%;
        height: 100%;

        /** workaround for jagged edges in firefox **/
        outline: 1px solid transparent;
    }
}

/* logopreview teaser for grid, list, view */
.logopreview-teaser {
    background: $color-grey3 url("../images/assets/bgd-mylogo-1.png") center 90px no-repeat;
    background-size: 80%;
    padding: 20px 12px !important;

    @include inset-drop-shadow-dark;

    .page-with-filter &,
    .page-products & {
        background: $color-grey3 url("../images/assets/bgd-mylogo-1.png") center 70px no-repeat;
        background-size: 200px;
        padding: 12px 20px !important;
        h2,
        .product-teaser-title {
            text-align: left;
            min-height: 65px;
        }
        .logopreview-dropzone-wrapper {
            background: #fff url('../images/assets/bgd-mylogo-arrow-2.png') center -45px no-repeat !important;
        }
        #logopreview-dropzone {
            padding: 0 !important;
            height: 150px !important;
            overflow: hidden;


            h4 {
                margin-top: 50px;
            }

            img {
                margin-top: 20px;
            }
        }
    }

    .catalog-product-view & {
        background: $color-grey3 url("../images/assets/bgd-mylogo-1.png") center 70px no-repeat;
        background-size: 30%;
        padding: 12px 10px !important;
        position: relative;
        @include min-screen($screen__s) {
            background-size: 70%;
        }

        .logopreview-teaser__heading {
            text-decoration: none !important;
            cursor: pointer;
            padding-bottom: 10px;

            &:after {
                color: #000;
                position: absolute;
                right: 5px;
                top: 14px;
                width: 21px;
                height: 21px;
                line-height: 1.9;
            }
        }

        #logopreview-dropzone {
            padding: 35px 0 20px 0 !important;
        }

        .button {
            font-size: $p-l;
            line-height: 1.25em;
            padding: 10px;
        }
    }

    h3 {
        color: #fff;
        line-height: 1.25em !important;

        text-align: left;

        @include max-screen($screen__s) {
          min-height: 65px;
        }

        .catalog-product-view & {
            width: 90%;
            margin-bottom: 0;
        }
    }

    a {
        color: #fff;
        font-size: $p-l;
        text-decoration: underline !important;

        .catalog-product-view & {
            font-size: $p__font-size__l;
        }
    }

    i {
        margin-right: 5px;
    }

    .logopreview-dropzone-wrapper {
        margin-top: 94px !important;
        background: #fff url("../images/assets/bgd-mylogo-arrow.png") -40px 30px no-repeat;
        border: 8px solid rgba(1, 1, 1, 0.8) !important;

        .page-products &,
        .catalog-product-view & {
            background: #fff url("../images/assets/bgd-mylogo-arrow-2.png") center -45px no-repeat !important;
        }

        .catalog-product-view & {
            margin-top: 110px !important;
        }
    }

    #logopreview-dropzone {
        padding: 75px 0 !important;
        border: 3px dashed $color-border !important;

        h4 {
            text-align: center;
            color: $color-red;
            font-size: $heading__font-size__m !important;
        }
    }

    .touch & {
        display: none !important;
    }
}

/* home page widget */
.home-mediathek-teaser {
    #logopreview-wrapper {
        width: 80%;
        margin: auto;
        @include min-screen($screen__s) {
            width: 40%;
        }
        #logopreview-upload-handler {
            min-height: 70px;
        }
    }
}
