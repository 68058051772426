.page-layout-without_headfoot {
    .page-footer {
        border-top: none;
    }
}
.page-footer {
    border-top: 1px solid $color-border;
    padding-top: 20px;
    padding-bottom: 50px;
    margin-top: 40px !important;
    .onestepcheckout-index-index & {
        border-top: none;
        float: left;
        width: 100%;
    }
    p,
    li {
        font-size: $p__font-size__l;
    }
    .footer.links {
        display: none;
    }
}
.footer-teaser-list {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
}
.footer-teaser-item {
    width: 100%;
    @include min-screen($screen__xs)  {
        width: 48%;
    }
    @include min-screen($screen__ml) {
        width: 22.5%;
    }
    h3 {
        margin-bottom: 30px;
    }
    &.service {
        .iconed {
            border-bottom: 1px solid $color-border;
            margin-bottom: 20px;
            margin-top: -15px;
            li {
                display: flex;
                align-items: baseline;
                border-top: 1px solid $color-border;
                padding: 10px 0 0 0;
                hyphens: none;
            }
        }
    }
    &.trusted-ekomi {
        h3 {
            border-bottom: 1px solid $color-border;
            padding-bottom: 15px;
            margin-bottom: 20px;
        }
        .shop-icons {
            width: 100%;
            display: flex;
            flex-flow: row nowrap;
            margin-bottom: 20px;
            li {
                width: 50%;
            } 
            .link-trusted {
                display: block;
                height: 80px;
                background: url('../images/assets/icn-trusted-80.png') left top no-repeat;
                background-size: contain;
            }
            .link-bvh {
                display: block;
                height: 90px;
                background: url('../images/assets/bvh-logo.png') left top no-repeat;
                background-size: contain;
                margin-top: -7px;
            }
        }
        #eKomiWidget_default {
            margin-bottom: 10px;
        }
    }
}
.footer-categories {
    border-top: 1px solid $color-border;
    padding-top: 30px;
    ul {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        li {
            width: 100%;
            text-align: center;
            @include min-screen($screen__xs)  {
                width: 50%;
                text-align: left;
            }
            @include min-screen($screen__ml) {
                width: 22.5%;
            }
            &:before {
                content: '\2022';
                margin-right: 3px;
            }
        }
        a {
            text-decoration: none;
        }
    } 
}
.footer-links-address {
    border-top: 1px solid $color-border;
    padding-top: 30px;
    text-align: center;
    .onestepcheckout-index-index & {
        float: left;
        width: 100%;
    }
    ul li,
    p {
        display: inline-block;
        font-size: $p__font-size__m;
    }
    ul {
        margin-bottom: 0;
        li:after {
            content: '\007C';
            margin-left: 3px;
        }
        li:last-child:after {
            content: '';
        }
        li a {
            text-decoration: none;
        }
    }
}
.footer-price {
    border-top: 1px solid $color-border;
    padding-top: 20px;
    text-align: center;
    p {
        font-size: $p__font-size__s;
        margin-bottom: 5px;
    }
}
