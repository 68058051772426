.slide-slider-container {
    width: 100%;
    position: relative;
    margin-bottom: 0;
    overflow: hidden;
    .slide-slider {
        margin-bottom: 40px;
    }
    .slick-initialized {
        .slide {
            display: inline-block !important;
        }
    }
    .wrapper-content {
        position: static;
        & > a {
            @include min-screen($screen__l) {
                display: block;
                height: 100%;
                overflow: hidden;
                position: absolute;
                left: 0;
                right: 0;
            }
        }
        @include min-screen($screen__s) {
            position: relative;
        }
    }

    .slick-next,
    .slick-prev {
        opacity: 0;
        background: rgba(0, 0, 0, 0.3);
        border-radius: 0;
        border: none;
        height: 100%;
        width: 35px;
        z-index: 10;
        cursor: pointer;
        font-size: 0;
        transition: opacity 0.25s ease-in-out;
        &:before {
            top: 42%;
            left: 13px;
        }
    }

    .slick-prev:before {
        left: 7px;
    }

    @at-root .slide-slider-container:hover .slick-next {
        opacity: 1;
        transition: opacity 0.25s ease-in-out;
    }
    @at-root .slide-slider-container:hover .slick-prev {
        opacity: 1;
        transition: opacity 0.25s ease-in-out;
    }

    .slide {
        position: relative;
        & + .slide {
            display: none;
        }
        .slider-content {
            padding: 0 10px 60px;
            z-index: 4;
            position: relative;
            min-height: 700px;
            width: 100%;
            @include min-screen($screen__xxs) {
                min-height: 740px;
            }
            @include min-screen($screen__s) {
                width: 50%;
                min-height: 400px;
                max-height: 400px;
            }
            @include min-screen($screen__l) {
                width: 45%;
                padding: 0;
            }
        }
        .right-bgd {
            width: 100%;
            position: absolute;
            right: 0;
            height: 100%;
            max-height: 200px;
            z-index: 1;
            top: 0;
            @include min-screen($screen__s) {
                width: 50%;
                max-height: none;
            }
            @include min-screen($screen__l) {
                width: 65%;
            }
        }
        img {
            max-width: 100%;
            height: auto;
            display: block;
            position: absolute;
            top: 0;
            &.img-1 {
                z-index: 3;
                width: 80%;
                left: 10%;
                @include min-screen($screen__xxs) {
                    width: 70%;
                    left: 15%;
                }
                @include min-screen($screen__xs) {
                    width: 60%;
                    left: 20%;
                }
                @include min-screen($screen__s) {
                    left: auto;
                    right: 0;
                }
            }
            &.img-2 {
                display: none;
                @include max-screen($screen__l - 1px) {
                    height: 1px;
                    width: 1px;
                }
                @include min-screen($screen__l) {
                    display: block;
                    left: 0;
                    z-index: 4;
                }
            }
        }
        .h1 {
            display: block;
            font-size: $heading__font-size__xl;
            text-align: left;
            line-height: 1.3em;
            padding-top: 230px;
            margin-bottom: 10px;
            hyphens: none;
            @include min-screen($screen__xxs) {
                font-size: $heading__font-size__xxl - 8px;
                padding-top: 260px;
            }
            @include min-screen($screen__s) {
                font-size: $heading__font-size__xl;
                padding-top: 10px;
                margin-top: 0;
            }
            @include min-screen($screen__l) {
                font-size: $heading__font-size__xxl - 8px;
                padding-top: 40px;
                max-width: 85%;
            }
        }
        ul {
            margin: 5px 0 25px 0;
            @include min-screen($screen__s) {
                margin: 5px 0 15px 0;
                max-width: 85%;
            }
            @include min-screen($screen__l) {
                margin: 5px 0 25px 0;
            }
        }
        li {
            font-size: $heading__font-size__m;
            color: $color-white;
            font-weight: $font-weight__semibold;
            margin-bottom: 4px;

            &:before {
                color: $color-white;
            }
        }
        p {
            margin: 5px 0 25px 0;
            font-weight: $font-weight__regular;
            font-size: $p__font-size__xl;
            color: $color-white;
            @include min-screen($screen__l) {
                max-width: 85%;
            }
        }
    }
}

.pagebuilder-hachslider {
    .slick-slide {
        margin: 0 10px;
    }
}