.xlanding-widget-group {
  margin-bottom: 40px;
  &.xlanding-widget-group-above-heading {
    margin-top: -20px;
  }
  &.xlanding-widget-group-below-heading {
    margin-bottom: 20px;
  }
  &.xlanding-widget-group-below-product-list {
    margin-top: 20px;
    margin-bottom: 80px;
  }
}

.xlanding-widget-buttons {
  margin-top: 10px;
  margin-bottom: 15px;
  @include min-screen($screen__xs) {
    display: flex;
    flex-flow: row wrap;
  }
  li {
    margin-bottom: 20px;
    @include min-screen($screen__xs) {
      margin-right: 10px;
      margin-bottom: 15px;
    }
    a {
      width: 100%;
      @include min-screen($screen__xs) {
        width: auto;
      }
    }
  }
}

.xlanding-widget-image {
  margin-bottom: 20px;
}

.xlanding-widget-text {
  margin-top: 25px;
  margin-bottom: 60px;
}

.xlanding-widget-tiles {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 0;
}
.xlanding-widget-tile {
  position: relative;
  width: 100%;
  margin-bottom: 20px;

  @include min-screen($screen__xs) {
    width: 48.5%;
  }
  @include min-screen($screen__m) {
    width: 23.5%;
  }
  img {
    z-index: 20;
    position: relative;
    margin: 0 auto;
    width: 100%;
    display: none;
    @include min-screen($screen__xs) {
      display: block;
    }
  }
  h3 {
    bottom: 0;
    width: 100%;
    z-index: 30;
    margin: 0;
    background: $color-light-red;
    left: auto;
    font-size: $heading__font-size__l;
    @include min-screen($screen__xs) {
      position: absolute;
      width: 80%;
      left: 10%;
      font-size: $heading__font-size__m;
    }
  }
}
.xlanding-widget-tiles-3 {
  @include min-screen($screen__m) {
    width: 75%;
  }
  .xlanding-widget-tile {
    @include min-screen($screen__m) {
      width: 31.5%;
    }
  }
}
.xlanding-widget-tiles-2 {
  @include min-screen($screen__m) {
    width: 49%;
  }
  .xlanding-widget-tile {
    @include min-screen($screen__m) {
      width: 48%;
    }
  }
}
