.checkout-cart-index {
    .page-title-wrapper {
        display: none;
    }
    .btn-prev {
        margin-bottom: 20px;
    }
    .f-left {
        float: left;
    }
    .clearfix {
        clear: both;
        float: none;
    }
}

.page-title-cart {
    padding: 10px 5px;
    background-color: $background-color;
    border: 1px solid $color-border;
    @include min-screen($screen__xxs) {
        padding: 15px 5px;
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
    }
    h1 {
        margin: 0;
        @include min-screen($screen__xxs) {
            margin: 14px 0 0 10px;
        }
        @include min-screen($screen__xs) {
            margin: 8px 0 0 10px;
        }
    }
    .button {
        display: none;
        @include min-screen($screen__xxs) {
            display: block;
            margin-right: 10px;
        }
    }
}
.cart-table-wrapper {
    .data-table {
        margin: 0;
        border-left: 1px solid $color-border;
        border-right: 1px solid $color-border;
        .col.price {
            @include min-screen($screen__s) {
                width: 24%;
            }
        }
    }
    .cart-icons {
        position: absolute;
        left: 1%;
        top: 45px;
        display: flex;
        flex-flow: column nowrap;
        @include min-screen($screen__xs) {
            top: 10px;
        }
        @include min-screen($screen__s) {
            position: relative;
            left: auto;
            top: auto;
            float: left;
        }
        .use-ajax {
            order: 1;
        }
    }
    .cart-images {
        display: none;
        @include min-screen($screen__s) {
            display: block;
            float: left;
            margin: 0 20px 0 10px;
            width: calc(30% - 30px);
        }
    }
    .product-item-details {
        @include min-screen($screen__xs) {
            padding-left: 40px;
        }
        @include min-screen($screen__s) {
            padding-left: 0;
            float: left;
            width: 59%;
        }
        p {
            margin-bottom: 5px;
        }
        .item-options {
            dt {
                font-weight: $font-weight__semibold;
                margin-right: 3px;
            }
        }
    }
    .print-cost-details {
        .toggle-active {
            display: block;
            margin-top: 5px;
            cursor: pointer;
        }
        .print_costs_detail-item {
            display: none;
            &.active {
                display: block;
            }
        }
    }
    .action-edit {
        display: none;
    }
    .icn-round-bordered {
        display: inline-block;
        padding-top: 4px;
        @include min-screen($screen__xs) {
            display: block;
            margin-bottom: 5px;
        }
    }
    .qty {
        width: 65px;
        text-align: center;
    }
    .product-item-name a {
        font-size: $p__font-size__xl;
        text-decoration: none;
    }
}
.cart-summary-wrapper {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    border: 1px solid $color-border;
    border-top: none;
    overflow: hidden; // FIXME: prevent table stripes from overflowing
    .block-discount {
        width: 100%;
        border-bottom: 1px solid $color-border;
        padding: 15px 10px 5px;
        background-color: $background-color;
        @include min-screen($screen__xs) {
            padding: 25px 10px 5px;
        }
        .content {
            @include min-screen($screen__xs) {
                float: right;
                margin-right: 20px;
            }
        }
        .fieldset {
            margin: 0;
        }
        .field {
            display: flex;
            flex-flow: column nowrap;
            @include min-screen($screen__xs) {
                flex-flow: row nowrap;
            }
            label {
                font-size: $p__font-size__xl;
                margin: 10px 0 10px;
                @include min-screen($screen__xs) {
                    margin: 10px 10px 10px;
                }
            }
            .button {
                padding: 0;
                text-align: left;
                margin: 10px 0 0;
                @include min-screen($screen__xs) {
                    margin: 10px 0 0 10px;
                }
            }
        }
    }
    .table-discount {
        margin: 15px 10px;
        @include min-screen($screen__s) {
            width: 46%;
        }
        &:before {
            content: attr(data-th);
            display: block;
            text-transform: uppercase;
            font-size: $heading__font-size__m;
            font-weight: $font-weight__semibold;
            margin: 0 0 6px 9px;
        }
        tr:first-child {
            text-transform: uppercase;
        }
        tr:nth-child(2n+1) {
            background-color: $background-color;
        }
    }
    .block-crosssell {
        width: auto;
        @include min-screen($screen__s) {
            width: 45%;
        }
        float: none;
        position: relative;
        background-color: $background-color;
        .block-crosssell-inner {
            display: inline-block;
            background-color: #fff;
            padding: 10px 20px;
            .block-title.title {
                text-transform: uppercase;
            }
            .products-list {
                width: 100%;
                .product-item {
                    width: 100%;
                    border-bottom: none;
                    .price-box {
                        flex-grow: 1;
                        font-size: 16px;
                        .price {
                            font-weight: normal;
                        }
                    }
                    .product-sku {
                        margin: 5px 0;
                        font-size: 0.75em;
                    }
                    .product-item-details {
                        flex: 1;
                        flex-grow: 1;
                        display: flex;
                        flex-direction: column;
                        width: 60%;
                    }
                    .product-item-photo {
                        padding-right: 20px;
                        width: 40%;
                    }
                    .btn-cart.btn-inverse {
                        font-size: 0.75em;
                        padding: 12px 10px 12px 40px;
                        &:before {
                            font-size: 1.5em !important;
                            position: absolute;
                            left: 15px;
                            top: 20%;
                        }
                    }
                }
            }
        }
    }
    .cart-summary {
        width: auto;
        flex: 1;
        float: none;
        background-color: $background-color;
        .table.totals {
            width: 100%;
        }
        .summary.title,
        .table-caption,
        .block.shipping {
            display: none;
        }
        .cart-totals {
            background: $background-color;
            padding: 10px 20px 20px;
            @include min-screen($screen__s) {
                height: 100%;
            }
        }
        .table.totals th,
        .table.totals td {
            padding: 0 5px 5px;
        }
        .table.totals th {
            position: relative;
            .toggle-active {
                position: absolute;
                right: 0;
                cursor: pointer;
            }
        }
        .table.totals td {
            text-align: right;
        }
        .totals.sub th,
        .totals.sub td {
            font-size: $heading__font-size__m;
            font-weight: $font-weight__semibold;
            padding-bottom: 15px;
        }
        .grand.totals.excl th,
        .grand.totals.excl td {
            font-size: $heading__font-size__xl;
            font-weight: $font-weight__semibold !important;
            color: $color-green;
            border-top: 1px solid $color-border;
            border-bottom: 1px solid $color-border;
            padding-top: 10px;
            padding-bottom: 12px;
        }
        .grand.totals.excl.no-tax th,
        .grand.totals.excl.no-tax td {
            border-bottom: none;
        }
        .btn-giant {
            width: 100%;
        }
    }
}
.cart-footer {
    @include min-screen($screen__s) {
        display: flex;
        flex-flow: column nowrap;
    }
    @include min-screen($screen__xl) {
        flex-flow: row nowrap;
        justify-content: space-between;
    }
    .checkout-methods-items {
        margin: 30px 0 20px;
        @include min-screen($screen__s) {
            width: 50%;
            margin-left: auto;
        }
        @include min-screen($screen__xl) {
            width: 30%;
            order: 1;
        }
        .button {
            width: 100%;
        }
        .quote-pdf-link {
            font-size: $p__font-size__l;
            text-decoration: none;
            i {
                margin-right: 5px;
            }
        }
        .amazon-button-container {
            margin-top: 20px;
        }
    }
    ul:not([class]) {
        @include min-screen($screen__ml) {
            display: flex;
            flex-flow: row nowrap;
            justify-content: space-between;
        }
        @include min-screen($screen__xl) {
            width: 67%;
            order: 0;
            margin-top: 30px;
        }
        li {
            position: relative;
            border-bottom: 1px solid $color-border;
            min-height: 70px;
            &.icn-green {
                padding-left: 60px;
            }
            &.trusted-cart {
                .link-trusted {
                    float: left;
                    padding-right: 12px;
                    min-height: 60px;
                }
            }
            &:last-child {
                border-bottom: none;
            }
            p {
                font-size: $p__font-size__l;
            }
            @include min-screen($screen__ml) {
                width: 30%;
                border-bottom: none;
            }
        }
    }
}
#shipping-info-block,
#early_rabatt_info_block,
#base_cost_info_block  {
    width: 100%;
    background: #fff;
    padding: 10px 0;
    margin-top: 5px;
    p {
        padding: 3px 0;
        margin-bottom: 10px;
        font-size: $p__font-size__l !important;
    }
    div {
        display: table-row;
        p {
            display: table-cell;
        }
    }
}
#shipping-info-block[style="display: block;"],
#early_rabatt_info_block.active,
#base_cost_info_block.active {
    display: table !important;
}
#shipping-info-block {
    .heading {
        font-weight: $font-weight__semibold;
    }
    p {
        display: table-cell;
        padding: 5px 0;
        &:first-child {
            width: 83%;
        }
    }
    .addon {
        display: block;
        p {
            width: 87%;
            font-size: 13px;
            display: block;
        }
    }
}
.cart-empty {
    min-height: 200px;
    margin-top: 40px;
    p {
        font-size: $p__font-size__l;
    }
}
