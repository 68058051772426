// breakpoints should be kept in sync with values in
// public/app/code/Hach/Brandshop/view/frontend/web/js/brand-page-slider.js
$brand-slider__2-columns-breakpoint: 420px !default;
$brand-slider__3-columns-breakpoint: 480px !default;
$brand-slider__4-columns-breakpoint: 650px !default;
$brand-slider__5-columns-breakpoint: 850px !default;

.product-slider {
    border: 1px solid $color-border;
    padding: 20px 10px;
    &.product-slider-my-logo {
        border-bottom: none;
    }
    h3 {
        margin-left: 20px;
    }
}
.tabbed-product-sliders {
    margin-bottom: 30px;
}
.home-brands {
    position: relative;
    padding-bottom: 20px;
    margin: 40px 0;
    .more {
        position: absolute;
        right: 50px;
    }
}
.home-brands .brands {
    padding: 0 60px;
    display: flex;
    flex-flow: row wrap;
    max-width: 100%;
    margin: 0;
    .brand-item {
        list-style: none;
        margin: 0;
        padding: 0;
    }
    > .brand-item {
        width: 100%;
        @include max-screen($brand-slider__2-columns-breakpoint - 1) {
            img {
                width: 100%;
            }
            &:nth-child(n+2) {
                display: none;
            }
        }
        @include screen($brand-slider__2-columns-breakpoint, $brand-slider__3-columns-breakpoint - 1) {
            &:nth-child(n+3) {
                display: none;
            }
        }
        @include screen($brand-slider__3-columns-breakpoint, $brand-slider__4-columns-breakpoint - 1) {
            &:nth-child(n+4) {
                display: none;
            }
        }
        @include screen($brand-slider__4-columns-breakpoint, $brand-slider__5-columns-breakpoint - 1) {
            &:nth-child(n+5) {
                display: none;
            }
        }
        @include min-screen($brand-slider__5-columns-breakpoint) {
            &:nth-child(n+6) {
                display: none;
            }
        }

        @include min-screen($brand-slider__2-columns-breakpoint) {
            width: 50%;
        }
        @include min-screen($brand-slider__3-columns-breakpoint) {
            width: 33.333333333%;
        }
        @include min-screen($brand-slider__4-columns-breakpoint) {
            width: 25%;
        }
        @include min-screen($brand-slider__5-columns-breakpoint) {
            width: 20%;
        }
    }
}
