

//
//  Columns
//  _____________________________________________



.columns {
    .column.main {
        width: 100%;
    }
}
.page-main {
    margin-top: 30px;
    .page-layout-home-page & {
        margin-top: 1px;
    }
    .hach-brandshop-brandpage-view & {
        margin-top: 10px;
    }
}
.wrapper {
    position: relative;
    width: 100%;
    max-width: 100%;
    margin: 0 auto;
}
.wrapper-content,
.column.main {
    position: relative;
    width: 100%;
    max-width: 100%;
    margin: 0 auto;
    padding: 0 3%;
    @include min-screen($screen__s) {
        padding: 0 2%;
    }
    @include min-screen($screen__xl)  {
        max-width: $wrapper__inner;
        width: $inner__value / $outer__value * 100%;
        padding: 0 1%;
    }
    .page-print & {
        margin: 0;
        padding: 0;
    }
}

.col2-set { 
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between; 
    .col-1 {
        width: 100%;
        margin-bottom: 20px;
        @include min-screen($screen__s) {
            margin-bottom: 0;
            width: 47.5%;
        }
    }
    .col-2 {
        width: 100%;
        @include min-screen($screen__s) {
            width: 47.5%;
        }
    }
    .col-narrow {
        width: 100%;
        @include min-screen($screen__s) { 
            width: 37%;
        }
    }
    .col-wide {
        width: 100%;
        @include min-screen($screen__s) { 
            width: 60%;
        }
    }
}
.twoCol {
    @include two-column-text;
}

#maincontent .wrapper-content > div,
img,
picture {
    content-visibility: auto;
}
