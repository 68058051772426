.catalog-product-view {
    .column.main {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        align-items: flex-start;
        margin-top: -10px;
    }

    .page-title-wrapper {
        position: relative;
        width: 100%;
        padding: 0;
        max-width: none;
        margin: -10px 0 20px;

        .page-title {
            margin-bottom: 5px;
        }
        & > #horizontal-product-navigation-container {
            display: none;
        }

        @include min-screen($screen__s) {
            display: flex;
            flex-wrap: wrap;
            & > * {
                width: 100%;
                flex: 68%;
            }
            & > #horizontal-product-navigation-container {
                flex: 32%;
                display: block;
            }
        }

        @include max-screen($screen__l) {
            & > #horizontal-product-navigation-container {
                .list-link {
                    &:after {
                        content: attr(data-text-short) " ";
                        font-size: 14px;
                        text-decoration: none;
                        text-align: center;
                        text-transform: uppercase;
                    }
                    font-size: 0;
                }
            }
        }

        .product-info-stock-sku {
            font-size: $font-size__s;
        }

        .product.attribute.sku {
            display: flex;
            flex-flow: row nowrap;
            margin-left: 29px;

            .value {
                margin-left: 10px;
            }
        }
    }

    .product.media {
        position: relative;
        width: 100%;

        @include min-screen($screen__s) {
            width: 48%;
        }


        .badges-list {
            opacity: 1;

            @include transition(all, 0.16s ease-in-out);
        }

        &:hover .badges-list {
            opacity: 0;

            @include transition(all, 0.16s ease-in-out);
        }
    }
    .product-info-main {
        width: 100%;
        order: 2;
        @include min-screen($screen__s) {
            width: 31%;
            order: 1;
            .touch & {
                width: 48%;
            }
        }


        .box-tocart {
            .label {
                font-size: $p__font-size__xl;
                margin: 8px 21px 0 0;
            }
            .add-to-qty {
                display: flex;
                flex-flow: row wrap;
            }
            .min_order_amount_info {
                font-size: $p__font-size__l;
                width: 100%;
            }
            .amount-box {
                margin-top: 5px;
            }
            .quantity-dropdown {
                position: relative;
                flex-grow: 1;

                .tierprice-table-qty {
                    position: absolute;
                    border: 1px solid $color-border;
                    background: rgba(255, 255, 255, 0.98);
                    z-index: 899;
                    top: 38px;
                    width: 100%;

                    @include drop-shadow;
                }
            }

            .add-to-qty-input {
                position: relative;

                input {
                    width: 95px;
                    padding-left: 13px;
                }

                .fa {
                    color: $color-grey2;
                    position: absolute;
                    right: 12px;
                    top: 11px;
                    display: none;
                }

                &.error {
                    color: $color-red;
                }
            }

            .input--dropdown input {
                width: 100%;
            }

            .input--dropdown .fa {
                display: block;
                cursor: pointer;
            }

            .input--dropdown .fa-chevron-up {
                display: none;
            }

            .input-active .fa-chevron-up {
                display: block;
            }

            .input-active .fa-chevron-down {
                display: none;
            }

            .tierprice-list {
                cursor: pointer;
            }

            .tierprice-list.element--show {
                display: block !important;
            }
        }
        .designer-prices {
            .box-tocart {
                .min_order_amount_text {
                    display: none;
                }
            }
            .tax-shipping {
                margin-bottom: 15px;
            }
            .price-with-tax {
                margin-bottom: 20px;
            }
        }
        .variant-qty-text {
            font-size: $p__font-size__l;
        }
        .product-info-price {
            font-size: $p__font-size__l;
            .price-box {
                .prices-list {
                    margin-bottom: 0;

                    li {
                        margin-bottom: 5px;
                    }
                }

                p {
                    margin-bottom: 5px;
                }
                .tax-shipping {
                    margin-bottom: 15px;
                }

                .prices-list__single,
                span.base-price,
                span.total-price,
                span.price-container.print-price {
                    display: flex;
                    flex-flow: row nowrap;
                    justify-content: space-between;
                }

                span.base-price,
                span.total-price {
                    width: 100%;
                }

                span.price-container.print-price,
                span.price-container.base-price {
                    border-bottom: 1px solid $color-border;
                    padding-bottom: 5px;
                }
                span.price-single-price {
                    text-decoration: line-through;
                    margin-left: auto;
                    margin-right: 20px;
                }
                span.base-price-single-reduced {
                    color: $color-red;
                }
                span.total-price {
                    font-size: $heading__font-size__l;
                    font-weight: $font-weight__semibold;
                }

                .print-prices-container {
                    padding-top: 20px;
                    border-top: 1px solid $color-border;
                    margin-top: 15px;
                    @at-root .catalog-product-view.carddesigner .product-info-price .price-box .print-prices-container {
                        display: none;
                    }
                    p {
                        margin-top: -10px;
                        margin-bottom: 25px;
                    }
                }
            }
        }
        .inventory-limited {
            width: 100%;
            font-size: $p__font-size__l;
            margin-top: 10px;
        }
        .artice_designer_active_info {
            width: 100%;
            margin-top: 10px;
        }

        .product-social-links {
            font-size: $p__font-size__l;
            margin: 0;
            margin-bottom: 15px;
            @at-root .catalog-product-view.carddesigner .product-info-main  .product-social-links  .product-addto-links {
                display: none !important;
            }
            .add-to-wishlist-error {
                margin: 5px 0 20px 26px;
                p {
                    font-size: $p__font-size__m;
                    color: $color-red;
                    margin-bottom: 5px;
                }
                a {
                    color: $color-green;
                }
            }
        }
    }

    .product-logopreview {
        width: 100%;
        @include min-screen($screen__s) {
            width: 16%;
            order: 2;
        }
    }

    .gallery-download {
        display: flex;
        flex-flow: column wrap;
        margin-top: 20px;
        font-size: $p__font-size__l;
        @include min-screen($screen__xs ) {
            flex-flow: row wrap;
        }
        ul {
            margin-top: 10px;
            margin-bottom: 0;
            @include min-screen($screen__xs ) {
                margin-left: 5px;
                margin-top: 0;
            }
            li {
                display: inline-block;
                margin-right: 5px;
            }
        }
    }

    .product-video {
        position: relative;
        margin: 20px 0 0;
        width: 100%;
        padding-bottom: 67.5%;
        height: auto;
        object {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }

    .product-info-bottom {
        width: 100%;
        border-top: 1px solid $color-border;
        margin: 30px 0 20px;
        padding-top: 30px;
        order: 1;
        @include min-screen($screen__s) {
            order: 3;
        }
        .showdetails {
            position: relative;
            width: 100%;
            display: block;
            font-weight: $font-weight__semibold;
            text-decoration: none;
            padding: 0 0 3px 40px;
            @include min-screen($screen__s) {
                display: none;
            }
            .icon-plus {
                position: absolute;
                left: -3px;
                top: -4px;
                padding-top: 3px;

            }
            &.is-active .icon-plus:before {
                content: '\e810';
            }
        }
        .product-info-bottom-inner {
            display: none;
            margin-top: 10px;
            &.is-active {
                display: block;
            }
            @include min-screen($screen__s) {
                display: block;
            }
            @include min-screen($screen__m) {
                display: flex;
                flex-flow: row nowrap;
                justify-content: space-between;
            }
        }
        .product.info.detailed {
            width: 100%;
            @include min-screen($screen__m) {
                width: 48%;
            }
            h3:first-of-type {
                display: none;
                @include min-screen($screen__s) {
                    display: block;
                }
            }
            .description p {
                font-size: $p__font-size__xl;
            }
            .description ul {
                list-style-type: disc;
                margin: 0 0 0 17px;
            }
        }

        .price-box {
            width: 100%;
            @include min-screen($screen__m) {
                width: 48%;
            }
        }

        .tierprice-info-container {
            background-color: $color-light-grey2;
            padding: 10px;

            .tierprice-info-text {
                font-size: $p__font-size__l;
            }
        }

        .product-ressources {
            [data-role=collapsible] {
                &:before {
                    font-size: 20px;
                    font-weight: bold;
                    position: absolute;
                    top: 6px;
                    left: 0;
                }
            }
        }
    }
    .nosto_element {
        order: 4;
        width: 100%;
    }
    .swatch-attribute {
        &.color {
            display: flex;
            .swatch-attribute-label {
                margin-right: 30px;
            }
            .swatch-attribute-options {
                margin-top: 0;
                margin-bottom: 5px;
                .swatch-option {
                    margin-bottom: 5px;
                }
            }
        }
    }
}

.print-type-list li {
    font-size: $p__font-size__l;
    margin-bottom: 10px;
}

.print-type {
    text-decoration: none;

    &:hover {
        text-decoration: none;
    }
}

.print-type:before {
    display: inline-block;
    background: $color-grey1;
    width: 25px;
    height: 20px;
    border-radius: 2px;
    color: $color-white;
    font-size: $p__font-size__l;
    text-align: center;
    margin-right: 5px;

    .tierprice-tabbutton & {
        width: 32px;
        padding-top: 2px;
        height: 23px;
    }
}

.print-type:hover:before {
    background: $color-grey2;
}

.werbe_druck_base .print-type:before,
.werbe_druck_sublimation .print-type:before,
.werbe_druck_tampon .print-type:before {
    content: 'W';
}

.sieb_druck .print-type:before {
    content: 'S';
}

.praegung .print-type:before {
    content: 'P';
}

.aetzung .print-type:before {
    content: '\00c4';
}

.offset_druck .print-type:before {
    content: 'O';
}

.spezial_sieb_druck .print-type:before {
    content: 'SP';
}

.stickerei .print-type:before {
    content: 'ST';
}

.laser_gravur .print-type:before {
    content: 'L';
}

.digital_druck .print-type:before {
    content: 'D';
}

.werbe_druck_tasse .print-type:before {
    content: 'T';
}

.werbe_druck_tasse_glossy .print-type:before {
    content: 'TG';
}

.werbe_druck_tasse_transfer .print-type:before {
    content: 'TT';
}
