.breadcrumbs {
    font-size: $p__font-size__m;
    margin: 20px 0 10px;
    .breadcrumbs-legend {
        margin: 0 5px 0 0;
        color: $color-grey2;
        display: inline-block;
    }
    .breadcrumbs-item {
        display: inline-block;
        color: $color-grey2;
        
        &:before {
            content: '\203A';
            margin: 0 5px;
            }
        &:first-child {
            margin: 0 5px 0 0;
        }
        &:first-child:before {
            content: '';
            margin: 0;
        }
        &:nth-child(2):before {
            content: '';
            margin: 0;
        }
        a {
            color: $color-grey1;
        }
        strong {
            font-weight: $font-weight__regular !important;
        }
    }
}
