button,
input[type="button"],
.button,
.checkout-registration .action.primary {
    position: relative;
    display: inline-block;
    width: auto;
    border: none;
    outline: none;
    padding: 12px 25px 12px;
    font-size: $p__font-size__xl;
    line-height: 1.2;
    font-weight: $font-weight__regular;
    color: $color-white;
    text-align: center;
    text-transform: uppercase;
    -webkit-font-smoothing: subpixel-antialiased;
    cursor: pointer;
    border-radius: 3px;
    text-decoration: none;
    white-space: wrap;

    @include drop-shadow-button;

    background: $color-red linear-gradient($color-red, #e23a46);

    &:hover {
        color: $color-white;
        text-decoration: none;
        background: $color-red linear-gradient(darken($color-red, 7%), darken(#e23a46, 7%));
    }

    &:visited {
        color: $color-white;
    }

    &.button--green {
        background: $color-green linear-gradient($color-light-green, $color-green);
    }

    &[disabled], &.disabled {
        background: #979899 linear-gradient(to top, #979899, #707273);
        opacity: 0.6;
        pointer-events: none;
        cursor: default;
    }
}

.btn-red {
    background: linear-gradient($color-red, #e23a46);

    &:hover {
        background: linear-gradient(darken($color-red, 7%), darken(#e23a46, 7%));
    }
}

.btn-blue {
    background: linear-gradient($color-blue, $color-blue);

    &:hover {
        background: linear-gradient(darken($color-blue, 7%), darken($color-blue, 7%));
    }
}

.btn-green,
.btn-success,
.action.tocart,
.action.save,
.action-save-address {
    color: $color-white;
    background: linear-gradient(#80bb3f, #8cc74c);

    &:visited {
        color: $color-white;
    }

    &:hover {
        background: linear-gradient(darken(#80bb3f, 7%), darken(#8cc74c, 7%));
    }
}

.btn-yellow,
.btn-action,
.action.update,
.checkout-registration .action.primary,
.action.primary.add {
    color: $color-grey3;
    background: linear-gradient($color-yellow, #fed459);

    &:visited {
        color: $color-grey3;
    }

    &:hover {
        color: $color-grey3;
        background: linear-gradient(darken($color-yellow, 7%), darken(#fed459, 7%));
    }
}

.action.update:before {
    display: none;
    margin-right: 8px;
    content: "\f021";
    font-size: $p__font-size__l;
    color: $color-grey1;
}
html.fonts-loaded {
    .action.update:before {
        display: inline-block;
        font-family: 'FontAwesome';
    }
}

.btn-inverse,
.action.back,
button.action.edit-address-link,
button.action.action-edit-payment,
.action-agree-popup,
.action-hide-popup {
    display: inline-block;
    background: #fff;
    border: 1px solid $color-border;
    color: $color-grey3;
    padding: 12px 25px 12px;
    box-shadow: none;
    font-size: $p__font-size__l;
    text-decoration: none;
    text-align: center;
    text-transform: uppercase;
    &:visited {
        color: $color-grey3;
        background: #fff;
    }

    &:hover {
        color: $color-red;
        background: #fff;

        @include drop-shadow-button;
    }
}

.action-agree-popup {
    float: right;
}

.btn-purple {
    background: linear-gradient($color-purple, #c955ac);

    &:hover {
        background: linear-gradient(darken($color-purple, 7%), darken(#c955ac, 7%));
    }
}

.btn-big {
    padding: 17px 22px;
    border-radius: 4px;

    @include drop-shadow-button;
}

.btn-blank {
    background: none;
    border: none;
    box-shadow: none;
    color: $color-red;
    text-transform: none;

    &:hover,
    &:active,
    &:focus {
        background: none;
        border: none;
        color: $color-red;
        text-decoration: underline;
    }
}

.btn-giant {
    padding: 20px 30px;
    font-size: $heading__font-size__l;
    border-radius: 4px;

    @include drop-shadow-button;
}

.tocart,
.btn-article-designer,
.btn-cart {
    width: 100%;
    hyphens: none;
    margin-bottom: 0;
    font-size: $p__font-size__l;
    padding: 17px 22px;

    @include min-screen($screen__xxs) {
        font-size: $p__font-size__xl;
    }
    &:before {
        display: none;
        content: "\f07a";
        font-size: 24px !important;
        position: absolute;
        left: 10px;
        top: 12px;
    }
}
html.fonts-loaded {
    .tocart,
    .btn-article-designer,
    .btn-cart {
        &:before {
            display: inline-block;
            font-family: 'FontAwesome';
        }
    }
}


.btn-refresh {
    background: none;
    box-shadow: none;
    &:hover {
        background: none;
        box-shadow: none;
    }
}

.wishlist-index-index .tocart {
    padding: 12px 25px 12px 42px;
    &:before {
        display: none;
        top: 7px;
    }
}
.btn-article-designer:before {
    content: "\f040";
}
.fallback .without_printareas {
    width: 100%;
    margin-top: 10px;
}

.has-multiple-buttons {
    @include min-screen($screen__xl) {
        display: flex !important;
        flex-flow: row wrap;
        justify-content: space-between;
    }
    .add-to-button,
    .fallback,
    .designer-wrapper {
        @include min-screen($screen__xl) {
            width: 48%;
        }
    }
    .add-to-button {
        margin-bottom: 10px;
        @include min-screen($screen__xl) {
            margin: 0;
        }
    }
    .tocart,
    .btn-article-designer {
        padding: 40px 10px 17px;
        @include min-screen($screen__xl) {
            min-height: 95px;
        }
        &:before {
            left: 46%;
            top: 8px;
        }
    }
    .btn-article-designer.disabled {
        opacity: 0.4;
        pointer-events: none;
        background: $color-grey4;
    }
    .without_printareas {
        width: 100%;
        @include min-screen($screen__xl) {
            min-height: 95px;
            padding: 31px 25px 12px;
        }
    }
}

.buttons-set {
    display: flex;
    flex-flow: column nowrap;

    @include min-screen($screen__xs) {
        flex-flow: row nowrap;
        justify-content: space-between;
    }


    .button {
        margin-bottom: 10px;

        @include min-screen($screen__xs) {
            margin-bottom: 0;
        }
    }
}

.add-to-button {
    &.disabled {
        button {
            opacity: 0.4;
            pointer-events: none;
            background: #999999;
        }
    }
}
