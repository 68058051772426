.news-heading {
    background: $color-light-red url(../images/assets/bgd-blog-header.jpg) center center no-repeat;
    padding: 20px 0 30px;
    margin-top: -30px;
    @include min-screen($screen__s) {
        margin-top: -29px;
    }
    .site-title {
        color: $color-white;
        font-weight: normal;
        font-size: $heading__font-size__xxl;
        text-transform: uppercase;
        font-weight: $font-weight__semibold;
        line-height: 1.4;
        margin-bottom: 0;
    }
    strong.site-title {
        display: block;
    }
    .site-description {
        color: $color-white;
        font-size: $p__font-size__xl;
    }
}
body[class*="blog-"] {
    .post-list {
        display: grid;
        grid-template-columns: auto auto;
        .post-item {
            &:nth-child(9),
            &:nth-child(10) {
                border-bottom: none;
            }
        }
    }
    .post-view ol {
        margin: 20px 0 0 17px;
        padding: 0;
        font-size: $p__font-size__l;
    }
    .post-view ul {
        list-style-type: disc;
        margin: 20px 0 0 17px;
        font-size: $p__font-size__l;
    }
    .page-title-wrapper {
        margin-top: 20px;
    }
    .post-date {
        color: $color-grey4;
        margin: 10px 0;
    }
    .post-item {
        border-bottom: 1px solid $color-border;
        margin-bottom: 20px;
        &:last-child {
            border-bottom: none;
        }
    }
    .post-view-modern {
        .post-title {
            margin-bottom: 0;
        }
        .blog-post-share-buttons {
            margin: 10px 0 10px 0;
        }
        figcaption {
            font-size: $p__font-size__m;
            color: $color-grey4;
            margin-top: -5px;
        }
        .share-btn {
            display: inline-block;
            color: #fff;
            border: none;
            padding: 0.5em;
            width: 4em;

            outline: none;
            text-align: center;
            margin-bottom: 10px;
            &:hover {
                color: #eeeeee;
                opacity: 0.8;
            }
            &:active {
                position: relative;
                top: 2px;
                box-shadow: none;
                color: #e2e2e2;
                outline: none;
            }
            &.twitter {
                background: #55acee;
            }
            &.google-plus {
                background: #dd4b39;
            }
            &.facebook {
                background: #3B5998;
            }
            &.stumbleupon {
                background: #EB4823;
            }
            &.reddit {
                background: #ff5700;
            }
            &.linkedin {
                background: #4875B4;
            }
            &.email {
                background: #444444;
            }
        }
    }
}

.post-list-wrapper {
    &.blog-widget-recent {
        .post-list {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            align-items: stretch;
            gap: 10px;
            > li {
                &.post-holder {
                    margin: 0;
                    width: calc(100% * (1 / 1) - 20px - 1px);
                    clear: none !important;
                    float: none;
                    @include min-screen($screen__xxs) {
                        width: calc(100% * (1 / 2) - 20px - 1px);
                    }
                    @include min-screen($screen__m) {
                        width: calc(100% * (1 / 4) - 20px - 1px);
                    }
                    .post-image {
                        width: 100%;
                    }
                    .post-title {
                        .post-item-link {
                            font-size: 16px;
                            line-height: 1.5;
                        }
                    }
                }
            }
        }
    }
}