.amsearch-loupe,
.amsearch-close {
    display: none !important;
}
.amsearch-result-section {
    width: 100% !important;
    background: $color-white;
    padding: 20px 5px;
    @include min-screen($screen__m) {
        margin-top: 45px;
    }
    .amsearch-items-section {
        width: auto !important;
        margin-bottom: 20px;
    }
    .amsearch-products {
        width: auto !important;
    }
    .amsearch-block-header {
        font-size: $font-size__l;
        text-transform: uppercase;
        font-weight: $font-weight__semibold;
        margin-bottom: 10px;
    }
    .amsearch-item-container.category {
        margin-bottom: 20px;
    }
    .amsearch-item {
        border-top: 1px solid $color-light-grey1;
        margin-bottom: 0;
        &.product-item {
            position: relative;
            display: flex;
            flex-flow: row nowrap;
            justify-content: space-between;
            padding: 3px 0;
        }
    }
    .item-name {
        display: block;
        padding: 10px 0;
        text-decoration: none;
    }
    .amsearch-image {
        margin-right: 10px;
        width: 17%;
    }
    .product-item-details {
        width: 77%;
    }
    .product-item-link {
        display: block;
        max-width: 95%;
        font-size: $p__font-size__l;
        font-weight: $font-weight__semibold;
        text-decoration: none;
    }
    .price-box {
        font-size: $p__font-size__l;
        font-weight: $font-weight__semibold;
    }
    .price-label {
        display: none;
    }
    .old-price {
        display: none;
    }
    .amsearch-block-footer {
        font-size: $p__font-size__l;
        margin-top: 10px;
    }
    .amsearch-message-block {
        font-size: $p__font-size__xl;
    }
}

body.-amsearch-overlay-opened {
    .logo,
    .nav-sections {
        z-index: 0;
    }
}
// end Fix for widgets

.page-header.amsearch-full-width.-opened {
    z-index: 12;
}

.amsearch-overlay-block {
    & {
        background: rgba(0, 0, 0, 0);
        bottom: 0;
        display: none;
        height: 100%;
        left: 0;
        position: fixed;
        right: 0;
        top: 0;
        width: 100%;
        z-index: 101;
    }

    .amsearch-overlay {
        bottom: 0;
        height: 100%;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        width: 100%;
    }
}
