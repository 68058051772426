.fotorama__stage {
    position: relative;
    max-width: 100%;
    overflow: hidden;
    border: 1px solid $color-border;
    margin-bottom: 10px;
    .fotorama__wrap--css3 & {
        @extend .fotorama-gpu;
    }

    .fotorama__wrap--video & {
        @extend .fotorama-transform-disabled;
    }

    &:before,
    &:after {
        @extend .fotorama-gpu;
        position: absolute;
        display: block;
        content: '';
        background-repeat: no-repeat;
        pointer-events: none;
        text-decoration: none;
        z-index: 10;
    }

    &:before {
        left: -10px;
        top: -10px;
    }

    &:after {
        right: -10px;
        bottom: -10px;
    }

    .fotorama--fullscreen &,
    .fotorama__wrap--no-shadows &,
    .fotorama__wrap--fade & {
        &:before,
        &:after {
            display: none;
        }
    }

    .fotorama__wrap--only-active & {
        max-width: 99999px !important;
    }
}
