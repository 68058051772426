//
//  Buttons variables
//  _____________________________________________


//  Font style

$button__font-family                          : $font-family__base !default;
$button__font-size                            : $p__font-size__xl !default;
$button__font-weight                          : $font-weight__regular !default;
$button__line-height                          : 16 !default;
$button__margin                               : 0 !default;
$button__padding                              : 12px 25px !default;
$button__width                                : false !default;


//  Display settings

$button__display                              : inline-block !default;
$button__cursor                               : pointer !default;
$button__border-radius                        : 0 !default;

$button__disabled__opacity                    : 0.7 !default;


//  Gradient button

$button__gradient                             : true !default;
$button__gradient-direction                   : vertical !default;


//  Default / secondary button

$button__color                                : $color-white !default;
$button__background                           : $color-red !default;
$button__border                               : 0 none !default;
$button__gradient-color-start                 : $color-dark-red !default;
$button__gradient-color-end                   : $color-red !default;

$button__hover__color                         : $color-white !default;
$button__hover__background                    : darken($color-red, 7%) !default;
$button__hover__border                        : $button__border !default;
$button__hover__gradient-color-start          : darken($color-dark-red, 7%) !default;
$button__hover__gradient-color-end            : darken($color-red, 7%) !default;

$button__active__color                        : $button__color !default;
$button__active__background                   : $button__hover__background !default;
$button__active__border                       : $button__border !default;
$button__active__gradient-color-start         : darken($color-dark-red, 12%) !default;
$button__active__gradient-color-end           : darken($color-red, 12%) !default;


//  Primary button

$button-primary__line-height                  : false !default;
$button-primary__width                        : false !default;
$button-primary__margin                       : false !default;
$button-primary__padding                      : $button__padding !default;
$button-primary__gradient                     : $button__gradient !default;
$button-primary__gradient-direction           : $button__gradient-direction !default;

$button-primary__background                   : $color-light-green !default;
$button-primary__border                       : $button__border !default;
$button-primary__color                        : $color-white !default;
$button-primary__gradient-color-start         : $color-green !default;
$button-primary__gradient-color-end           : $color-light-green !default;

$button-primary__hover__background            : darken($color-green, 7%) !default;
$button-primary__hover__border                : $button__border !default;
$button-primary__hover__color                 : $button-primary__color !default;
$button-primary__hover__gradient-color-start  : darken($color-green, 7%) !default;
$button-primary__hover__gradient-color-end    : darken($color-light-green, 7%) !default;

$button-primary__active__background           : $button-primary__hover__background !default;
$button-primary__active__border               : $button__border !default;
$button-primary__active__color                : $button-primary__color !default;
$button-primary__active__gradient-color-start : darken($color-green, 12%) !default;
$button-primary__active__gradient-color-end   : darken($color-light-green, 12%) !default;


// Button Blue

$button-blue__background                      : $color-blue !default;
$button-blue__border                          : $button__border !default;
$button-blue__color                           : $color-white !default;
$button-blue__gradient-color-start            : false !default;
$button-blue__gradient-color-end              : false !default;

$button-blue__disabled__background            : $color-grey1 !default;
$button-blue__disabled__opacity               : 0.2 !default;

$button-blue__hover__background               : darken($color-blue, 7%) !default;

$button-blue__active__background              : darken($color-blue, 12%) !default;


// Button Action/Yellow

$button-action__background                   : $color-yellow !default;
$button-action__border                       : $button__border !default;
$button-action__color                        : $color-grey2  !default;
$button-action__gradient-color-start         : false !default;
$button-action__gradient-color-end           : false !default;

$button-action__hover__background            : darken($color-yellow, 7%) !default;
$button-action__hover__color                 : $color-grey2  !default;

$button-action__active__background           : darken($color-yellow, 12%) !default;


// Button Black Inverse/Darl Grey

$button-inverse__padding                      : 12px 30px !default;

$button-inverse__background                   : $color-white !default;
$button-inverse__border                       : 1px solid $color-border !default;
$button-inverse__color                        : $color-grey2  !default;

$button-inverse__hover__background            : $color-white !default;
$button-inverse__hover__color                 : $color-red !default;

//  Button with icon

$button-icon__use                             : false !default;
$button-icon__content                         : $icon-settings !default;
$button-icon__font                            : $icon-font !default;
$button-icon__font-size                       : 22px !default;
$button-icon__line-height                     : inherit !default;
$button-icon__color                           : inherit !default;
$button-icon__margin                          : 0 !default;
$button-icon__vertical-align                  : middle !default;
$button-icon__position                        : $icon__position !default;
$button-icon__text-hide                       : false !default;

$button-icon__hover__font-color               : inherit !default;
$button-icon__active__font-color              : inherit !default;


//  Large button

$button__font-size__l                         : $p__font-size__xxl  !default;
$button__line-height__l                       : $p__font-size__xxl  + 4 !default;
$button__padding__l                           : 17px 35px !default;
$button__border-radius__l                     : 4px !default;


//  Small button

$button__font-size__s                         : 11px !default;
$button__line-height__s                       : $button__font-size__s + 1 !default;
$button__padding__s                           : 8px 16px !default;
