//  Color variables General

$color-white             : #ffffff !default;
$color-orange            : #fa9245 !default;
$color-purple            : #c252a6 !default;
$color-yellow            : #ffcc36 !default;
$color-grey1             : #333333 !default;
$color-grey2             : #666666 !default;
$color-grey3             : #474747 !default;
$color-grey4             : #999999 !default;
$color-light-grey1       : #d3d3d3 !default;
$color-light-grey2       : #f6f6f6 !default;
$color-light-grey3       : #e8eaec !default;
$color-light-grey4       : #e5e5e5 !default;


//  Color variables Hach

$color-red               : #cd0c1a !default;
$color-purple            : #c252a6 !default;
$color-light-red         : #ec404d !default;
$color-dark-red          : #e23a46 !default;
$color-blue              : #15a5db !default;
$color-green             : #59a20c !default;
$color-light-green       : #88c149 !default;
$color-blue-opp          : #144488 !default;

// Color variables Hach sustainable

$color-sustainable-green         : #65855b !default;
$color-sustainable-light-green   : #cacfc0 !default;

//  Color nesting

$background-color: $color-light-grey2;
$color-border: $color-light-grey1;
