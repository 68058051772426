.wishlist-index-index {
    .product-item-info {
        height: 100%;
        display: flex;
        flex-flow: row wrap;
        align-items: flex-start;
    }
    .product-item-photo {
        order: 2;
    }
    .product-item-name {
        order: 0;
        width: 80%;
        display: block;
        font-size: $heading__font-size__m;
        margin-bottom: 3px;
        a {
            text-decoration: none;
        }
    }
    .added-at-field {
        order: 1;
        width: 80%;
        margin-bottom: 0;
        font-size: $p__font-size__m;
    }
    
    .price-box {
        order: 3;
        width: 100%;
        font-size: $heading__font-size__m;
        font-weight: $font-weight__semibold;
        p {
            margin-bottom: 10px;
        }
    }
    .label {
        font-size: $p__font-size__l;
        font-weight: $font-weight__semibold;
    }
    .product-item-tooltip {
        order: 4;
        margin-bottom: 10px;
        .action {
            font-weight: $font-weight__semibold;
        }
    }
    .product-item-inner {
        width: 100%;
        order: 5;
        margin-top: auto;

    }
    .comment-box {
        margin-bottom: 10px;
    }
    .button {
        width: 100%;
        margin-bottom: 10px;
    }
    .icn-round-bordered {
        position: absolute;
        top: 40px;
        right: 5px;
        padding-top: 2px;
        &.is-first {
            top: 5px;
        }
    }
}
