//
//  Product Grid / Lists
//  _____________________________________________

// breakpoints should be kept in sync with values in
// public/app/code/Hach/ProductSlider/view/frontend/web/js/product-slider.js
$product-slider__2-columns-breakpoint: 600px !default;
$product-slider__3-columns-breakpoint: 850px !default;
$product-slider__4-columns-breakpoint: 1060px !default;
$product-slider__5-columns-breakpoint: 1200px !default;

.category-header {
    display: none;
    background-repeat: no-repeat;
    background-position: center top;
    min-height: 320px;
    margin-bottom: 20px;
    @include min-screen($screen__xs) {
        display: block;
    }
    .wrapper-content {
        position: relative;
    }
    .category-copy {
        position: absolute;
        width: 48%;
        top: 33px;
        left: 20px;
        @include min-screen($screen__ml) {
            left: 15px;
        }
        .light-headline {
            text-align: left;
            line-height: 1.25em;
        }
        p {
            font-size: $p__font-size__xl;
            text-align: left;
        }
    }
}
.sale-filter {
    margin-bottom: 15px;
    ul {
        @include min-screen($screen__xs) {
            display: flex;
            flex-flow: row nowrap;
        }
    }
    li {
        margin-bottom: 20px;
        @include min-screen($screen__xs) {
            margin-right: 10px;
        }
        a {
            width: 100%;
            @include min-screen($screen__xs) {
                width: auto;
            }
        }
        &.active a {
            @include inset-drop-shadow-top-left();
            font-weight: $font-weight__semibold;
        }
    }
}
.page-title-wrapper {
    position: relative;
    .action.link.rss {
        display: block;
        margin: -10px 0 20px;
        @include min-screen($screen__xxs) {
            margin: 0;
            position: absolute;
            right: 20px;
            top: 8px;
        }

    }
}
.products {
    .product-item-link {
        display: block;
        text-decoration: none;
        &:hover {
            text-decoration: underline;
        }
    }
    .price-label,
    .normal-price .price,
    .price-box .price,
    .price-box .price-wrapper {
        font-weight: $font-weight__semibold;
    }
    .minimal-price-link {
        pointer-events: none;
        text-decoration: none;
        font-weight: $font-weight__semibold;
    }
    .price-final_price .price-label {
        text-transform: lowercase;
    }
    .old-price {
        .price-label {
            display: none;
        }
        .price {
            text-decoration: line-through;
            margin-right: 10px;
            font-weight: $font-weight__semibold;
        }
    }
    .special-price {
        .price-label {
            display: none;
        }
        .price {
            font-weight: $font-weight__semibold;
            color: $color-red;
        }

    }
    .ekomi-rating {
        margin-top: 10px;
        label {
            width: 100%;
            font-size: $p__font-size__s;
            float: left;
            margin-top: 5px;
        }
    }
    .color-qty {
        font-size: $p__font-size__s;
        margin-top: 2px;
    }
}

.category-image {
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
}

.products-grid {
    margin-bottom: 10px;
    .product-slider-my-logo &,
    .tabbed-product-sliders & {
        margin: 0;
    }
    .list {
        display: flex;
        flex-flow: row wrap;
        border-left: 1px solid $color-border;
        padding: 0;
        margin: 0;
        .tabbed-product-slider &,
        &.product-items-greeting-card {
            border: none;
        }
        &.product-slider-items {
            max-width: 100%;
        }

        @at-root .tabbed-product-slider-visited .products-grid .slick-track {
            min-width: 1200px;
        }
        @at-root .product-additional-products .products-grid .slick-track {
            min-width: 1200px;
        }
        &.slider-items, .nosto-product-list {
            border: none;
            padding: 0 60px;
            .touch & {
                padding: 0 30px;
            }
        }

        .blog-view-index & {
            border-top: 1px solid $color-border;
            margin-top: 10px;
            @include min-screen($screen__xl) {
                flex-flow: column wrap;
            }
        }
        > .product-item {
            .tabbed-product-slider &,
            .product-slider & {
                @include max-screen($product-slider__2-columns-breakpoint - 1) {
                    &:nth-child(n+2) {
                        display: none;
                    }
                }
                @include screen($product-slider__2-columns-breakpoint, $product-slider__3-columns-breakpoint - 1) {
                    &:nth-child(n+3) {
                        display: none;
                    }
                }
                @include screen($product-slider__3-columns-breakpoint, $product-slider__4-columns-breakpoint - 1) {
                    &:nth-child(n+4) {
                        display: none;
                    }
                }
                @include min-screen($product-slider__4-columns-breakpoint) {
                    &:nth-child(n+5) {
                        display: none;
                    }
                }
            }
            .tabbed-product-slider & {
                @include min-screen($product-slider__5-columns-breakpoint) {
                    width: 20%;
                    &:nth-child(n+5) {
                        display: block;
                    }
                    &:nth-child(n+6) {
                        display: none;
                    }
                }
            }
        }
    }

    .product-item {
        width: 100%;
        position: relative;
        display: block;
        border-right: 1px solid $color-border;
        border-bottom: 1px solid $color-border;
        padding: 1.2% 1.2% 10px;
        margin: 0;
        @include min-screen($product-slider__2-columns-breakpoint) {
            width: 50%;
        }
        @include min-screen($product-slider__3-columns-breakpoint) {
            width: 33.333333333%;
        }
        @include min-screen($product-slider__4-columns-breakpoint) {
            width: 25%;
        }
        &.greeting-card {
            @include min-screen($screen__ml) {
                width: 33.333333333%;
                border: none;
            }
            &.subcategory {
                border-top: 1px solid $color-border;
                padding: 20px 0;
                .product-item-details {
                    display: none;
                }
            }
        }
        .tabbed-product-slider &,
        .product-slider & {
            border: none;
            padding: 14px 14px 8px;
        }
        .tabbed-product-slider & {
            @include min-screen($product-slider__5-columns-breakpoint) {
                width: 20%;
            }
        }
        .blog-view-index & {
            @include min-screen($screen__l) {
                width: 100%;
                padding: 20px;
            }
        }
    }
    .product-info {
        max-width: 100%;
        padding: 15px;
    }
    .product-item-link {
        margin: 18px 0 4px;
    }
    .nosto-product-list-item {
        @include max-screen($product-slider__2-columns-breakpoint - 1) {
            &:nth-child(n+2) {
                display: none;
            }
        }
        @include screen($product-slider__2-columns-breakpoint, $product-slider__3-columns-breakpoint - 1) {
            &:nth-child(n+3) {
                display: none;
            }
        }
        @include screen($product-slider__3-columns-breakpoint, $product-slider__4-columns-breakpoint - 1) {
            &:nth-child(n+4) {
                display: none;
            }
        }
        @include min-screen($product-slider__4-columns-breakpoint) {
            &:nth-child(n+5) {
                display: none;
            }
        }
    }
    .product-item, .nosto-product-list-item {

        .product-item-photo, .nosto-product-image a {
            display: block;
            position: relative;
            transition: transform 0.25s ease;
            transform: scale(0.75);
            text-align: center;
        }
        .nosto-product-image img {
            min-height: 170px;
        }
        .nosto-product-price {
            &:after {
                content: ' €';
            }
        }
        &:hover .product-item-photo, &:hover .nosto-product-image a {
            transform: scale(1.0);
        }
        .product-shop a:hover {
            color: $color-red;
            text-decoration: underline;
            h3 {
                color: $color-red;
                text-decoration: underline;
            }
        }
    }
}

.products-list {
    position: relative;
    margin: 20px 0 0;
    width: 100%;
    @include min-screen($screen__xs) {
        width: 75%;
        float: left;
    }
    .product-items {
        padding: 0;
    }
    .product-item {
        position: relative;
        display: block;
        margin: 0 0 20px;
        padding: 10px 0 20px;
        width: 100%;
        border-bottom: 1px solid $border-color__base;
        @include min-screen($screen__xs) {
            width: 95%;
        }
    }
    .product-item-info {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
    }
    .product-item-photo {
        width: 100%;
        margin-bottom: 10px;
        @include min-screen($screen__xs) {
            width: 27%;
            margin-bottom: 0;
        }
    }
    .product-item-details {
        width: 100%;
        @include min-screen($screen__xs) {
            width: 70%;
        }
        .product-description {
            @include min-screen($screen__xs) {
                width: 80%;
            }
            @include min-screen($screen__l) {
                width: 90%;
            }
            p {
                font-size: $p__font-size__l;
            }
        }
    }
    .product-item-link {
        font-size: $font-size__l;
        font-weight: $font-weight__semibold;
        max-width: 90%;
    }
    .price-box {
        font-size: $p__font-size__xxl;
    }
}
.products-grid.wishlist {
    margin: 0;
    .product-items {
        margin: 0;
        padding: 0;
        display: flex;
        flex-flow: row wrap;
        border: 1px solid $color-border;
        border-bottom: none;
    }
    .product-item {
        position: relative;
        margin: 0;
        border-right: none;
        @include min-screen($screen__xxs) {
            border-right: 1px solid $color-border;
            &:nth-child(2n) {
                border-right: none;
            }
        }
        @include min-screen($screen__xs) {
            &:nth-child(2n) {
                border-right: 1px solid $color-border;
            }
            &:nth-child(3n) {
                border-right: none;
            }
        }
        @include min-screen($screen__l) {
            &:nth-child(3n) {
                border-right: 1px solid $color-border;
            }
            &:nth-child(4n) {
                border-right: none;
            }
        }
        .product-item-photo {
            width: 100%;
        }
    }
}
.product-item {
    &.product-teaser {
        text-align: center;
        padding-left: 0;
        padding-right: 0;
        .product-teaser-title {
            line-height: $line-height__s;
            color: $color-white;
            padding: 0 10px;
            font-size: 23px;
            margin-bottom: 15px;
            display: block;
        }
        a {
            text-decoration: none;
            color: $color-white;
        }
        img {
            margin-bottom: 20px;
        }
        .button {
            max-width: 90%;
        }
    }
    &.next-teaser {
        min-height: 300px;
        .teaser-next-link {
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-flow: column nowrap;
        }
        .icon-round-next {
            width: 130px;
            height: 130px;
            border-radius: 50%;
            border: 1px solid $border-color__base;
            background: $color-white;
            display: block;
            font-size: 2.75em;
            padding: 35px 0 0 5px;
        }
        .icon-right-open:hover:before {
            color: $color-red;
        }
        .teaser-next-link {
            color: $color-grey1;
        }
    }
}

.product-list-right {
    margin-top: 20px;
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    @include min-screen($screen__xs) {
        width: 25%;
        float: right;
        display: block;
    }
    .product-teaser {
        padding-top: 5px;
        padding-bottom: 20px;
        margin-bottom: 20px;
        width: 100%;
        @include min-screen($screen__xxs) {
            width: 48%;
        }
        @include min-screen($screen__xs) {
            width: 100%;
        }
    }
}

.category-description {
    margin-top: 10px;
    font-size: $p__font-size__l;
    ul {
        margin-left: 20px;
        list-style-type: disc;
    }
}
.product-additional-products {
    width: 100%;
    margin: 15px 0;
    &.product-slider-upsell {
        order: 4;
        @include min-screen($screen__s) {
            order: 5;
        }
    }
    &.product-slider-related {
        order: 5;
        @include min-screen($screen__s) {
            order: 6;
        }
    }
    .products-grid {
        margin: 0;
        &.list {
            margin: 0;
        }
    }
    .image {
        transition: transform 0.25s ease;
        transform: scale(0.75);
    }
    .product-item:hover .image {
        transform: scale(0.85) !important;
    }
    .product-item-details {
        margin-top: -5px;
    }
    .product-item-link {
        margin: 0 0 4px;
    }
}

.nosto-prestashop {
    width: 100%;
    padding: 20px 0 10px;
    border: 1px solid $color-border;
    margin-bottom: 30px;
    .cms-home-page & {
        border-top: none;
        margin-bottom: 0;
        padding: 20px 10px;
        ul {
            padding: 0 30px 0 30px;
        }
        li.item {
            padding: 14px 14px 8px;
        }
    }
    .catalog-category-view & {
        margin-top: 8px;
    }
    .nosto-product-list {
        display: flex;
        flex-flow: row wrap;
        padding: 0;
        margin: 0;
    }
    .nosto-heading h3 {
        margin-left: 10px;
        @include min-screen($screen__xxs) {
            margin-left: 20px;
        }
    }
    ul li.item {
        width: 100%;
        position: relative;
        display: block;
        padding: 1.2% 1.2% 10px;
        margin: 0;

        @include min-screen($product-slider__2-columns-breakpoint) {
            width: 50%;
        }
        @include min-screen($product-slider__3-columns-breakpoint) {
            width: 33.333333333%;
        }
        @include min-screen($product-slider__4-columns-breakpoint) {
            width: 25%;
        }
        a {
            text-decoration: none;
            display: block;
            position: relative;
        }
        h3 {
            font-weight: $font-weight__regular;
            margin: 18px 0 4px;
        }
        .price-box {
            font-weight: $font-weight__semibold;
            display: flex;
            .label {
                font-size: $p__font-size__xl;
                display: none;
            }
            .nosto-old-price {
                order: 0;
                margin-right: 10px;
                text-decoration: line-through;
                small {
                    font-size: $p__font-size__xl !important;
                }
            }
            .nosto-current-price {
                order: 1;
                color: $color-red;
            }
        }
    }
}

.nosto-prestashop .nosto-product-brand,
.nosto-prestashop .product-desc a {
    display: none;
}

#nosto-page-success {
    margin-top: 20px;
}
