.swatch-attribute-label {
    font-weight: $font-weight__semibold;
    position: relative;
    .catalog-product-view & {
        float: left;
        margin-right: 30px;
        &:after {
            content: ':';
        }
        @at-root .catalog-product-view.carddesigner .swatch-attribute-label:after {
            content: '';
        }
    }
}

.swatch-attribute-label.required {
    padding-right: 10px;
}

.swatch-attribute-label[data-required="1"]:after {
    content: '*';
    color: red;
    position: absolute;
    right: -11px;
    top: -2px;
    font-weight: bold;
    font-size: 1em;
}

.swatch-attribute-selected-option {
    color: #646464;
    padding-left: 17px;
    .catalog-product-view & {
        display: none;
    }
}

.swatch-attribute-options,
.carddesigner-product-color-options {
    margin-top: 10px;
}

.swatch-option {
    padding: 2px;
    width: 18px;
    height: 18px;
    float: left;
    margin-right: 5px;
    cursor: pointer;
    position: relative;
    border-radius: 2px;
   
    &[option-tooltip-value="#FFFFFF"] {
        border: 1px solid rgb(218, 218, 218);
    }
    .catalog-product-view .product-info-main &,
    .filter & {
        width: 30px;
        height: 30px;
        background-size: cover !important;
    }
}

.swatch-option.text {
    background: #f0f0f0;
    color: #686868;
    font-size: 12px;
    font-weight: 700;
    padding: 7px 8px 4px 8px;
    margin-bottom: 5px;
    width: auto !important;
    min-width: 30px;
    max-height: 30px;
    text-align: center;
    &:first-child {
        margin-left: -5px;
    }
}

.swatch-option.selected {
    border: 1px solid $color-grey2 !important;
    box-shadow: inset 0 0 2px #525151;
}

.swatch-option.text.selected {
    background-color: #fff !important;
}

.clearfix:after {
    content: '';
    visibility: hidden;
    display: block;
    height: 0;
    clear: both;
}

.swatch-attribute.size .swatch-option,
.swatch-attribute.manufacturer .swatch-option {
    background: rgb(240, 240, 240);
    color: rgb(148, 148, 148);
}


.swatch-attribute.size .swatch-option.selected,
.swatch-attribute.manufacturer .swatch-option.selected {
    color: black;
    background: #fff;
    border: 1px solid #fff;
}


.swatch-option:not(.disabled):hover {
    border: 1px solid $color-white;
    box-shadow: inset 0 0 2px #525151;
}

.swatch-option.image:not(.disabled):hover,
.swatch-option.color:not(.disabled):hover {
    border: 1px solid #999;
   
}

.swatch-option.disabled {
    cursor: default;
}

.swatch-option.disabled:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: linear-gradient(
        to left top,
        rgba( 255, 255, 255, 0) 0%,
        rgba( 255, 255, 255, 0) 42%,
        rgba( 255, 255, 255, 1) 43%,
        rgba( 255, 255, 255, 1) 46%,
        rgba( 255, 82, 22, 1) 47%,
        rgba( 255, 82, 22, 1) 53%,
        rgba( 255, 255, 255, 1) 54%,
        rgba( 255, 255, 255, 1) 57%,
        rgba( 255, 255, 255, 0) 58%,
        rgba( 255, 255, 255, 0) 100%
    );
}



.swatch-opt {
    margin: 20px 0;
}

.swatch-more {
    display: inline-block;
    margin: 2px 0;
    text-decoration: none !important;
    position: static;
    z-index: 1;
}

// Layered Features
.swatch-option-link-layered {
    position: relative;
    margin: 0 !important;
    padding: 0 !important;
}

.swatch-option-link-layered:focus > div {
    box-shadow: 0 0 3px 1px #68a8e0;
}

.swatch-option-tooltip-layered {
    width: 140px;
    position: absolute;
    background: #fff;
    color: rgb(148, 148, 148);
    border: 1px solid #adadad;
    display: none;
    z-index: 999;
    left: -47px;
}

.swatch-visual-tooltip-layered {
    height: 160px;
    top: -170px;
}

.swatch-textual-tooltip-layered {
    height: 30px;
    top: -40px;
}

.swatch-option-link-layered:hover > .swatch-option-tooltip-layered {
    display: block;
}

.swatch-attribute-options .swatch-option-tooltip-layered .title {
    width: 100%;
    height: 20px;
    position: absolute;
    bottom: -5px;
    left: 0;
    color: rgb(40, 40, 40);
    text-align: center;
    margin-bottom: 10px;
}

.swatch-option-disabled:after {
    content: '';
    position: absolute;
    width: 42px;
    height: 2px;
    background: red;
    transform: rotate(-30deg);
    z-index: 995;
    left: -4px;
    top: 10px;
}

.swatch-option-disabled {
    outline: none !important;
    cursor: default;
    border: 0;
}

// Bugfix for Add To Cart button
.swatch-opt-listing {
    margin-bottom: 10px;
}

.swatch-option-loading {
    content: url(../Magento_Swatches/images/loader-2.gif);
}

.swatch-input {
    left: -1000px;
    position: absolute;
    visibility: hidden;
}
