.direct-order {
    position: relative;
    padding-bottom: 20px;
    width: 100%;
}
.direct-order-info {
    width: 100%;
    position: relative;
    background: none;
    z-index: 2;
    padding-bottom: 20px;
    @include min-screen($screen__l) {
        background: url('../images/assets/bgd-directorder.jpg') no-repeat right 10px;
        padding-bottom: 100px;
    }
}
.direct-order-info-text {
    width: 100%;
    @include min-screen($screen__l) {
        width: 50%;
    }
    ol {
        margin-left: 27px;
        padding: 0;
    }
    li {
        margin-bottom: 8px;
        font-size: $p__font-size__l;
    }
    .footnote {
        margin: 20px 10px;
    }
}
.direct-order-title {
    width: 100%;
    border-bottom: 1px solid $color-border;
    padding: 15px 5px 5px;
    @include min-screen($screen__s) {
        padding: 15px 20px 0;
    }
}

.product-search {
    width: 100%;
    background-color: $background-color;
    border: 1px solid $color-border;
    z-index: 1;
    position: relative;
}

.product-search-inner {
    width: 100%;
    max-width: 600px;
    padding: 20px 5px;
    @include min-screen($screen__s) {
        padding: 20px;
    }

    .fieldset {
        margin: 0;
    }
    label {
        margin: 4px 10px 0 0;
        font-weight: $font-weight__semibold;
    }
    .fa-spinner {
        position: absolute;
        right: 10px;
        top: 12px;
        opacity: 0.7;
    }
}

.direct-order-table.data-table {
    thead {
        border-top: 1px solid $color-border;
        @include min-screen($screen__s) {
            th {
                padding: 10px 20px;
            }
        }
    }
    tbody td {
        @include min-screen($screen__s) {
            padding: 8px 20px 12px 20px;
        }
    }
    .product-infos {
        position: relative;
        @include min-screen($screen__xs) {
            padding-left: 45px;
        }
        @include min-screen($screen__s) {
            padding-left: 60px;
        }
        .icn-round-bordered {
            display: inline-block;
            padding-top: 4px;
            position: absolute;
            left: 5px;
            top: 40px;
            @include min-screen($screen__xs) {
                top: 10px;
            }
            @include min-screen($screen__s) {
                left: 20px;
            }
        }
    }
    .no-items {
        padding: 0 5px;
        @include min-screen($screen__xs) {
            padding: 20px 10px;
        }
        @include min-screen($screen__s) {
            padding: 20px;
        }
    }
    h2,
    h4 {
        margin-bottom: 5px;
    }
    input.qty {
        width: 65px;
        text-align: center;
        margin-right: 10px;
    }
    .btn-refresh {
        display: block;
        margin-top: 6px;
    }
    .last {
        @include min-screen($screen__xs) {
            text-align: right;
        }
    }
}

.direct-order-totals {
    width: 100%;
    @include min-screen($screen__s) {
        display: flex;
    }
    .direct-order-totals-inner {
        padding: 0 5px 20px;
        @include min-screen($screen__s) {
            padding: 0 20px 20px;
            width: 50%;
            margin-left: auto;
        }
        @include min-screen($screen__l) {
            width: 36%;
        }
    }
    .direct-order-totals-price {
        margin: 0 0 10px 0;
        padding: 17px 5px 20px;
        border-bottom: 1px solid #ddd;
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
    }
    p {
       font-size: $p__font-size__m; 
    }
    .or {
        margin: 8px 0;
        text-align: center;
        font-size: $p__font-size__m;
        text-transform: lowercase;
    }
    .button {
        width: 100%;
    }
}
