//
//  Resetes
//  _____________________________________________

//
//  Magento reset
//  ---------------------------------------------


@mixin lib-magento-reset() {
    html {
        box-sizing: border-box;
    }
    *, 
    *:before,
    *:after {
        box-sizing: inherit;
    }
      
    body {
        height: 100%;
        font-family: $font-family__base;
        font-size: $font-size__base;
        line-height: $line-height__base;
        font-weight: $font-weight__heavier;
        color: $color-grey1;
        background-color: $color-white;
        text-align: left;
        text-rendering: optimizeLegibility;
        -webkit-font-smoothing: antialiased;
        min-width: 320px;
        hyphens: auto;
        @include min-screen($screen__xs) {
            hyphens: none;
        }
    }

    article,
    aside,
    details,
    figcaption,
    figure,
    main,
    footer,
    header,
    nav,
    section,
    summary {
        display: block;
    }

    audio,
    canvas,
    video {
        display: inline-block;
    }

    audio:not([controls]) {
        display: none;
        height: 0;
    }
    strong {
        font-weight: $font-weight__semibold !important;
    }
    ul {
        list-style-type: none;
        padding: 0;
    }

    nav ul,
    nav ol {
        list-style: none none;
    }

    img {
        max-width: 100%;
        height: auto;
        border: 0;
        -ms-interpolation-mode: bicubic;
        vertical-align: middle;
    }

    img,
    object,
    video,
    embed {
        height: auto;
        max-width: 100%;
    }

    svg:not(:root) {
        overflow: hidden;
    }

    figure {
        margin: 0;
    }

    @include lib-typography-all();

   

   
    form {
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    }

    address {
        font-style: normal;
    }

    * {
        &:focus {
            box-shadow: none;
            outline: 0;
        }
    }

    ._keyfocus *,
    input:not([disabled]),
    textarea:not([disabled]),
    select:not([disabled]) {
        &:focus {
            @include lib-css(box-shadow, $focus__box-shadow);
        }
    }
    .spinner {
        width: 22px;
        height: 22px;
        cursor: none;
        background: url("../images/loader.gif") no-repeat 50% 50%;
    }

    [class^="icon-"]:before,
    [class*=" icon-"]:before {
        font-family: "fontello";
        font-style: normal;
        font-weight: normal;
        speak: none;
        display: inline-block;
        text-decoration: inherit;
        width: 1em;
        margin-right: 0.2em;
        text-align: center;
        font-variant: normal;
        text-transform: none;
        line-height: 1em;
        margin-left: 0.2em;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
}
