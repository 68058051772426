//
//  Account Dropdown
//  ---------------------------------------------


.miniaccount-wrapper {        
    @include lib-dropdown(
        $_toggle-selector                     : '.action.showaccount',
        $_options-selector                    : '.block-miniaccount',
        $_dropdown-toggle-icon-content        : '',
        $_dropdown-toggle-active-icon-content : '',
        $_dropdown-list-item-padding          : false,
        $_dropdown-list-item-hover            : false,
        $_icon-font-position                  : before,
        $_icon-font-size                      : 35px,
        $_icon-font-line-height               : 33px,
        $_icon-font-color                     : $minicart-icons-color,
        $_icon-font-color-hover               : $minicart-icons-color-hover,
        $_icon-font-color-active              : $minicart-icons-color
    );
}
