.actions-toolbar {
    display: flex;
    flex-flow: column wrap;
    @include min-screen($screen__xs) {
        flex-flow: row nowrap;
    }
    &.order-actions-toolbar {
        display: block;
        margin-top: 20px;
        a {
            display: block;
            @include min-screen($screen__xs) {
                display: inline-block;
            }
        }
        .button {
            margin-bottom: 10px;
            @include min-screen($screen__xs) {
                
                margin-right: 10px;
            }
        }
    }
    > .primary {
        order: 1;
        @include min-screen($screen__xs) {
            margin-left: auto;
        }
        button {
            margin-bottom: 10px;
            width: 100%;
            @include min-screen($screen__xs) {
               
                width: auto;
            }
        }
    }
    > .secondary {
        order: 0;
        margin-bottom: 10px;
        @include min-screen($screen__xs) {
            margin-bottom: 0;
            margin-right: 10px;
        }
        a {
            width: 100%;
            @include min-screen($screen__xs) {
                width: auto;
            }
        }
    }
}
