// sass-lint:disable-all

$activecolor: #AA0587;


a#logopreview-open-mediathek {
  display: block;
  cursor: pointer;
  text-align: center;
  color: #fff;
  margin-top: 10px;
  i {
    margin-right: 5px;
  }
}

.mediathek-list {

  padding: 15px;

  .h1 {
    display: block;
    margin-bottom: 5px;
  }

  /* mimic main #nav */
  #mediathek-filter {
    position: relative;
    max-width: 925px;
    margin-bottom: 20px;
    padding-bottom: 5px;
    border-bottom: 1px solid #E3E3E3;

    li {
      position: relative;
      cursor: pointer;
      display: inline-block;
      height: 40px;
      width: auto;

      a {
        display: block;
        padding: 0px 15px 0px 15px;
        line-height: 40px;

        span {
          font-weight: 600;
          font-size: 14px;
          text-transform: uppercase;          
        }
      }

      @include bp-xx-small {
        height: 30px;
        a {
          line-height: 30px;
          span {
            font-size: 12px;
          }
        }
      }

      &.active,
      &:hover {
        background-color: #E7EBEC;
        a {
          text-decoration: none;
        }
      }

      /* arrow */
      &.active { 
        a {
          &:after{
            border-color: #E7EBEC rgba(0, 0, 0, 0) rgba(0, 0, 0, 0);
            border-style: solid;
            border-width: 6px;
            bottom: -12px;
            content: "";
            height: 0;
            left: 50%;
            margin-left: -6px;
            position: absolute;
            width: 0;        
          }
        }
      }

    }
  }

  button#mediathek-close {
    position: absolute;
    top: 6px;
    right: 0px;
    box-shadow: none;
    background: none;
  }

  ul.mediathek-images, ul.mediathek-orders {
    max-height: 400px;
    max-width: 900px;
    overflow-y: scroll;

    li {
      position: relative;
      display: inline-block;
      width: 290px;
      margin-bottom: 25px;
      cursor: pointer;
      vertical-align: top;

      /* date label */
      & > span {
        position: absolute;
        right: 12px;
        top: 93px;
      }

      div.mediathek-thumbnail {
        width: 280px;
        height: 90px;
        margin-right: 10px;
        margin-left: 3px;
        margin-top: 3px;
        background-position: center center;
        background-size: contain;
        background-repeat: no-repeat;
        border: 1px solid #bbb;
        background-color: #fff;
      }

      span.mediathek-filename {
        display: block;
        margin-left: 5px;

        @include bp-xx-small { font-size: 12px; }
      }

      /* hide remove icon by default*/
      a.remove {
        position: absolute;
        display: none;
      }

      span.designer-refresh-please-wait {
        position: absolute;
        top: 35%;
        left: 50%;
      }

      /* active / hover thumbnail */
      &.mediathek-current,
      &:hover {
        div.mediathek-thumbnail {
          border-color: $activecolor;
          border-width: 1px;

          &:before {
            content: "";
            display: block;
            width: 280px;
            height: 90px;
            margin-top: -2px;
            margin-left: -2px;
            border: 1px solid $activecolor;
          }          
        }

        /* make logo label visible */
        span.file-printable span {
          display: inline;
          padding-right: 5px;
        }        
      }

      &:hover {
        /* show remove icon on hover */
        a.remove {
          display: block;
          top: 4px;
          right: 10px;
          background: #fff;


          &:hover {
            border-top: 0px;
            border-right: 0px;
          }
        }
      }

      span.file-printable {
        position: absolute;
        top: 68px;
        right: 10px;
        background-color: white;
        padding-right: 3px;
        span {
          display: none;
        }
      }

    }
  }
}

div.mediathek-login-container {
  display: inline-flex;

  span.message-error {
    display: block;
  }
}

/* images */
#mediathek-clipart-list.mediathek-list ul.mediathek-images {
  li {
    width: 170px;
    margin-right: 3px;
    div.mediathek-thumbnail {
      width: 160px;
      &:before{
        width: 160px;
      }
    }
  }
}

/* orders */
#mediathek-order-list.mediathek-list ul.mediathek-orders {
  li {
    width: auto;
    margin-right: 3px;
    div.mediathek-order-article {
      float: left;
    }
    div.mediathek-thumbnail {
      float: left;
      width: 160px;
      &:before{
        width: 160px;
      }
    }
    /* bigger
    div.mediathek-thumbnail {
      float: left;
      height: 180px;
      &:before {
        height: 180px;
      }
    }    
     */
  }
}

div.mediathek-order-load-modal {
  button.btn-inverse {
    margin-right: 5px;
  }
  button.btn-success {
    margin-top: 10px;
  }

  div.mediathek-thumbnail {
    /* background-image set inline */
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    width: 100%;
    height: 350px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  @include bp-x-tiny {
    div.mediathek-thumbnail {
      height: 250px;
    }
  }
}

