//
//  Typography variables
//  _____________________________________________



//
//  General Font Sizes
//  ---------------------------------------------


//  Path

$font-path                      : '../../fonts/' !default;
$icons__font-path               : '../fonts/fontello/fontello' !default;
$icons__font-version            : '?fc0ec437';


//  Names

$icons__font-name               : 'fontello' !default;


//  Font families

$font-family-name__base         : 'Open Sans' !default;
$font-family__sans-serif        : 'Helvetica Neue', Helvetica, Arial, sans-serif !default;


//  Weights

$font-weight__light             : 300 !default;
$font-weight__regular           : 400 !default;
$font-weight__heavier           : 500 !default;
$font-weight__semibold          : 600 !default;
$font-weight__bold              : 700 !default;


//  Styles

$font-style__base               : normal !default;
$font-style__emphasis           : italic !default;


// Base font

$font-family__base              : $font-family__sans-serif !default;
$font-family__base-loaded       : $font-family-name__base, $font-family__sans-serif !default;
$font-family__serif             : Georgia, 'Times New Roman', Times, serif !default;
$font-family__monospace         : Menlo, Monaco, Consolas, 'Courier New', monospace !default;


//  Sizes

$root__font-size                : 62.5% !default; // Defines ratio between root font size and base font size, 1rem = 10px
$font-size-ratio__base          : 1.6 !default; // Defines ratio of the root font-size to the base font-size

$font-size-unit                 : px !default; // The unit to which most typography values will be converted by default
$font-size-unit-ratio           : $root__font-size * 16 / 100 / 1% !default; // Ratio of the root font-size to the font-size unit
$font-size-unit-convert         : false !default; // Controls whether font-size values are converted to the specified font-size unit

$font-size__base                : $font-size-unit-ratio * $font-size-ratio__base * 1px !default;
$font-size__xl                  : ceil(1.375 * $font-size__base) !default; // 22px
$font-size__l                   : ceil(1.125 * $font-size__base) !default; // 18px
$font-size__m                   : ceil(1 * $font-size__base) !default; // 16px
$font-size__s                   : ceil(0.875 * $font-size__base) !default; // 14px
$font-size__xs                  : floor(0.75 * $font-size__base) !default; // 12px
$font-size__xxs                 : floor(0.6875 * $font-size__base) !default; // 11px

//  Hach Sizes

$heading__font-size__xxl        : 44px;
$heading__font-size__xl         : 28px;
$heading__font-size__l          : 23px;
$heading__font-size__m          : 18px;

$p__font-size__xxl              : 22px;
$p__font-size__xl               : 16px;
$p__font-size__l                : 14px;
$p__font-size__m                : 12px;
$p__font-size__s                : 11px;
$p__font-size__xs               : 10px;

//  Line heights

$line-height__base              : 1.4 !default;
$line-height__computed          : floor($font-size__base * ($line-height__base / ($line-height__base * 0 + 1)) ) !default;
$line-height__xl                : 1.8 !default;
$line-height__l                 : 1.5 !default;
$line-height__s                 : 1.3 !default;


//  Colors

$text__color                    : $primary__color !default;
$text__color__intense           : $primary__color__darker !default;
$text__color__muted             : $primary__color__lighter !default;

//
//  Borders
//  ---------------------------------------------

$border-color__base             : $color-border !default;
$border-width__base             : 1px !default;


//
//  Links
//  ---------------------------------------------


$link__color                    : $color-grey1 !default;
$link__text-decoration          : underline !default;

$link__visited__color           : $link__color !default;
$link__visited__text-decoration : underline !default;

$link__hover__color             : $color-red !default;
$link__hover__text-decoration   : underline !default;

$link__active__color            : $active__color !default;
$link__active__text-decoration  : underline !default;


//
//  Focus
//  ---------------------------------------------

$focus__color                   : false !default;
$focus__box-shadow              : false !default;


//
//  Headings
//  ---------------------------------------------

$heading__font-family__base     : $font-family__base;
$heading__font-weight__base     : $font-weight__semibold !default;
$heading__line-height__base     : 1.25em !default;
$heading__color__base           : $color-grey1 !default;
$heading__font-style__base      : normal !default;
$heading__margin-top__base      : $indent__base !default;
$heading__margin-bottom__base   : $indent__base !default;

$h1__font-size                  : $heading__font-size__l !default;
$h1__font-color                 : $heading__color__base !default;
$h1__font-family                : $heading__font-family__base !default;
$h1__font-weight                : $heading__font-weight__base !default;
$h1__font-style                 : $heading__font-style__base !default;
$h1__line-height                : $line-height__s !default;
$h1__margin-top                 : 0 !default;
$h1__margin-bottom              : 30px !default;   // all 'px' values here and below to be replaced with $..indent__l
$h1__font-size-desktop          : $h1__font-size !default;

$h2__font-size                  : $heading__font-size__l !default;
$h2__font-color                 : $heading__color__base !default;
$h2__font-family                : $heading__font-family__base !default;
$h2__font-weight                : $heading__font-weight__base !default;
$h2__font-style                 : $heading__font-style__base !default;
$h2__line-height                : $line-height__xl !default;
$h2__margin-top                 : 0 !default;
$h2__margin-bottom              : 15px !default;

$h3__font-size                  : $heading__font-size__m !default;
$h3__font-color                 : $heading__color__base !default;
$h3__font-family                : $heading__font-family__base !default;
$h3__font-weight                : $heading__font-weight__base !default;
$h3__font-style                 : $heading__font-style__base !default;
$h3__line-height                : $heading__line-height__base !default;
$h3__margin-top                 : 0 !default;
$h3__margin-bottom              : 15px !default;

$h4__font-size                  : $heading__font-size__l !default;
$h4__font-color                 : $heading__color__base !default;
$h4__font-family                : $heading__font-family__base !default;
$h4__font-weight                : $font-weight__semibold !default;
$h4__font-style                 : $heading__font-style__base !default;
$h4__line-height                : $heading__line-height__base !default;
$h4__margin-top                 : 0 !default;
$h4__margin-bottom              : 15px !default;

$h5__font-size                  : $heading__font-size__l !default;
$h5__font-color                 : $heading__color__base !default;
$h5__font-family                : $heading__font-family__base !default;
$h5__font-weight                : $font-weight__semibold !default;
$h5__font-style                 : $heading__font-style__base !default;
$h5__line-height                : 1 !default;
$h5__margin-top                 : 0 !default;
$h5__margin-bottom              : 15px !default;
$h5__text-transform             : uppercase !default;

$h6__font-size                  : ceil(($font-size__base * 0.7)) !default; // 10px
$h6__font-color                 : $heading__color__base !default;
$h6__font-family                : $heading__font-family__base !default;
$h6__font-weight                : $font-weight__bold !default;
$h6__font-style                 : $heading__font-style__base !default;
$h6__line-height                : $heading__line-height__base !default;
$h6__margin-top                 : 0 !default;
$h6__margin-bottom              : 15px !default;

$heading__small-color           : $primary__color !default;
$heading__small-line-height     : 1 !default;
$heading__small-size            : ($font-size__xs / $font-size__base) * 100% !default;


//  Lists

$list__color__base              : false !default;
$list__font-size__base          : false !default;
$list__margin-top               : 0 !default;
$list__margin-bottom            : $indent__m !default;

$list-item__margin-top          : 0 !default;
$list-item__margin-bottom       : $indent__s !default;

$dl__margin-top                 : 0 !default;
$dl__margin-bottom              : $indent__base !default;

$dt__margin-top                 : 0 !default;
$dt__margin-bottom              : $indent__xs !default;
$dt__font-weight                : $font-weight__bold !default;

$dd__margin-top                 : 0 !default;
$dd__margin-bottom              : $indent__s !default;


//  Paragraphs

$p__margin-top                  : 0 !default;
$p__margin-bottom               : 20px !default;
$p__font-size                   : 0.875rem !default;
$p__line-height                 : 1.6 !default;


//  Code blocks

$code__background-color         : $panel__background-color !default;
$code__color                    : $primary__color__darker !default;
$code__font-size                : $p__font-size__s !default;
$code__padding                  : 2px 4px !default;

$pre__background-color          : $panel__background-color !default;
$pre__border-color              : $border-color__base !default;
$pre__border-width              : $border-width__base !default;
$pre__color                     : $primary__color__darker !default;

$kbd__background-color          : $panel__background-color !default;
$kbd__color                     : $primary__color__darker !default;


//
//  Misc
//  ---------------------------------------------


$mark__color                    : $primary__color__dark !default;
$mark__background-color         : $panel__background-color !default;

$abbr__border-color             : $border-color__base !default;


//  Disable filters output in css

$disable-filters                : false !default;


// Hr

$hr__height                     : 0 !default;
$hr__border-color               : $color-light-grey3 !default;
$hr__border-style               : solid !default;
$hr__border-width               : 1px 0 0 !default;
$hr__margin                     : 10px 0 30px !default;


//  Blockquote

$blockquote__border-color       : $color-light-grey3 !default;
$blockquote__border-width       : 0 0 0 1px !default;
$blockquote__content-before     : '\2014 \00A0' !default;
$blockquote__font-size          : 1.063em !default;
$blockquote__font-style         : $font-style__emphasis !default;
$blockquote__color              : #777 !default;
$blockquote__margin             : 0 0 1.250em 0 !default;
$blockquote__padding            : 0.563em 1.250em 0 1.250em !default;

$blockquote-small__color        : $primary__color !default;
$blockquote-small__font-size    : $p__font-size__xs !default;


$cite__font-style               : $font-style__base !default;



