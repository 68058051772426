.lp-designer {
    position: relative;
}

.lp-designer-stage {
    position: relative;
    overflow: hidden;
    width: 100%;
    background-color: #f0f2f3;
}

.lp-designer-title {
    margin: 20px 20px 30px 20px;
    @include min-screen($screen__ml) {
        margin: 0 60px 30px 60px;
    }
}

.lp-designer-title p {
    margin: 0;
    font-size: $p__font-size__xl;
}

.lp-designer-head {
    width: 90%;
}

.lp-designer-button {
    margin-top: 30px;
}

.lp-designer-button .campaign-button {
    padding: 12px 18px 14px 24px;
    font-size: $p__font-size__l;
    font-weight: 700;
    line-height: 1em;
    text-decoration: none;
}

.lp-designer-button .campaign-button span {
    padding-right: 10px;
}
.lp-designer-wrapper {
    display: flex;
    flex-flow: column wrap;
    @include min-screen($screen__xs) {
        flex-flow: row wrap;
    }
}
.lp-designer-column {
    width: 100%;
    padding: 14px;
    margin: 40px 0;
    @include min-screen($screen__xs) {
        width: 49%;
    }
    @include min-screen($screen__ml) {
        width: 25%;
    }
    p {
        font-size: $p__font-size__l;
    }
}

.lp-designer-column-title {
  
    display: flex;
    flex-flow: row nowrap;
    
    margin-bottom: 30px;
   
    .lp-designer-batch {
       
        height: 40px;
        width: 40px;
        font-size: 19px;
        line-height: 40px;
        text-indent: 4px;
        color: #666666;
        font-weight: 600;
        text-align: center;
        background-color: #f0f2f3;
        border-radius: 50%;
        box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
        flex-shrink: 0;
    }
    h3 {
        margin-left: 20px;
    }
}

.lp-designer-thumb {
    display: block;
    margin-bottom: 15px;
}
