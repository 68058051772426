table {
    width: 100%;
    border-collapse: collapse;
    font-size: $p__font-size__l;
    .table-caption {
        display: none;
    }
    th {
        font-weight: normal;
        padding: 3px 10px;
    }
    td {
        vertical-align: top;
        padding: 3px 10px;
    }
    .cms-page-view & {
        hyphens: none;
    }
    &.standard-table {
        td:last-child,
        th:last-child {
            text-align: right;
        }
        tr:nth-child(even) {
            background:#f6f7f9;
        }

    } 
}
.data-table {
    position: relative;
    width: 100%;
    margin: 10px 0;
    &.table-order-items {
        margin: 0;
        @include min-screen($screen__xs) {
            tfoot tr th {
                padding: 5px 0;
            }
            .grand_total strong,
            .grand_total .amount,
            .grand_total_incl .amount,
            .grand_total_incl strong {
                border-top: 1px solid $color-border;
            }
            .grand_total strong {
                padding-top: 4px;
            }
            .grand_total_incl strong {
                padding: 4px 0 0 60px;
            }
            .grand_total .amount strong,
            .grand_total_incl .amount strong {
                border: none;
            }
        }
    }
    thead {
        display: none;
        line-height: 1em;
        border-bottom: 1px solid $color-border;
        @include min-screen($screen__xs) {
            display: table-header-group;
        }
        th {
            font-size: $p__font-size__l;
            text-transform: uppercase;
            font-weight: $font-weight__semibold;
            padding: 10px;
            white-space: nowrap;
            vertical-align: middle;
            background-color: $background-color;
        }
    }
    tbody {
        tr {
            border-bottom: 1px solid $color-border;
        }
        td {
            position: relative;
            display: block;
            padding: 8px 10px 12px 50%;
            @include min-screen($screen__xs) {
                display: table-cell;
                padding: 8px 10px 12px 10px;
            }
            
            &:before {
                position: absolute;
                left: 5px;
                top: 8px;
                content: attr(data-th);
                @include min-screen($screen__xs) {
                    display: none;
                }
            }
        }
    }
    tfoot  {
        tr {
            @include min-screen($screen__xs) {
                text-align: right;
            }
        }
        th, 
        td {
            display: block;
            text-align: right;
            @include min-screen($screen__xs) {
                display: table-cell;
            }
        }
    }
    .subtotal {
        font-weight: $font-weight__semibold;
        @include min-screen($screen__xs) {
            text-align: right;
        }
    }
    .grand_total {
        font-size: $heading__font-size__m;
    }
}
.tierprice-box {
    position: relative;
    .tierprice-table  {
        position: relative;
        margin: 15px 0;
        font-size: $p__font-size__xs;
        background: $color-white;
        width: 85%;
        @include min-screen($screen__xs) {
            font-size: $p__font-size__l;
        }
        @include min-screen($screen__m) {
            font-size: $p__font-size__s;
        }
        @include min-screen($screen__ml) {
            font-size: $p__font-size__l;
        }
        thead tr {
            background: none;
            border-right: 1px solid $color-border;
        }
        thead th {
            padding: 2px;
            font-size: $p__font-size__xs - 1px;
            background: none;
            white-space: nowrap;
            @include min-screen($screen__xs) {
                padding: 2px 10px;
                font-size: $p__font-size__s;
            }
            @include min-screen($screen__m) {
                padding: 2px;
                font-size: $p__font-size__xs - 1px;
            }
            @include min-screen($screen__ml) {
                padding: 2px 10px;
                font-size: $p__font-size__s;
            }
            &:nth-child(n + 3) {
                border: 1px solid $color-border;
                background: none;
            }
            .kind-of-print {
                font-weight: $font-weight__semibold;
            }
        }
        thead .tierprice-table__trFirst th:nth-child(-n+2),
        thead .tierprice-table__trSecond th:nth-child(-n+2) {
            background-color: #eaeef1;
            border: none;
        }
        thead .tierprice-table__trFirst th:nth-child(n + 3) {
            border: 1px solid $color-border;
            border-bottom: none;
        }
        thead .tierprice-table__trSecond th:nth-child(n + 3) {
            border: none;
        }
        thead .tierprice-table__trSecond th:nth-child(3) {
            border-left: 1px solid $color-border;
        }
        tbody tr {
            border-right: 1px solid $color-border;
            &:nth-child(even) {
                background: #f6f7f9;
            }
        }
        tbody td {
            padding: 4px 2px;
            @include min-screen($screen__xs) {
                padding: 9px 10px;
            }
            @include min-screen($screen__m) {
                padding: 4px 2px;
            }
            @include min-screen($screen__ml) {
                padding: 9px 10px;
            }
            &:nth-child(2) {
                border-right: 1px solid $color-border;
            }
        }
        .printprices {
            background: $color-white;
            font-weight: bold;
            
        }
        tbody tr:last-child {
            .printprices {
                border-bottom: 1px solid $color-border;
            }
        }
    }

    .tierprice-tabbutton {
        position: absolute;
        top: 0;
        right: 1px;  
        width: 15.1%;
        list-style: none;
        text-align: center;
        border: 1px solid $color-border;
        border-left: none;
        border-bottom: none;
        li {
            display: block;
            padding: 6px 0 4px 12px;
            margin: 0;
            height: 44px;
            background-color: $background-color;
            border-left: 1px solid $color-border;
            border-bottom: 1px solid $color-border;
            width: 100%;
            position: relative;
            &.active {
                border-left: 1px solid transparent;
                background: $color-white;
            }
            .print-type {
                cursor: pointer;
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
                top: 10px;
            }
            a:before {
                margin: 0; 
            }
        }
    }
}

.tierprice-table-qty {
    font-size: 13px;
    text-align: left;
    float: left;
    background: $color-white;
    border: 1px solid $color-border;
    border-top: none;
    width: 100%;
    th {
        background-color: #eaeef1;
        padding: 4px 8px;
        font-size: 11px;
        
    }
    th:nth-child(4) {
        color: $color-red;
        font-weight: 600;
    }
    tr:nth-child(even) {
        background: #f6f7f9;
        
    }
    tr:nth-child(odd) {
        background: $color-white;
    }
    tr:hover {
        background: $color-light-grey2;
    }
    td {
        padding: 10px 8px;
        &[class^="tierprice-save-"] {
            color: $color-red;
        }
    }
}

/* Printcost Table
-------------------------------------------------- */
.printcost-table {
    width: 100%;
    margin-top: 15px;
    tr {
        border-bottom: none !important;
    }
    td {
        padding: 2px 8px !important;
        background: none;
        display: table-cell !important;
        text-align: left !important;
    }
    .print-item {
        font-weight: $font-weight__semibold;
        background-color: $background-color;
        padding: 4px 8px;
    }
    .print-check {
        font-weight: $font-weight__semibold;
        background-color: $background-color;
        padding: 2px 8px;
    }
    tfoot td {
        background-color: $background-color;
    }
}
