.newsletter {
    .field {
        width: 100%;
        display: flex;
        flex-flow: column nowrap;
        @include min-screen($screen__xs) {
            flex-flow: row nowrap;
        }
        @include min-screen($screen__s) {
            flex-flow: column nowrap;
        }
        @include min-screen($screen__l) {
            flex-flow: row nowrap;
        }
        .control {
            width: 100%;
            margin-bottom: 10px;
            @include min-screen($screen__xs) {
                width: 60%;
            }
            @include min-screen($screen__s) {
                width: 100%;
            }
            @include min-screen($screen__l) {
                width: 60%;
            }
        }
        input {
            height: 45px;
        }
        .actions {
            width: 100%;
            @include min-screen($screen__xs) {
                width: 40%;
            }
            @include min-screen($screen__s) {
                width: 100%;
            }
            @include min-screen($screen__l) {
                width: 40%;
            }
        }
        .primary {
            width: 100%;
            padding: 13px 18px;
            border-radius: 0 2px 2px 0;
           
        }
    }
}
