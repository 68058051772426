.onestepcheckout-index-index {
    .page-title-wrapper.wrapper-content {
        width: 100%;
        padding-bottom: 15px;
        border-bottom: 1px solid $color-border;
        display: flex;
        flex-flow: column nowrap;
        @include min-screen($screen__xs) {
            flex-flow: row nowrap;
            justify-content: space-between;
            align-items: flex-start;
            .button {
                margin-left: 10px;
            }
        }
        .page-title {
            margin-bottom: 10px;
        }
    }
    .opc-col-wrapper {
        @include min-screen($screen__m) {
            display: flex;
            flex-flow: row wrap;
            justify-content: space-between;
        }
    }
    .opc-left {
        width: 100%;
        @include min-screen($screen__m) {
            width: 68%;
        }
    }
    .opc-right {
        display: none;
        @include min-screen($screen__m) {
            display: block;
            width: 29%;
            padding-bottom: 20px;
        }
    }
    .opc-bottom {
        width: 100%;
        float: left;
    }
    .col-mp {
        margin-bottom: 15px;
    }
    .block-authentication {
        margin-top: 20px;
        position: relative;
        background: $background-color;
        border: 1px solid $color-border;
        padding: 10px 20px 10px;
        margin-bottom: 20px;
        @include min-screen($screen__m) {
            margin-bottom: 60px;
        }
        & > .block {
            margin-bottom: 20px;
        }
        #login-button-set {
            button {
                margin-bottom: 10px;
            }
            @include min-screen($screen__m) {
                display: flex;
                flex-flow: row wrap;
                justify-content: space-between;
            }
        }
        .register-advantages {
            list-style: disc;
            margin-left: 18px;
        }
        .buttons-set {
            flex-flow: column nowrap;
            @include min-screen($screen__xl) {
                flex-flow: row nowrap;
            }
            .button {
                margin-bottom: 10px;
                @include min-screen($screen__xl) {
                    margin-bottom: 0;
                }
            }
        }
        & > .block-amazon-button {
            width: 100%;
            border-top: 1px solid $color-border;
            padding-top: 20px;
            margin-bottom: 4px;
            .amazon-checkout-button {
                max-width: 180px;
            }
        }
    }

    .create-account-block {
        float: left;
        width: 100%;
        border-top: 1px solid $color-border;
        border-bottom: 1px solid $color-border;
        padding: 30px 0 17px;
        margin: 20px 0;
        .form-create-account {
            width: 100%;
        }
        .create-account-checkbox {
            input {
                display: none;
            }
            label {
                padding: 12px 25px 12px;
                font-size: $p__font-size__l;
                color: $color-grey3;
                background: linear-gradient($color-yellow, #fed459);
                border-radius: 3px;
                @include drop-shadow-button();
                cursor: pointer;
                text-transform: uppercase;
                font-weight: $font-weight__regular;
                hyphens: none;
                text-align: center;
                &:hover {
                    color: $color-grey3;
                    background: linear-gradient(darken($color-yellow, 7%), darken(#fed459, 7%));
                }
                &.btn-red {
                    color: $color-white;
                    background: linear-gradient($color-red, #e23a46);
                    &:hover {
                        background: linear-gradient(darken($color-red, 7%), darken(#e23a46, 7%));
                    }
                }
            }
        }
        .fieldset {
            margin: 55px 0 0;
        }
    }

    .checkout-shipping-address {
        div[data-bind="fadeVisible: isPasswordVisible"] {
            font-size: $p__font-size__l;
            margin-top: 3px;
        }
        .shipping-address-items {
            display: flex;
            flex-flow: row wrap;
            .shipping-address-item {
                width: 100%;
                font-size: $p__font-size__l;
                margin: 10px 0 0;
                cursor: pointer;
                @include min-screen($screen__s) {
                    width: 48%;
                }
                &.not-selected-item {
                    opacity: 0.3;
                }
                &.selected-item {
                    font-weight: $font-weight__semibold;
                }
                button.edit-address-link {
                    margin-top: 18px;
                    margin-bottom: 30px;
                }
            }
        }
        .btn-green {
            margin-bottom: 20px;
        }
        .address {
            margin: 0;
        }
    }
    .checkout-billing-address {
        .field-select-billing {
            .label {
                display: none;
            }
        }
        .choice.field {
            @include min-screen($screen__xs) {
                float: left;
            }
        }
        .billing-address-form {
            margin-top: 10px;
        }
    }
    .checkout-shipping-method {
        float: left;
        width: 100%;
        #checkout-shipping-method-load {
            display: flex;
            flex-flow: column nowrap;
            @include min-screen($screen__s) {
                flex-flow: row nowrap;
                justify-content: space-between;
            }
        }
        .table-checkout-shipping-method {
            margin-bottom: 10px;
            @include min-screen($screen__s) {
                width: 49%;
                margin: 0;
            }
            td {
                padding: 5px 10px;
            }
        }
        .table-checkout-shipping-method-prices {
            font-size: $p__font-size__l;
            padding: 10px;
            @include min-screen($screen__s) {
                width: 49%;
            }
            .shipping-info-block {
                width: 100%;
                div {
                    display: table-row;
                    &:nth-child(even) {
                        background: $color-light-grey2;
                    }
                }
                p {
                    display: table-cell;
                    padding: 5px 0;
                    &:first-child {
                        width: 83%;
                    }
                }
                .heading {
                    text-transform: uppercase;
                }
                .addon {
                    display: block;
                    p {
                        width: 87%;
                        font-size: 13px;
                        display: block;
                    }
                }
            }
        }
    }
    .checkout-payment-method {
        width: 100%;
        float: left;
    }
    .opc-payment {
        .step-title,
        .actions-toolbar {
            display: none;
        }
        .payment-method {
            margin-bottom: 20px;
            .field {
                margin-bottom: 5px;
            }
            .label {
                font-size: $p__font-size__xl;
            }
            .payment-method-content {
                display: none;
                font-size: $p__font-size__l;
                padding-left: 29px;
            }
            .action.action-edit-payment {
                margin-top: 5px;
                margin-left: -2px;
            }
            .action-help {
                margin-left: 10px;
                font-size: $p__font-size__l;
                @include min-screen($screen__xs) {
                    display: inline-block;
                    width: 70%;
                    text-align: right;
                }
            }
            &.payment-method-heidelpay {
                background: url(../images/assets/bgd-cc-payment.png) left 20px center no-repeat;
                background-size: 120px 35px;
                .label {
                    padding-left: 120px;
                }
            }
            &.payment-method-unzer-cards {
                background: url(../images/assets/bgd-cc-payment.png) left 25px top no-repeat;
                background-size: 120px 35px;
                .payment-method-title {
                    padding-top: 8px;
                }
                .payment-method-content {
                    margin-top: 10px;
                    margin-bottom: 30px;
                    .form {
                        .action-field {
                            display: none;
                        }
                    }
                 }
                .label {
                    padding-left: 120px;
                }
            }
            &#amazon-payment {
                .amazon-button-container {
                    display: none;
                }
            }
        }
        .payment-method._active {
            .payment-method-content {
                display: block;
            }
        }
    }
    .order-summary {
        border: 1px solid $color-border;
        margin-bottom: 15px;
        .legend {
            margin: 0;
            padding: 20px 8px;
            background: $background-color;
        }
        .checkout-table {
            margin: 0;
            th.qty-th {
                text-align: center;
            }
            td.product {
                @include min-screen($screen__ml) {
                    display: flex;
                    flex-flow: row nowrap;
                }
                .product-image-container {
                    display: none;
                    @include min-screen($screen__ml) {
                        display: block;
                        width: 30% !important;
                        margin-right: 20px;
                    }
                }
                .product-item-detail {
                    @include min-screen($screen__ml) {
                        width: 70%;
                    }
                    .item-option-group h3 {
                        font-size: $p__font-size__l;
                        background-color: $color-light-grey2;
                    }
                }
            }
            td.details-price {
                @include min-screen($screen__ml) {
                    width: 25%;
                }
                .print-cost-item-names {
                    display: none;
                    @include min-screen($screen__ml) {
                        display: block;
                    }
                }
            }
            td.details-qty {
                br {
                    display: none;
                    @include min-screen($screen__xs) {
                        display: inline-block;
                    }
                }
            }
            td.subtotal {
                br {
                    display: none;
                    @include min-screen($screen__xs) {
                        display: inline-block;
                    }
                }
                div {
                    &:after {
                        font-weight: $font-weight__regular;
                        @include min-screen($screen__xs) {
                            content: "" !important;
                        }
                    }
                    &:nth-of-type(1):after {
                        content: " (Warenwert)";
                    }
                    &:nth-of-type(2):after {
                        content: " (Druckpreis)";
                    }
                    &:nth-of-type(3):after {
                        content: " (Grundkosten)";
                    }
                    &:nth-of-type(4):after {
                        content: " (Zusatzkosten)";
                    }
                    &:nth-of-type(5):after {
                        content: " (Korrekturmuster/abzug)";
                    }
                    &:nth-of-type(6):after {
                        content: " (Druckkosten gesamt)";
                    }
                    &:nth-of-type(7):after {
                        content: " (SUMME)";
                    }
                }
            }
            .print-area {
                display: inline;
            }
            .item_qty {
                border: none;
                pointer-events: none;
                @include min-screen($screen__xs) {
                    text-align: center;
                }
            }
            .price-wrapper {
                hyphens: none;
            }

        }
        .table-totals-wrapper {
            background: $background-color;
        }
        .table-totals {
            padding: 20px 0;
            @include min-screen($screen__m) {
                width: 60%;
                margin-left: 40%;
            }
            .amount {
                text-align: right;
            }

            .totals.sub th,
            .totals.sub td {
                font-size: $p__font-size__xxl;
                font-weight: $font-weight__semibold !important;
                padding-top: 10px;
            }
            .grand.totals.excl th,
            .grand.totals.excl td {
                font-size: $heading__font-size__l;
                font-weight: $font-weight__semibold !important;
                color: $color-green;
                border-top: 1px solid $color-border;
                border-bottom: 1px solid $color-border;
                padding-top: 10px;
                padding-bottom: 12px;
            }
            .grand.totals.excl.no-tax th,
            .grand.totals.excl.no-tax td {
                border-bottom: none;
            }
            .grand.totals.incl th,
            .grand.totals.incl td, {
                padding-bottom: 20px;
            }
        }
    }
    .checkout-agreements-block {
        .checkout-agreement {
            width: 100%;
            display: flex;
            flex-flow: row wrap;
            input {
                width: 15px;
            }
            label {
                max-width: 90%;
            }
        }
        div.mage-error {
            width: 100%;
            font-size: $p__font-size__m;
            color: $color-dark-red;
            display: block;
            &:before {
                font-family: $icons__font-name;
                content: '\f176';
                margin-right: 5px;
            }
        }
    }
    .opc-place-order {
        @include min-screen($screen__m) {
            width: 60%;
            margin-left: 40%;
        }
        .osc-trust-seal {
            .supp-tel {
                display: none;
            }
        }
    }
    .opc-submit {
        display: flex;
        flex-flow: column nowrap;
        margin-top: 20px;
        @include min-screen($screen__s) {
            flex-flow: row nowrap;
            justify-content: space-between;
        }
        .button {
            width: 100%;
            padding: 27px 35px;
        }
    }
    .place-order-primary {
        @include min-screen($screen__s) {
            order: 1;
        }
    }
    .trusted-checkout {
        display: flex;
        flex-flow: row nowrap;
        margin-top: 20px;
        @include min-screen($screen__s) {
            margin-top: 0;
            order: 0;
        }
        .link-trusted {
            margin-right: 10px;
            margin-top: 5px;
        }
        p {
            font-size: $p__font-size__l;
        }
    }
    .info-box-customer {
        background: $background-color;
        z-index: 5;
        max-width: 355px;
        margin-top: 20px;
        border: 1px solid $color-border;
        padding: 8px 10px 10px;
        hyphens: none;
        position: relative;
        &.sticky {
            position: fixed;
            top: 0;
            margin-top: 10px;
        }
        &.sticky-stop {
            margin-top: 1324px;
        }
        @at-root .logged-in .info-box-customer { // needs check
            margin-top: 83px;
            &.sticky {
                position: fixed;
                top: 0;
                margin-top: 10px;
            }
            &.sticky-stop {
                margin-top: 269px;
            }
        }
        .checkout-progress {
            margin-bottom: 10px;
            h2 {
                margin: 0 0 3px 20px;
            }
            li {
                border-bottom: 1px solid $color-border;
                padding: 12px 0;
                margin: 0;
                span {
                    margin-left: 20px;
                }
            }
        }
        .customer-support {
            position: relative;
            margin-top: 20px;
            h3 {
                margin-left: 20px;
            }
            p {
                margin-bottom: 0;
            }
            ul {
                padding: 0;
            }
            li {
                border-bottom: 1px solid $color-border;
                padding: 10px 0;
                font-size: $p__font-size__l;
                &.supp-tel {
                    padding-left: 55px;
                    &:before {
                        color: $color-grey1;
                        background: none;
                        margin: -4px 0 0 10px;
                    }
                    .phone-link {
                        font-size: $p__font-size__xl;
                        text-decoration: none;
                    }
                }

                &.trusted-checkout {
                    display: flex;
                    flex-flow: row nowrap;
                    border-bottom: none;
                    .link-trusted {
                        margin: 7px 14px 0 15px;
                    }
                    p {
                        width: 70%;
                    }
                }
            }
        }
    }

    div[name="billingAddress.custom_attributes.mposc_field_1"],
    div[name="billingAddress.custom_attributes.mposc_field_2"],
    div[name="billingAddress.custom_attributes.mposc_field_3"],
    div[name="shippingAddress.custom_attributes.custom_attributes.mposc_field_1"],
    div[name="shippingAddress.custom_attributes.custom_attributes.mposc_field_2"],
    div[name="shippingAddress.custom_attributes.custom_attributes.mposc_field_3"] {
        display: none;
    }
}
.checkout-success {
    .actions-toolbar {
        border-top: 1px solid $color-border;
        padding-top: 20px;
        margin: 20px 0 40px;
    }
}
.checkout-registration {
    p {
        font-size: $p__font-size__l;
    }
}
