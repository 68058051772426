.cookie-notice {
    position: relative;
    background: $color-yellow;
    padding: 5px 0;
    margin-top: 43px;
    z-index: 1000;
    @include min-screen($screen__m) {
        margin: 0;
    }
    .wrapper-content {
        position: relative;
    }
    p {
        font-size: $p__font-size__s;
        margin: 0;
        width: 90%;
    }
    a {
        hyphens: none;
    }
    button {
        position: absolute;
        right: 5px;
        bottom: 3px;
        color: $color-black;
        padding: 2px 5px 3px;
        background: none;
        box-shadow: none;
        border: 1px solid $color-black;
        font-size: $p__font-size__s;
        @include min-screen($screen__m) {
            bottom: -2px;
        }
    }
}


.magnify-lens {
    overflow: hidden;
}

.magnifier-large {
    width: auto;
    height: auto;
    max-height: none;
    max-width: none;
    border: none;
    position: absolute;
    z-index: -1;
}

.magnifier-preview {
    z-index: 2;
}
