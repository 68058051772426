//
//  Icons variables
//  _____________________________________________


$icon__position            : before !default;
$icon__text-hide           : false !default;
$icon__height              : 26px !default;
$icon__width               : 26px !default;
$icon__margin              : '' !default;
$icon__vertical-align      : middle !default;

$icon-image__position-x    : 0 !default;
$icon-image__position-y    : 0 !default;

$icon-sprite__position-x   : 0 !default;
$icon-sprite__position-y   : 0 !default;
$icon-sprite__grid         : 26px !default;

$icon-font                 : $icons__font-name !default;
$icon-font__position       : $icon__position !default;
$icon-font__text-hide      : $icon__text-hide !default;
$icon-font__margin         : $icon__margin !default;
$icon-font__size           : inherit !default;
$icon-font__line-height    : $icon-font__size !default;
$icon-font__color          : inherit !default;
$icon-font__color-hover    : false !default;
$icon-font__color-active   : false !default;
$icon-font__vertical-align : $icon__vertical-align !default;
$icon-font__display        : inline-block !default;

$icon-calendar__font-size  : 40px !default;

$iconsprite: url('../images/assets/iconsprite-redesign-2019-12-11-10-40.png');



//
//  Custom Icons   /  old css / it will be replaced
//  ---------------------------------------------


a.link-red:before,
a.link-green:before,
a.link-white:before,
a.link-grey:before {
    display: inline-block;
    margin-right: 6px;
    content: "\f105";
    font-family: $icons__font-name;
}

a.link-refresh:before {
    display: inline-block;
    margin-right: 6px;
    content: "\e803";
    font-family: $icons__font-name;
    font-size: 15px;
}

.icn-round-menu {
    @include icn-round-bordered (30px, 30px, $color-light-red, $color-light-red);
    display: block;
    position: absolute;
    left: 0;
    margin: 0;
    color: #fff;
    font-style: normal;
    font-weight: $font-weight__semibold;
    &.sale {
        padding-top: 0;
    }
}

.icn-round-next {
    @include icn-round-bordered (130px, 130px, $color-border, #fff);
    display: block;
    font-size: $heading__font-size__xxl !important;
    padding: 1em 0 0 0.125em;
    margin: 40% 0 20px;
}

.icn-rectangle {
    color: #fff;
    text-align: center;
    line-height: 1.5em !important;
    @include icn-rectangle(20px, 20px, 3px);
}

.icn-round-small {
    font-size: 18px !important;
    text-align: center;
    line-height: 1.8em !important;
    margin-right: 6px;
    font-weight: normal;
    @include icn-round(33px, 33px);
}

.icn-round-big {
    font-size: 25px !important;
    text-align: center;
    line-height: 1.8em !important;
    margin-right: 8px;
    font-weight: normal;
    @include icn-round(45px, 45px);
}

.icn-round-bordered {
    @include icn-round-bordered (30px, 30px, $color-border, #fff);
}

.icn-red {
    color: #fff;
    background: $color-light-red;
}

.icn-new,
.icn-brand,
.icn-sale {
    text-transform: uppercase;
    font-size: $p__font-size__xs;
    font-family: Arial, 'Helvetica Neue', 'Helvetica', sans-serif;
    font-weight: bold;
}
.icn-new {
    line-height: 3.1;
    .nav-special & {
        line-height: 2.3;
    }
    .product.media & {
        font-size: $p__font-size__m;
        line-height: 3.5;
    }
}

.icn-brand {
    font-size: $p__font-size__l;
    line-height: 1.75;
}

.icn-sale {
    font-size: $p__font-size__xl;
    .product.media & {
        font-size: $p__font-size__xxl;
    }
}

.supp-tel:before,
.supp-newsletter:before,
.supp-email:before,
.supp-delivery:before {
    color: #fff;
    content: "\e804";
    font-family: $icons__font-name;
    font-size: $heading__font-size__l;
    line-height: 1.8em !important;
    font-weight: normal;
    position: absolute;
    left: 0;
    background: $color-light-red;
    display: block;
    text-align: center;
    @include icn-round(40px, 40px);
}

.supp-tel.icn-green:before,
.supp-delivery.icn-green:before {
    background: none;
    color: $color-green;
    border: 1px solid $color-border;
    @include icn-round(45px, 45px);
    font-size: $heading__font-size__xl;
    line-height: 1.7 !important;
}
.supp-delivery.icn-green:before {
    line-height: 1.5 !important;
}

.supp-newsletter:before,
.supp-email:before {
    content: "\f0e0";
    font-size: 18px;
    line-height: 2.25em !important;
    text-indent: 1px;
}

.supp-delivery:before {
    content: "\e805";
    line-height: 1.6em !important;
}

.sbToggle:before {
    color: #666;
    content: "\f107";
    font-family: $icons__font-name;
    font-weight: bold;
    font-size: 18px;
    position: absolute;
    right: 14px;
    top: 11px;
}

.pages .action.next:before,
.pages .previous:after {
    font-size: $heading__font-size__l;
    color: #979797;
    content: "\f104";
    font-family: $icons__font-name;
    position: absolute;
    top: 4px;
    left: -4px;
}

.pages .action.next:before {
    content: "\f105";
}

.btn-refresh span:before {
    display: inline-block;
    margin-right: 8px;
    content: "\e803";
    font-family: $icons__font-name;
    font-size: $p__font-size__l;
    color: $color-grey1;
}

.btn-prev span:before {
    display: inline-block;
    margin-right: 8px;
    content: "\f104";
    font-family: $icons__font-name;
}

.btn-next span:after {
    font-size: $heading__font-size__m;
    display: inline-block;
    margin-left: 10px;
    content: "\f105";
    font-family: $icons__font-name;
}

.btn-action i {
    margin-right: 8px;
    font-size: $p__font-size__l;
}

.icn-cart-trigger:before  {
    font-family: $icons__font-name;
    content: "\f107";
    color: #fff;
    font-style: normal;
    display: block;
    font-size: $p__font-size__xxl;
    line-height: 1;
    text-align: center;
}

.active .icn-cart-trigger:before {
    content: "\f106";
}

.iconed li:before,
.new-customer ul li:before,
.supp-args li:before,
.info-list dt:before,
.block-rating-info ul li:before,
.topbar ul li:before,
.section-articledesigner ul li:before,
.slide-slider-container .slide li:before,
.availability.delivery-status-in-stock:before,
.availability.delivery-status-out-of-stock:before,
.availability.delivery-status-stock-limited:before,
.availability.delivery-status-discontinued:before  {
    font-family: $icons__font-name;
    content: "\e80a";
    color: $color-green;
    font-style: normal;
    width: 26px;
    display: inline-block;
    font-size: 18px;
}
.availability.delivery-status-out-of-stock:before,
.availability.delivery-status-stock-limited:before {
    color: $color-yellow;
}
.availability.delivery-status-discontinued:before  {
    color: $color-red;
    content: "\e812";
}
.product-addto-links:before {
    font-family: $icons__font-name;
    content: '\e81a';
    text-decoration: none;
    margin-right: 8px;
    margin-bottom: 5px;
}
.product-social-links .link-email {
    text-decoration: none;
    .fa-reply {
        margin-right: 8px;
    }
    span {
        text-decoration: underline;
    }
}
.section-articledesigner ul li:before {
    font-size: $heading__font-size__xxl;
    color: $color-light-red;
    position: absolute;
    left: -50px;
    top: -10px;
}
.form-search button:before,
.block-search-news button:before {
    color: #fff;
    content: "\e800";
    font-family: $icons__font-name;
    font-size: $heading__font-size__l;
}


.flex-direction-nav .flex-prev:after,
.flex-direction-nav .flex-next:after {
    font-size: 22px;
    font-family: $icons__font-name;
    content: "\e802";
    color: #fff;
    position: absolute;
    top: 45%;
    left: 0.5em;
    opacity: 0.5;
}

.flex-direction-nav .flex-next:after {
    content: "\e801";
    right: 0.5em;
    left: auto;
}

.flex-direction-nav:hover .prev:after,
.flex-direction-nav:hover .next:after {
    opacity: 1;
}

.validation-advice:before {
    font-size: 12px;
    font-family: $icons__font-name;
    content: "\f176";
    margin: 0 10px 0 8px;
}


// Rating Stars

.stars {
    position: relative;
    float: left;
    height: 20px;
    margin-right: 10px;
}

.stars-percentage {
    display: block;
    height: 22px;
    margin-top: -29px;
    overflow: hidden;
}

.stars:before,
.stars-percentage:before {
    font-family: $icons__font-name;
    content: "\e80c \00a0 \e80c \00a0 \e80c \00a0 \e80c \00a0 \e80c";
    color: $color-yellow;
    font-size: 21px;
}

.stars:before {
    content: "\e80b \00a0 \e80b \00a0 \e80b \00a0 \e80b \00a0 \e80b";
}

// Mobile Nav

.icn-active .fa-chevron-down:before {
    content: '\f106' !important;
}

.icn-active .fa-angle-down:before {
    content: '\f106' !important;
}

.icn-active .fa-minus:before {
    content: '\e81f' !important;
}

.fa-info-circle {
    font-size: 1.5em !important;
    margin: -2px 4px 4px;
}


// Slick

.slick-prev:before,
.slick-next:before {
    &:before {
        position: absolute;
        left: 16px;
        top: 22px;
        content: "\e801";
        font-family: $icons__font-name;
        font-size: 20px;
        color: $color-grey2;
    }
}

.slick-prev:before {
    content: "\e802";
    left: 13px;
}

.slick-prev:hover:before,
.slick-next:hover:before {
    color: $color-grey1;
}

//  Image Icons

.icon-trusted-80 {
    display: block;
    width: 80px;
    height: 80px;
    background: url('../images/assets/icn-trusted-80.png') no-repeat 0 0;
}

.icon-trusted-50 {
    display: block;
    width: 50px;
    height: 50px;
    background: url('../images/assets/icn-trusted-50.png') no-repeat 0 0;

}

.icon-trusted-45 {
    display: block;
    width: 45px;
    height: 45px;
    background: url('../images/assets/icn-trusted-45.png') no-repeat 0 0;
}
.icon-behv {
    display: block;
    width: 105px;
    height: 49px;
    background: url('../images/assets/hach-bevh-mitglied-logo.jpg') no-repeat 0 0;
    background-size: cover;
}
.icon-ekomi {
    display: block;
    width: 52px;
    height: 52px;
    background: url('../images/assets/hach-ekomi-logo.jpg') no-repeat 0 0;
    background-size: cover;
}

.icon-go {
    width: 20px;
    height: 20px;
    background: url('../images/assets/sprt-arrows.png') no-repeat 0 -240px;
    position: absolute;
    top: 9px;
    right: 0;
}

.icn-sustainable {
    @include icn-round-bordered (33px, 33px, lighten($color-sustainable-green, 34%), $color-white);
    width: 33px;
    height: 33px;
    display: block;
    background-color: white;
    background-image: url('../images/assets/icn-sustainable-leaf-hover.svg');
    background-repeat: no-repeat;
    background-position: -4px 3px;
    background-size: 127%;
}
.product.media .icn-madeingermany,
.product.media .icn-madeineurope {
    width: 45px;
    height: 45px;
}

.icn-madeingermany,
.icn-madeineurope {
    width: 33px;
    height: 33px;
    display: block;
    background-repeat: no-repeat;
    background-position: 0 0;
    background-size: 100%;
}
.icn-madeingermany {
    background-image: url('../images/assets/icn-made-in-germany.svg');
}
.icn-madeineurope {
    background-image: url('../images/assets/icn-made-in-europe.svg');
}

//  Variables for Fontello (<i class="icon-search>")


// ''
.icon-search:before {
    content: '\e800';
}

// ''
.icon-right-open:before {
    content: '\e801';
}

// ''
.icon-left-open:before {
    content: '\e802';
}

// ''
.icon-arrows-cw:before {
    content: '\e803';
}

// ''
.icon-phone:before {
    content: '\e804';
}

// ''
.icon-truck:before {
    content: '\e805';
}

// ''
.icon-th:before {
    content: '\e806';
}

// ''
.icon-list:before {
    content: '\e807';
}

// ''
.icon-up-open:before {
    content: '\e808';
}

// ''
.icon-down-open:before {
    content: '\e809';
}

// ''
.icon-ok:before {
    content: '\e80a';
}

// ''
.icon-star-empty:before {
    content: '\e80b';
}

// ''
.icon-star:before {
    content: '\e80c';
}

// ''
.icon-user:before {
    content: '\e80d';
}

// ''
.icon-basket:before {
    content: '\e80e';
}

// ''
.icon-plus:before {
    content: '\e80f';
}

// ''
.icon-minus:before {
    content: '\e810';
}

// ''
.icon-trash-empty:before {
    content: '\e811';
}

// ''
.icon-cancel:before {
    content: '\e812';
}

// ''
.icon-info-circled:before {
    content: '\e813';
}

// ''
.icon-attention:before {
    content: '\e814';
}

// ''
.icon-cog:before {
    content: '\e815';
}

// ''
.icon-location:before {
    content: '\e816';
}

// ''
.icon-comment:before {
    content: '\e817';
}

// ''
.icon-calendar:before {
    content: '\e818';
}

// ''
.icon-gift:before {
    content: '\e819';
}

// ''
.icon-heart:before {
    content: '\e81a';
}

// ''
.icon-pencil:before {
    content: '\e81b';
}

// ''
.icon-cancel-circled:before {
    content: '\e81c';
}

// ''
.icon-flag:before {
    content: '\e81d';
}

// ''
.icon-download:before {
    content: '\e81e';
}

// ''
.icon-th-list:before {
    content: '\e81f';
}

// ''
.icon-print:before {
    content: '\e820';
}

// ''
.icon-spin5:before {
    content: '\e838';
}

// ''
.icon-menu:before {
    content: '\f0c9';
}

// ''
.icon-mail-alt:before {
    content: '\f0e0';
}

// ''
.icon-angle-double-left:before {
    content: '\f100';
}

// ''
.icon-angle-double-right:before {
    content: '\f101';
}

// ''
.icon-angle-left:before {
    content: '\f104';
}

// ''
.icon-angle-right:before {
    content: '\f105';
}

// ''
.icon-angle-up:before {
    content: '\f106';
}

// ''
.icon-angle-down:before {
    content: '\f107';
}

// ''
.icon-reply:before {
    content: '\f112';
}

// ''
.icon-help:before {
    content: '\f128';
}

// ''
.icon-info:before {
    content: '\f129';
}

// ''
.icon-down:before {
    content: '\f175';
}

// ''
.icon-up:before {
    content: '\f176';
}

// ''
.icon-right-big:before {
    content: '\e821';
}

// ''
.icon-left-big:before {
    content: '\e822';
}

// ''
.icon-thumbs-up-alt:before {
    content: '\f164';
}

// ''
.icon-thumbs-down-alt:before {
    content: '\f165';
}


//  Variables to replace icons-blank-theme default icons
$icon-wishlist-full        : '\e81a' !default;
$icon-wishlist-empty       : '\e81a' !default;
$icon-warning              : '\e814' !default;
$icon-update               : '\e803' !default;
$icon-trash                : '\e811' !default;
$icon-star                 : '\e80c' !default;
$icon-settings             : '\e815' !default;
$icon-pointer-down         : '\e607' !default;
$icon-next                 : '\f105' !default;
$icon-menu                 : '\f0c9' !default;
$icon-location             : '\e816' !default;
$icon-list                 : '\e807' !default;
$icon-info                 : '\e813' !default;
$icon-grid                 : '\e806' !default;
$icon-comment-reflected    : '\e817' !default;
$icon-collapse             : '\f107' !default;
$icon-checkmark            : '\e80a' !default;
$icon-cart                 : '\e80e' !default;
$icon-calendar             : '\e818' !default;
$icon-arrow-up             : '\f176' !default;
$icon-arrow-down           : '\f175' !default;
$icon-search               : '\e800' !default;
$icon-remove               : '\e812' !default;
$icon-prev                 : '\f104' !default;
$icon-pointer-up           : '\e618' !default;
$icon-pointer-right        : '\e619' !default;
$icon-pointer-left         : '\e61a' !default;
$icon-flag                 : '\e81d' !default;
$icon-expand               : '\e61c' !default;
$icon-envelope             : '\f0e0' !default;
$icon-compare-full         : '\e81f' !default;
$icon-compare-empty        : '\e81f' !default;
$icon-comment              : '\e817' !default;
$icon-up                   : '\f106' !default;
$icon-down                 : '\f107' !default;
$icon-help                 : '\f128' !default;
$icon-arrow-right-thin     : '\f105' !default;
$icon-arrow-left-thin      : '\f104' !default;
$icon-arrow-down-thin      : '\f107' !default;
$icon-arrow-double-right   : '\f101' !default;
$icon-arrow-double-left    : '\f100' !default;
$icon-account              : '\e80d' !default;
$icon-gift-registry        : '\e628' !default;
$icon-present              : '\e819' !default;
$icon-arrow-up-thin        : '\f106' !default;
$icon-up-open              : '\e808' !default;
$icon-down-open            : '\e809' !default;
$icon-right-open           : '\e801' !default;
$icon-left-open            : '\e802' !default;
$icon-right-big            : '\e821' !default;
$icon-left-big             : '\e822' !default;
$icon-thumbs-up            : '\f164' !default;
$icon-thumbs-down          : '\f165' !default;
$icon-spin5                : '\e838' !default;
$icon-plus                 : '\e80f' !default;
$icon-minus                : '\e810' !default;
