.fotorama__thumb-border {
    @extend .fotorama-print-background;
    position: absolute;
    top: 0;
    left: 0;
    border: 1px solid $color-border;
    border-width: 1px !important;
    margin-top: 0 !important;
    margin-left: 10px;
    backface-visibility: hidden;
    z-index: 9;
    display: none;
    &:first-child {
        margin-left: 0;
    }
    .fotorama__nav--dots & {
        display: none;
    }

    .fotorama__wrap--css3 & {
        @extend .fotorama-transition-for-slide;
    }
}
