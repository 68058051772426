.cms-home-page {
    #maincontent {
        .home-page-header {
            .slider-container {
                @include max-screen($screen__xs) {
                    display: none;
                }
            }
        }
    }
}