//
//  Mixins   // from old css
//  ---------------------------------------------


@mixin transition($where, $time) {
    transition: $where $time;
}

@mixin visually-hidden() {
    position: absolute; 
    overflow: hidden; 
    clip: rect(0 0 0 0); 
    height: 1px; width: 1px; 
    margin: -1px;
    padding: 0;
    border: 0; 
}

@mixin drop-shadow() {
    box-shadow: 0 4px 2px -2px rgba(0, 0, 0, 0.25);
}

@mixin drop-shadow-button() {
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.15);
}

@mixin inset-drop-shadow() {
    box-shadow: inset 0 0 2px #c7c3c3;
}

@mixin inset-drop-shadow-dark() {
    box-shadow: inset 0 0 14px rgba(0, 0, 0, 0.2);
}

@mixin inset-drop-shadow-top-left() {
    box-shadow: inset 1px 1px 2px 0 rgba(0, 0, 0, 0.1);
}
@mixin round-drop-shadow() {
    position: relative;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
}

@mixin round-drop-shadow-after() {
    content: "";
    position: absolute;
    z-index: -1;
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.3);
    bottom: 0;
    left: 7%;
    width: 88%;
    height: 30%;
    border-radius: 100%;
}

@mixin mobile-overlay() {
    position: fixed;
    top: 42px;
    padding: 12px 8px;
    background: #fff;
    float: left;
    width: 100%;
    border: 1px solid $color-border;
    max-width: 420px;
    z-index: 3000;
    border-radius: 1px; //fixes box shadow bug iphone 6+ and other retina devices
}

@mixin two-column-text() {
    width: 100%;
    margin-bottom: 10px;
    @include min-screen($screen__xs) {
        column-count: 2;
    }
}

@mixin hyphen-and-break-word() {
    word-break: break-all;
    word-break: break-word;
    hyphens: auto;
}

@mixin icn-rectangle($width, $height, $radius) {
    width: $width;
    height: $height;
    border-radius: $radius;
}

@mixin icn-round($width, $height) {
    width: $width;
    height: $height;
    border-radius: 50%;
}

@mixin icn-round-bordered($width, $height, $color, $bg) {
    background: $bg;
    width: $width;
    height: $height;
    border-radius: 50%;
    border: 1px solid $color;
    text-align: center;
}


@mixin webkit-appearance {
    appearance: none;
}

@mixin clearfix() {
    content: ".";
    clear: both;
    display: block;
    visibility: hidden;
    height: 0;
}

@mixin high-res-img {
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) { 
        @content;
        background-size: cover;
    }
}


@mixin lib-font-face__fontello (
    $family-name,
    $font-path,
    $font-ver,
    $font-weight: normal,
    $font-style: normal
) {
    @font-face {
        font-family: $family-name;
        src: url('#{$font-path}.eot#{$font-ver}');
        src: url('#{$font-path}.eot#{$font-ver}#iefix') format('embedded-opentype'),
        url('#{$font-path}.woff#{$font-ver}') format('woff'),
        url('#{$font-path}.ttf#{$font-ver}') format('truetype'),
        url('#{$font-path}.svg#{$font-ver}##{$family-name}') format('svg');
        font-weight: $font-weight;
        font-style: $font-style;
        font-display: swap;
    }
}
