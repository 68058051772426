.brands {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    margin: 0;
    padding: 0;
    list-style: none;
    &:after {
        content: '';
        flex: auto;
      }
    .brands__item {
        width: 100%;
        margin: 0;
        margin-bottom: 20px;
        text-align: center;
        @include min-screen($screen__xxs)  {
            width: calc(50% - 20px);
            margin-right: 20px;
        }
        @include min-screen($screen__xs)  {
            width: calc(33.333333333% - 20px);
        }
        @include min-screen($screen__s)  {
            width: calc(25% - 20px);
        }
        @include min-screen($screen__m)  {
            width: calc(20% - 20px);
        }
    }
    .brands__info {
        a {
            text-decoration: none;
        }
    }
}

.brand-content {
    background: $color-grey3;
    margin-bottom: 20px;
    padding-bottom: 20px;
    @include min-screen($screen__s) {
        margin-top: 1px;
    }
    .light-headline {
        text-align: left;
        padding-top: 50px;
    }
    .brand-image {
        text-align: center;
        img {
            width: 60%;
            margin-top: 20px;
        }
    }
}

.brand-description {
    @include two-column-text();
    font-size: $p__font-size__l;
}
