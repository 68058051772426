.toolbar {
    width: 100%;
    position: relative;
    background-color: $color-light-grey2;
    border: 1px solid $color-border;
    padding: 8px 12px;
    overflow: hidden;
    .toolbar__inner {
        display: none;
        flex-flow: row wrap;
        margin-top: 20px;
        &.is-active {
            display: flex;
        }
        @include min-screen($screen__s) {
            display: flex;
            margin: 0;
        }
    }
    .sorter-label,
    .modes-label {
        display: inline-block;
        margin-bottom: 5px;
        margin-right: 10px;
        font-weight: $font-weight__semibold;
        text-transform: uppercase;
    }
    .showtoolbar {
        width: 100%;
        display: block;
        font-weight: $font-weight__semibold;
        text-decoration: none;
        padding-bottom: 3px;
        @include min-screen($screen__s) {
            display: none;
        }
        .icon-plus {
            position: absolute;
            right: 8px;
            top: 5px;
            padding-top: 3px;
           
        }
        &.is-active .icon-plus:before {
            content: '\e810';
        }
    }
  
    .sorter {
        width: 100%;
        padding: 0;
        margin-bottom: 20px;
        @include min-screen($screen__s) {
            width: auto;
            margin-right: 40px;
        }
        @include min-screen($screen__l) {
            margin-bottom: 0;
        }
    }
    
    .modes {
        width: 100%;
        margin-bottom: 20px;
        @include min-screen($screen__s) {
            width: auto;
        }
        @include min-screen($screen__l) {
            margin-bottom: 0;
        }
        .modes-mode {
            border: 1px solid $color-border;
            background-color: $color-light-grey2;
            padding: 5px 9px 2px;
            @include lib-icon-font(
                $icon-grid,
                $_icon-font-size       : 24px,
                $_icon-font-text-hide  : true,
                $_icon-font-color      : $text__color__muted,
                $_icon-font-color-hover: $text__color__muted,
                $_icon-font-display    : inline-block
            );
            &.active {
                background-color: $color-white;
            }
        }
        .mode-list {
            @include lib-icon-font-symbol($icon-list);
        }
    }
    .pagination {
        width: 100%;
        display: flex;
        flex-flow: row wrap;
        align-items: center;
        @include min-screen($screen__l) {
            width: auto;
            align-items: flex-start;
            position: absolute;
            right: 10px;
        }
        .toolbar-amount { 
            font-size: $font-size__xs;
            margin-bottom: 2px;
            margin-right: 10px;
            @include min-screen($screen__l) {
                margin-bottom: 0;
                margin-top: 12px;
            }
            .toolbar-number {
                font-weight: $font-weight__semibold;
                text-transform: uppercase;
            }
        }
    }
}
.pages {
    .label,
    .pages-label,
    .action > span {
        @include lib-visually-hidden();
    }
    .pages-items {
        background: $color-white;
        border: 1px solid $color-border;
        display: flex;
        flex-flow: row nowrap;
        .item {
            min-width: 40px;
            height: 40px;
            margin: 0;
            border-right: 1px solid $color-border;
            vertical-align: middle;
            text-align: center;
            &.current {
                background-color: $color-light-grey2;
            }
            .page, .action {
                display: block;
                position: relative;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                font-size: $p__font-size__xl;
                font-weight: $font-weight__semibold;
                text-decoration: none;
                letter-spacing: normal;
                line-height: 40px;
                color: $color-grey1;
                &:hover {
                    background: none;
                    text-decoration: none;
                }
                &:before, &:after {
                    position: relative;
                    top: 0;
                    left: 0;
                }
            }
            .action {
                line-height: 39px;
            }
            &:last-child {
                border-right: 0;
            }
        }
    }
}
// different display for toolbar bottom
.products.grid + .toolbar,
.products.list + .toolbar,
.product-list-right + .toolbar {
    height: 80px;
    background-color: transparent;
    border: none;
    .toolbar__inner {
        display: block;
        
    }
    .toolbar-sorter,
    .modes,
    .toolbar-amount,
    .showtoolbar {
        display: none;
    }
    .pagination {
        position: relative;
    }
    .pages {
        position: absolute;
        right: 0;
        top: 0;
    }
}
.order-products-toolbar {
    padding: 0 10px;
    border: 1px solid $color-border;
    border-top: none;
    padding-bottom: 10px;
    .pager {
        display: flex;
        flex-flow: column nowrap;
        @include min-screen($screen__xxs) {
            flex-flow: row nowrap;
            justify-content: space-between;
        }
    }
    .toolbar-amount {
        font-weight: $font-weight__semibold;
        margin-bottom: 10px;
        @include min-screen($screen__xxs) {
            margin-top: 9px;
        }
    }
    .limiter {
        font-size: $p__font-size__l;
        .limiter-label {
            font-weight: $font-weight__regular !important;
            margin-right: 5px;
        }
        .limiter-options {
            width: auto;
            margin-right: 5px;
        }
    }
}
