@charset "UTF-8";

// Default Variables

// Slick icon entity codes outputs the following
// "\2190" outputs ascii character "←"
// "\2192" outputs ascii character "→"
// "\2022" outputs ascii character "•"

$slick-font-path: "./fonts/" !default;
$slick-font-family: "slick" !default;
$slick-loader-path: "./" !default;
$slick-arrow-color: white !default;
$slick-dot-color: black !default;
$slick-dot-color-active: $slick-dot-color !default;
$slick-prev-character: "\2190" !default;
$slick-next-character: "\2192" !default;
$slick-dot-character: "\2022" !default;
$slick-dot-size: 6px !default;
$slick-opacity-default: 0.75 !default;
$slick-opacity-on-hover: 1 !default;
$slick-opacity-not-active: 0.25 !default;

@function slick-image-url($url) {
    @if function-exists(image-url) {
        @return image-url($url);
    }
    @else {
        @return url($slick-loader-path + $url);
    }
}

@function slick-font-url($url) {
    @if function-exists(font-url) {
        @return font-url($url);
    }
    @else {
        @return url($slick-font-path + $url);
    }
}

/* Icons */
@if $slick-font-family == "slick" {
    @font-face {
        font-family: "slick";
        src: slick-font-url("slick.eot");
        src: slick-font-url("slick.eot?#iefix") format("embedded-opentype"), slick-font-url("slick.woff") format("woff"), slick-font-url("slick.ttf") format("truetype"), slick-font-url("slick.svg#slick") format("svg");
        font-weight: normal;
        font-style: normal;
    }
}

/* Arrows */

.slick-prev,
.slick-next {
    @include icn-round-bordered(45px, 45px, $color-border, rgba(255,255,255,0.95));
    position: absolute;
    display: block;
    cursor: pointer;
    top: 50%;
    transform: translate(0, -50%);
    padding: 0;
    outline: none;
    z-index: 1;
    font-size: 0;
    &:hover,
    &:focus {
        background: $color-white;
        border: 1px solid $color-border;
    }
    &.slick-disabled:before {
        opacity: $slick-opacity-not-active;
    }

}
.slick-prev {
    left: 0;
    .touch & {
        left: -20px;
    }
}
.slick-next {
    right: 0;
    .touch & {
        right: -20px;
    }
}
.touch .brands .slick-prev {
    left: -10px;
}
.touch .brands .slick-next {
    right: -10px;
}
.slick-prev:before,
.slick-next:before {
    position: absolute;
    left: 16px;
    top: 11px;
    content: "\e801";
    font-family: $icons__font-name;
    font-size: 20px;
    color: $color-grey4;
}

.slick-prev:before {
    content: "\e802";
    left: 13px;
}

.slick-prev:hover:before,
.slick-next:hover:before {
    color: $color-grey1;
}

/* Dots */

.slick-dotted.slick-slider {
    margin-bottom: 30px;
}

.slick-dots {
    position: absolute;
    bottom: 10px;
    list-style: none;
    display: block;
    text-align: center;
    padding: 0;
    margin: 0;
    width: 100%;
    li {
        position: relative;
        display: inline-block;
        height: 20px;
        width: 20px;
        margin: 0 5px;
        padding: 0;
        cursor: pointer;
        button {
            display:block;
            @include icn-round(16px, 16px);
            background: $color-white;
            box-shadow: 0 3px 2px -2px rgba(0,0,0,0.4);
            text-indent: 100%;
            white-space: nowrap;
            overflow: hidden;
            cursor: pointer;
            padding: 0;
            &:hover,
            &:focus,
            &[aria-selected="true"] {
                background: $color-red;
            }

        }
    }
}


.page-layout-home-page {
    .lazyload,
    .lazyloading {
        opacity: 0;
    }
    .lazyloaded {
        opacity: 1;
        transition: opacity .2s;
    }
    .slick-fade-in-arrows {
        .slick-arrow {
            opacity: 0;
        }
        &.slick-rendered {
            .slick-arrow {
                opacity: 1;
                transition: opacity .4s;
            }
        }
    }
    .slick-fade-in-dots {
        .slick-dots {
            opacity: 0;
        }
        &.slick-rendered {
            .slick-dots {
                opacity: 1;
                transition: opacity .4s;
            }
        }
    }
}
