.amazon-checkout-now,
.amazon-or-checkout-now,
.amazon-express-title {
    text-transform: uppercase;
    font-weight: bold;
    margin-bottom: 5px;
    font-size: 14px;
    color: #000;
}
.amazon-checkout-button {
    & > div[role=button] {
        width: 100% !important;
        height: 64px !important;
    }
}
.amazon-button-column-tooltip {
    font-size: 12px;
    margin-top: 9px;
    margin-bottom: 16px;
}
.amazon-product-button .amazon-checkout-button:active {
    pointer-events: none;
}

