// legacy mode for now
// sass-lint:disable-all


.sbHolder:after,
.sbOptions:after {
    display:block;
    zoom:1;
    *zoom:1;
    content:"\0020";
    height:0;
    clear:both;
    visibility:hidden;
    overflow:hidden;
}


/* Select Replace
-------------------------------------------------- */
.sbHolder {
    display: inline-block;
    zoom: 1;
    position: relative;
    height: 40px;
    border: 1px solid #d3d3d3;
    border-radius: 2px;
    line-height: 1em;
    font-weight: 400;
    text-align: left;
    background: #fff;
    &:hover {
        @include drop-shadow-button();
    }
    ul {
        top: 25px !important;
    }
}

.sbSelector {
    zoom: 1;
    width: auto;
    height: 40px;
    line-height: 36px;
    padding:0 10px;
    outline:none;
}

.sbSelector:link, .sbSelector:visited, .sbSelector:hover{
    outline:none;
    text-decoration:none;
    }

.sbToggle {
    float: right;
    width: 40px;
    height: 40px;
    outline: none;
    border-left: 1px solid #d3d3d3;
    text-decoration: none;
    }
.sbOptions {
    position:absolute;
    list-style:none;
    top:100%;
    left:0;
    zoom:1;
    min-width:100%;
    margin:0;
    padding:0;
    z-index:1000;
    background-color:#ffffff;
    -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    }

.sbOptions li {
    position:relative;
    display:block;
    white-space:nowrap;
    cursor:pointer;
    margin:0;
    padding:0;
    background-color:#ffffff;
    }

.sbOptions li:hover {
    background:#f5f5f5;
    }

.sbOptions li:hover {
    background:#f5f5f5;
    }

.sbOptions a {
    display: block;
    outline: none;
    padding: 9px 12px;
    color: #666;
    text-indent: 10px;
    border-bottom: 1px solid #ccc;
    }

.sbOptions a:link, .sbOptions a:visited {
    color:#666;
    text-decoration:none;
    }

.sbOptions a:hover,
.sbOptions a:focus,
.sbOptions a.sbFocus {
    color:#333;
    }

.sbOptions li.last a {
    border-bottom:none;
    }

.sbOptions li.last a {
    border-bottom:none;
    }

.sbOptions .sbDisabled {
    display:block;
    color:#AAA;
    border-bottom:1px solid #ccc;
    padding:10px 0px 10px 10px;
    cursor:not-allowed;
    }

.sbOptions .sbGroup {
    display:block;
    font-weight:600;
    border-bottom:1px solid #ccc;
    }

.sbOptions .sbSub {
    padding-left:17px;
    }