.fallback-restricted {
    height: 10px;
    margin-top: 20px;
    display: block;

    label {
        font-size: $p__font-size__l;
        display: inline-block;
        font-weight: bold;
        cursor: pointer;
    }
}

ul.print_area_switch {
    z-index: 2;
    margin-top: 30px;
}

ul.print_area_switch li {
    width: 49.9%;
    height: 50px;
    margin: 0;
    padding: 0 0 10px 0;
    overflow: hidden;
    font-size: 11px;
    font-weight: 700;
    text-align: center;
}

ul.print_area_switch li.active:after {
    position: absolute;
    bottom: -12px;
    left: 50%;
    height: 0;
    width: 0;
    margin-left: -6px;
    border-top: 6px solid #e6eaed;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 6px solid transparent;
    content: '';
}

ul.print_area_switch li a {
    display: block;
    text-decoration: none;
    height: 40px;
    margin: 0;
    line-height: 40px;
}

ul.print_area_switch li a:hover {
    text-decoration: underline;
}

ul.print_area_switch li a.active {
    background: #e6eaed;
}

.close_print_area {
    background: transparent;
}

div#designer-hasuserdesigns-message {
    margin-bottom: 20px;
}

ul.print_area_switch li.active {
    height: 50px;
    background: url("../images/assets/bgd-marker.png") 50% -120px no-repeat;
}

ul.print_area_switch li a {
    display: block;
    text-decoration: none;
    height: 40px;
    margin: 0;
    line-height: 40px;
}

ul.print_area_switch li a:hover {
    text-decoration: underline;
}

ul.print_area_switch li a.active {
    background: #e6eaed;
}

.close_print_area {
    background: transparent;
}

div#designer-hasuserdesigns-message {
    margin-bottom: 20px;
}

.print_areas_overview {
    margin-bottom: 10px;
}

.print_areas_overview #designer-open {
    position: relative !important;
    display: inline-block;
    margin: 15px 0 !important;
}

.print_areas_overview .send_offer {
    margin: 0 0 15px 0;
    font-size: 12px;
}

.print_areas_overview .short_printareas ul {
    margin: 10px 0;
    font-weight: 600;
}

#print_areas {
    font-size: $p__font-size__l;
    margin: 20px 0 0 0;
    padding: 10px;
    background: #f6f7f9;
    display: none;

    .print-area-header {
        padding: 10px 0;
        position: relative;
        cursor: pointer;
        border-top: 1px solid $color-border;

        &.print_area_1_add {
            border: none;
        }

        input {
            margin-top: 2px;
            position: absolute;
        }

        .print_area_title {
            margin-left: 25px;
        }

        .print-type-list {
            position: absolute;
            right: 0;
            top: 8px;
        }
    }
    .print_area_fields {
        padding-bottom: 10px;
    }

    .options-field {
        margin: 10px 0;
        position: relative;
        height: 40px;
    }

    .label-options {
        margin-top: 10px;
        position: absolute;
        left: 0;
        font-weight: $font-weight__regular;
    }

    .sbHolder {
        width: 57%;
        position: absolute;
        right: 0;
    }

    .print-area-list {
        width: 100%;
        margin-top: -15px;
        td {
            padding: 5px 0;

            &:nth-child(even) {
                text-align: right;
            }
        }

        .end_costs {
            font-weight: bold;
        }
    }

    .options-list li {
        margin: 9px 0 0 10px;
        display: inline-block;
        width: 120px;
    }

    select {
        width: 210px;
        margin: 10px 0;
        border: none;
        background: #fff url("../images/assets/bgd-select.png") right 0 no-repeat;
    }

    textarea {
        position: relative;
        margin: 10px 0 0 !important;
        width: 100%;
    }

    .note {
        display: none;
    }

    .option.count-colors .sbHolder {
        position: relative;
        right: auto;
    }

    .option .Korrekturabzug.field,
    .option .Korrekturmuster.field {
        display: flex;
        flex-flow: row nowrap;
        margin-bottom: 10px;

        label {
            margin-right: 10px;
            width: 130px;
            font-weight: $font-weight__regular;
        }
        input {
            margin-top: 4px;
        }
    }
}

.print_area_title h4 {
    font-size: 1em;
    font-weight: 700;
}

.print_area_title h5 {
    font-size: 1em;
    font-weight: 400;
}

/* Print Areas Block Styling */

.print_area_1_active,
.print_area_2_active,
.print_area_3_active,
.print_area_4_active,
.print_area_5_active,
.print_area_6_active {
    display: none;
}

.print_area_0 {
    margin: 10px 20px 10px 0;
}

.print_area_0 .label-options {
    font-weight: 700;
}

.print_area_1 .input-text,
.print_area_2 .input-text,
.print_area_3 .input-text,
.print_area_4 .input-text,
.print_area_5 .input-text,
.print_area_6 .input-text {
    width: 100%;
}

.upload-logo label,
.print-area-list label,
.field.textarea .label,
.option.count-colors .label {
    font-weight: $font-weight__regular;
}
.upload-logo {
    input[disabled] {
        opacity: 0.4;
    }
}
.upload-box {
    position: relative;
    margin: 15px 0;
    display: flex;
    flex-flow: row wrap;
    align-items: stretch;
    justify-content: flex-end;
}

.qq-uploader {
    position: relative;
}

.qq-uploader .qq-upload-button input {
    position: absolute;
    top: 0;
    left: 0;
    width: auto;
    max-width: 100%;
    height: 42px;
}
.option.count-colors {
    label {
        display: none;
        &[for="with-logo"] {
            display: block;
        }
    }
    .control {
        display: flex;
        justify-content: flex-end;
    }
}
.upload-box .qq-upload-drop-area,
.upload-box .qq-upload-extra-drop-area {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    min-height: 30px;
    z-index: 2;
    background: #FF9797;
    text-align: center;
}

.upload-box .btn-inverse,
.upload-box .btn-red {
    padding: 8px 14px;
    height: 35px;
    margin-left: 10px;
}

.upload-box .btn-inverse:hover {
    border: 1px solid $color-border;
}

.upload-box .qq-upload-spinner {
    width: 30px;
    height: 30px;
    margin-top: 10px;
}

/* Misc Options  */

dd.values_Korrekturmuster {
    margin-bottom: 10px;
}

.kind_of_print {
    display: none;
}

.width {
    display: none;
}

.height {
    display: none;
}

.same_position {
    display: none;
}

.cost {
    display: none;
}

.upload-image-dimensions {
    display: none;
}

