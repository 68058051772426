
ul.tabs {
    display: block;
    margin: 0;
    padding: 0;
    border: 1px solid $color-border;
    background: $color-light-grey2;
    height: 60px;
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    .designer-wrapper & {
        height: auto;
    }
    li {
        display: block;
        width: 100%;
        width: auto;
        padding: 0;
        margin: 0;
        position: relative;
        .icn-topseller,
        .icon-sustainable,
        .icn-new {
            position: absolute;
            bottom: 12px;
            left: 14px;
        }
        .icn-new, .icn-topseller {
            @include icn-round-bordered (33px, 33px, $color-border, $color-white);
            line-height: 3.1;
        }
        .icn-new {
            line-height: 3.1;
        }
        .icn-topseller {
            line-height: 1.7;
        }
        .icon-sustainable {
            @include icn-round-bordered (33px, 33px, $color-border, $color-white);
            background-color: white;
            background-image: url('../images/assets/icn-sustainable-leaf.svg');
            background-repeat: no-repeat;
            background-position: -4px 2px;
            background-size: 130%;
        }
        .fa-eye,
        .fa-leaf {
            line-height: 1.7;
        }
        a {
            display: block;
            text-decoration: none;
            font-weight: $font-weight__semibold;
            padding: 15px 14px 16px;
            border-top: 5px solid $color-light-grey2;
            border-right: 1px solid $color-border;
            visibility: visible;
            opacity: 1;
            transition: visibility 0s, opacity 0.1s ease-in-out;
            .designer-wrapper & {
                font-size: $p__font-size__s;
                padding: 15px 14px 20px;
                @include min-screen($screen__xxs) {
                    font-size: $p__font-size__xl;
                }
            }
            &:hover {
                .icon-sustainable {
                    //border-color: $color-sustainable-light-green;
                    background-image: url('../images/assets/icn-sustainable-leaf-hover.svg');
                }
            }
            &.active {
                position: relative;
                background: #fff;
                border-top: 5px solid $color-light-red;
                .icn-topseller,
                .icn-new {
                    background-color: $color-light-red;
                    color: #fff;
                    border-color: transparent;
                }
                .icon-sustainable {
                    background-image: url('../images/assets/icn-sustainable-leaf-active.svg');
                    background-color: $color-sustainable-green;
                    border-color: transparent;
                }
                &:after {
                    content: '';
                    position: absolute;
                    top: 50px;
                    height: 5px;
                    width: 100%;
                    background: #fff;
                    left: 0;
                }
                .icon-thumbs-up-alt {
                    line-height: 1.7;
                }
                .fa-leaf {
                    line-height: 1.8;
                }
            }

            &.hidden {
                visibility: hidden;
                opacity: 0;
            }
        }
    }
    &.with-icons li a {
        padding: 14px 14px 0 45px;
        height: 59px;
        .designer-wrapper & {
            padding: 14px 14px 20px 45px;
        }
        span {
            display: none;
            @include min-screen($screen__xs) {
                display: block;
                padding-left: 10px;
            }
        }
    }
    .cms-page-view & {
        @include max-screen($screen__l) {
            display: block;
            height: auto;
            border-bottom: 0;
            li {
                a {
                    border-top: 0;
                    border-right: 0;
                    border-bottom: 0;
                    border-bottom: 1px solid $color-border;
                    &.active {
                        border-top: 0;
                        border-left: 5px solid $color-light-red;
                        padding-left: 9px;
                        &:after {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}

ul.tabs-content {
    position: relative;
    display: block;
    border: 1px solid $color-border;
    border-top: none;
    margin: 0;
    width: 100%;
    padding: 20px 14px 20px;
    > li {
        list-style: none;
        margin: 0;
        &.active {
            display: block;
        }
    }
    .view-all {
        position: absolute;
        top: -38px;
        right: 15px;
        font-size: $p__font-size__m;
        @include min-screen($screen__xxs) {
            font-size: $p__font-size__l;
        }
    }
}

.tabbed-product-slider {
    min-height: 120px;
    .fa-spinner {
        position: absolute;
        top: 50%;
        left: 48%;
        opacity: 0.6;
    }
}
