//  Header

.page-header {
    position: relative;
    width: 100%;
    height: 150px;
    border-bottom: 1px solid $color-border;
    .checkout-cart-index &,
    .onestepcheckout-index-index & {
        @include drop-shadow();
    }
    .onestepcheckout-index-index & {
        height: 118px;
        @include min-screen($screen__m) {
            height: 150px;
        }
    }
    @include min-screen($screen__m) {
        height: 138px;
        border: none;
    }
    .topbar {
        display: none;
        @include min-screen($screen__m) {
            display: block;
            padding: 8px 0 9px;
            border-bottom: 1px solid $color-border;
        }
        ul {
            position: relative;
            display: flex;
            flex-flow: row nowrap;
            justify-content: space-between;
            margin: 0;
            li {
                position: relative;
                display: inline-block;
                vertical-align: middle;
                padding-left: 26px;
                margin: 0;
                font-size: $p__font-size__s ;
                line-height: $line-height__xl;
                @include min-screen($screen__l) {
                    font-size: $p__font-size__m + 1px;
                }
                &:before {
                    position: absolute;
                    left: 3px;
                    top: -1px;
                    line-height: $line-height__s;
                    @include min-screen($screen__l) {
                        top: 0;
                    }
                }
                &.has-noCheck:before {
                    display: none;
                }
            }
        }
        .topbar-link--image {
            display: block;
            width: 200px;
            height: 22px;
            background: transparent url('../images/assets/bgd-payment.gif') no-repeat right center;
            background-size: contain;
            @include min-screen($screen__l) {
                width: 260px;
            }
        }
    }
    @include max-screen($screen__xs) {
        height: 110px;
    }
}

.logo-container {
    position: absolute;
    top: 56px;
    left: -8px;
    max-width: 50%;
    position: relative;
    z-index: 5;
    @include min-screen($screen__m) {
        top: 10px;
        left: 4px;
    }
    .onestepcheckout-index-index & {
        top: 20px;
    }
    .page-print & {
        top: 0;
        left: 0;
    }
    .logo {
        background: url(../images/logo/hach-logo.gif) top left;
        display: block;
        width: 240px;
        height: 79px;
        @include high-res-img {
            background-image: url('../images/logo/hach-logo@2x.gif');
        }
        a {
            display: block;
            width: 240px;
            height: 79px;
        }
        span {
            display: block;
            font-size: 0;
        }
        @include max-screen($screen__xs) {
            width: 152px;
            height: 50px;
            background-size: contain;
        }
    }
}

.header {
    .phone-newsletter.is--desktop {
        display: none;
        position: absolute;
        right: 260px;
        top: 28px;
        @include min-screen($screen__m) {
            display: block;
        }

        .blog-index-index &,
        .blog-view-index &,
        .blog-category-index & {
            right: 230px;
        }
    }
    .news-back-to-shop {
        display: none;
        position: absolute;
        right: 17px;
        top: 28px;
        @include min-screen($screen__m) {
            display: block;
        }
    }
    .header-icons {
        position: absolute;
        right: 8px;
        top: 68px;
        display: flex;
        flex-flow: row nowrap;
        @include min-screen($screen__m) {
            right: 25px;
            top: 24px;
        }
        .onestepcheckout-index-index & {
            top: 33px;
        }
        .blog-index-index &,
        .blog-view-index &,
        .blog-category-index & {
            display: none;
        }
        .header-icons__link {
            margin-left: 15px;
        }

        .is-behv,
        .is-ekomi {
            display: none;
            @include min-screen($screen__m) {
                display: block;
            }
        }

        @include max-screen($screen__xs) {
            top: 50px;
        }
    }
}

.sticky-wrapper {
    width: 100%;
    height: 42px;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 102;
    background-color: $color-light-grey2;
    @include drop-shadow();
    @include min-screen($screen__m) {
        position: relative;
        height: 60px;
        border-top: 1px solid $color-border;
        @include min-screen($screen__s) {
            .checkout-cart-index &,
            .blog-index-index &,
            .blog-view-index &,
            .blog-category-index & {
                display: none;
            }
        }
    }
    &.sticked {
        position: fixed;
        top: 0;
        left: 0;
        margin-top: 0;
        .wrapper-content .nav-item {
            &.item-stickylogo {
                width: 68px;
            }
        }
    }
    .page-print & {
        display: none;
    }
    .wrapper-content {
        width: 100%;
        position: relative;
        padding: 0;
        @include min-screen($screen__m) {
            padding: 0 2%;
        }
        @include min-screen($screen__xl)  {
            padding: 0 1%;
        }
        .nav-wrapper {
            position: relative;
            width: 100%;
            display: block;
            margin: 0;
            height: 60px;
        }
        .nav-item {
            position: relative;
            float: left;
            height: 42px;
            margin: 0;
            @include min-screen($screen__m) {
                height: 60px;
                border-right: 1px solid $color-border;
                @include transition(all, 0.16s ease-in-out);
            }
            &.item-nav,
            &.item-search,
            &.item-contact,
            &.item-account {
                .icon-menu,
                .label[for=search]:before,
                .icon-phone,
                .icon-user {
                    width: 20px;
                    text-align: center;
                    font-size: 1.2em;
                    color: $color-grey3;
                }
            }
            .action {
                display: block;
                font-weight: $font-weight__semibold;
                text-decoration: none;
                &:hover {
                    color: $color-grey1;
                }
            }
            [class^="icon-angle-"] {
                display: none;
                position: absolute;
                right: 5px;
                top: 19px;
                @include min-screen($screen__m) {
                    display: block;
                }
            }
            .action.active .icon-angle-down {
                display: none;
            }
            .icon-angle-up {
                display: none;
            }
            @include min-screen($screen__m) {
                .action.active .icon-angle-up {
                    display: block;
                }
            }
            &.item-stickylogo {
                display: none;
                @include min-screen($screen__m) {
                    display: block;
                    background: $color-red;
                    width: 0;
                    border: none;
                    overflow: hidden;
                    padding-top: 0;
                    padding-bottom: 0;
                    a {
                        background: url(../images/logo/logo-thmb.png);
                        background-position: 0 -4px;
                        background-size: 68px auto;
                        display: block;
                        width: 68px;
                        height: 60px;
                        text-indent: -99999px;
                    }

                }
            }

            &.item-nav {
                border-right: 1px solid $color-border;
                padding: 8px 8px 12px 0;
                @include min-screen($screen__m) {
                    border-left: 1px solid $color-border;
                    padding: 17px 28px 12px 5px;
                }
                .icon-menu {
                    margin-right: 2px;
                    margin-top: 2px;
                }

                .text {
                    display: inline;
                    position: relative;
                    top: -1px;
                }
            }

            &.item-account {
                float: right;
                padding: 9px 10px;
                @include min-screen($screen__m) {
                    padding: 17px 28px 12px 5px;
                    float: left;
                }
                .text {
                    display: none;
                    @include min-screen($screen__m) {
                        display: inline;
                        position: relative;
                        top: -1px;
                    }
                }
            }

            &.item-search {
                float: left;
                padding: 0;
                @include min-screen($screen__m) {
                    width: 440 / $inner__value * 100%;
                    @at-root .sticky-wrapper.sticked .wrapper-content .nav-item.item-search {
                        width: 340 / $inner__value * 100%;
                    }
                }
                @include min-screen($screen__ml) {
                    width: 500 / $inner__value * 100%;
                    @at-root .sticky-wrapper.sticked .wrapper-content .nav-item.item-search {
                        width: 410 / $inner__value * 100%;
                    }
                }
                @include min-screen($screen__l) {
                    width: 530 / $inner__value * 100%;
                    @at-root .sticky-wrapper.sticked .wrapper-content .nav-item.item-search {
                        width: 440 / $inner__value * 100%;
                    }
                }
                @include min-screen($screen__xl) {
                    width: 470 / $inner__value * 100%;
                    @at-root .sticky-wrapper.sticked .wrapper-content .nav-item.item-search {
                        width: 390 / $inner__value * 100%;
                    }
                }
                .block-title {
                    display: none;
                }
                .label {
                    @include lib-icon-font(
                        $_icon-font-content      : $icon-search,
                        $_icon-font-size         : 35px,
                        $_icon-font-text-hide    : true
                    );
                    display: inline-block;
                    padding: 9px 12px;
                    border-right: 1px solid $color-border;
                    cursor: pointer;
                    @include min-screen($screen__m) {
                        display: none;
                    }
                }
                .mage-dropdown-dialog {
                    margin-left: -119px;
                    @include min-screen($screen__xs) {
                        margin-left: -1px;
                    }
                    @include min-screen($screen__m) {
                        display: block !important;
                    }
                }

                .block-search .control {
                    display: flex;
                    flex-flow: row wrap;
                    background: $color-white;
                    padding: 12px 8px 12px 8px;
                    margin: 0;
                    @include min-screen($screen__m) {
                        position: static;
                    }
                    .amsearch-form-block {
                        display: flex;
                        flex-flow: row wrap;
                    }
                    .amsearch-wrapper-input {
                        width: calc(100% - 60px);
                    }
                    input {

                        font-size: $p__font-size__xl;
                        padding: 18px 24px 18px 24px;
                        @include min-screen($screen__m) {
                            width: calc(100% - 60px);
                            position: absolute;
                            left: 0;
                            top: 0;
                            height: 59px;
                        }
                        &::placeholder {
                            color: $color-grey1;
                        }
                    }
                }

                .actions {
                    width: 60px;
                    order: 2;

                    @include min-screen($screen__m) {
                        position: absolute;
                        right: 0;
                        top: -1px;
                        order: 0;
                    }
                    .search {
                        @include lib-icon-font(
                            $_icon-font-content      : $icon-right-big,
                            $_icon-font-size         : 1em,
                            $_icon-font-color        : $color-white
                        );
                        background: $color-red;
                        width: 60px;
                        height: 60px;
                        position: relative;
                        border-radius: 0;
                        @include min-screen($screen__m) {
                            @include lib-icon-font(
                                $_icon-font-content: $icon-search,
                                $_icon-font-size         : 1.5em,
                                $_icon-font-line-height: 30px
                            );
                            &:before {
                                position: absolute;
                                left: 19px;
                                top: 15px;
                                color: $color-white !important;
                            }
                            background: $color-grey3;
                        }
                        &[disabled] {
                            background: $color-red;
                            opacity: 1;
                            @include min-screen($screen__m) {
                                background: $color-grey3;
                            }
                        }
                        span {
                            display: none;
                        }
                    }
                }
            }

            &.item-directorder {
                display: none;
                float: left;
                padding: 18px 12px 12px 12px;
                border: none;
                @include min-screen($screen__m) {
                    display: block;
                }
                @include min-screen($screen__l) {
                    border-right: 1px solid $color-border;
                }
                @include min-screen($screen__xl) {
                    border: none;
                }
            }
            &.item-contact {
                float: right;
                border-left: 1px solid $color-border;
                border-right: 1px solid $color-border;
                padding: 9px 8px;
                @include min-screen($screen__m) {
                    display: none;
                }

            }
            &.item-minicart {
                position: relative;
                float: right;
                background-color: $color-light-green;
                border-right: none;
                width: 48px;
                @include min-screen($screen__m) {
                    border-right: none;
                    padding: 14px 46px 12px 14px;
                    width: 70px;
                }
                @include min-screen($screen__xl) {
                    width: auto;
                }
                .item-minicart__link {
                    color: $color-white;
                    text-decoration: none;
                    font-weight: $font-weight__semibold;
                    cursor: pointer;
                    @include min-screen($screen__xl) {
                        .icon-basket {
                            display: none;
                        }
                        .item-minicart__counter {
                            display: none;
                        }
                    }
                }
                .item-minicart__desktoplink {
                    display: none;
                    font-weight: $font-weight__semibold;
                    color: $color-white;
                    text-decoration: none;
                    @include min-screen($screen__xl) {
                        display: inline-block;
                    }
                }
                .icon-basket {
                    font-size: $p__font-size__xl + 4px;
                    color: $color-white;
                    position: absolute;
                    left: 8px;
                    top: 8px;
                    @include min-screen($screen__m) {
                        font-size: $p__font-size__xxl;
                        left: 18px;
                        top: 14px;
                    }
                    @include min-screen($screen__xl) {
                        position: relative;
                        left: auto;
                        top: auto;
                    }
                }
                .item-minicart__counter {
                    position: absolute;
                    top: 0;
                    right: 2px;
                    width: 18px;
                    height: 18px;
                    background-color: $color-green;
                    font-size: $p__font-size__s;
                    color: #fff;
                    border-radius: 50%;
                    text-align: center;
                    line-height: 1.6;
                    @include min-screen($screen__m) {
                        top: 2px;
                        right: 5px;
                    }
                    @include min-screen($screen__xl) {
                        position: relative;
                        top: auto;
                        right: auto;
                        width: auto;
                        height: auto;
                        background-color: transparent;
                        font-size: $font-size__base;
                        border-radius: 0;
                        text-align: left;
                        &:before {
                            content: '(';
                            margin-right: -3px;
                        }
                        &:after {
                            content: ')';
                            margin-left: -3px;
                        }
                    }
                    .loading-mask {
                        background: none;
                    }
                }
                .item-minicart__showcart {
                    position: absolute;
                    right: 14px;
                    top: 0;
                    height: 60px;
                    width: 20px;
                    text-decoration: none;
                    @include min-screen($screen__xl) {
                        border-left: 1px solid rgba(255, 255, 255, 0.3);
                    }
                }
                .icn-cart-trigger {
                    display: none;
                    @include min-screen($screen__m) {
                        display: block;
                        margin-top: 22px;
                        margin-left: 15px;
                    }
                    @include min-screen($screen__xl) {
                        margin-top: 24px;
                        margin-left: 11px;
                    }
                    &:before {
                        font-size: $p__font-size__xl + 1px;
                    }
                }
            }
        }
    }
    .dropdown-wrapper {
        position: relative;
        display: flex;
    }
    .ui-dialog-content {
        @include drop-shadow();
        position: fixed;
        top: 42px;
        width: 100%;
        min-width: auto;
        max-width: 420px;
        max-height: 89vh;
        overflow-y: auto;
        padding: 10px 8px;
        border: 1px solid $color-border;
        z-index: $dropdown-list__z-index;
        background-color: rgba(255, 255, 255, 0.98);
        @include min-screen($screen__m) {
            position: absolute;
            top: -1px;
            max-width: none;
        }
        &.is-minicart {
            right: 0;
            width: 100%;
            max-width: none;
            padding: 0;
            @include min-screen($screen__xs) {
                width: 70%;
            }
            @include min-screen($screen__m) {
                width: 50%;
            }
        }
        &.is-mainNavigation {
            left: 0;
        }
        &.is-account {
            right: 0;
            @include min-screen($screen__m) {
                right: auto;
                left: 0;
                width: 326px;
                @at-root .sticky-wrapper.sticked .wrapper-content .ui-dialog-content.is-account {
                    width: 393px;
                }
            }
        }
        &.is-contact {
            right: 0;
        }
        .block {
            margin-bottom: 0;
        }
    }
}

.phone-newsletter {
    li {
        position: relative;
        float: left;
        padding-left: 46px;
        margin-right: 20px;

        &.supp-tel {
            a {
                display: block;
                text-decoration: none;
            }

            strong {
                display: block;
            }

            span {
                display: block;
                font-size: 0.8em;
                line-height: 1.8em;
                cursor: default;
            }
        }

        &.supp-email {
            a {
                text-decoration: none;
                display: block;
                font-size: 0.8em;
                line-height: 1.8em;
            }
        }
    }
}
