.sticky-buttons {
    display: none;
    position: fixed;
    left: 100%;
    top: 453px;
    z-index: 100;
    .sticky-button {
        position: relative;
        left: -46px;
        background: $color-grey3;
        color: $color-white;
        margin: 3px 0 0;
        transition: left 0.3s ease-in-out, background 0.2s ease-in-out;
        padding: 3px;
        border-radius: 10px 0 0 10px;
        min-height: 50px;

        &.disabled a {
            pointer-events: none;
            cursor: pointer;
        }

        &.hover {
            background: $color-light-green;
            left: -100%;
        }

        .fa {
            display: block;
            margin: 10px;
            font-size: 23px;
            float: left;

            &.fa-phone {
                margin-top: 9px;
                font-size: 30px;
            }
        }
        a {
            display: block;
            margin-left: 46px;
            padding-left: 5px;
            padding-right: 11px;
            color: $color-white;
            text-decoration: none;
            font-size: 0.75em;
            line-height: 1.6em;
            white-space: nowrap;
            span {
                display: block;
            }
            strong {
                display: block;
                font-size: 1.45em;
            }
        }
    }
    @include min-screen($screen__s) {
        .fonts-loaded &.initialized {
            display: block;
        }
    }
}
