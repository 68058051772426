// Login Pages
.login-container {
    margin-bottom: 40px;
    h2 {
        margin-bottom: 5px;
    }
    ul {
        margin-top: 20px;
    }
    li {
        font-size: $p__font-size__l;
    }
    p {
        font-size: $p__font-size__l;
    }
    .new-customer {
        background: $color-light-grey2 url(../images/assets/bgd-register.jpg) left bottom no-repeat;
        padding: 15px 20px 160px 15px;
        .actions-toolbar {
            > .primary {
                margin-left: 0;
            }
        }
    }
    .customer-login {
        button {
            margin-bottom: 20px;
        }
    }
}
.form-create-account {
    @include min-screen($screen__m) {
        width: 75%;
    }
    @include min-screen($screen__ml) {
        width: 50%;
    }
    .button {
        margin-bottom: 20px;
    }
}
// Customer Account -> .account is body class
.account {
    .columns {
        display: flex;
        flex-flow: column wrap;
    }
    .column.main {
        order: 1;
        .welcome-msg,
        .account-wrapper,
        .form-edit-account fieldset,
        .form-address-edit fieldset,
        .form-newsletter-manage fieldset,
        .actions-toolbar {
            padding: 0 10px 30px;
            border: 1px solid $color-border;
            border-top: none;
            margin: 0;
        }
        .welcome-msg,
        .form-edit-account fieldset,
        .form-address-edit fieldset,
        .form-newsletter-manage fieldset {
            border-bottom: none;
        }
        .block-dashboard-orders  {
            .block-title {
                border: 1px solid $color-border;
                border-top: none;
                padding: 0 10px;
            }
        }
        .table-wrapper {
            border-left: 1px solid $color-border;
            border-right: 1px solid $color-border;
            padding-bottom: 20px;
            @at-root .account.sales-order-view .table-wrapper {border-bottom: 1px solid $color-border;}
        }
        .actions-toolbar {
            padding: 20px 10px 30px;
            .page-print & {
                display: none;
            }
        }

        .box-title {
            .more {
               margin-left: 20px;
            }
        }
        .box {
            margin-bottom: 20px;
        }
        .block-title h3 {
            font-size: $heading__font-size__m;
            
        }
        .message {
            margin: 0;
        }
    }
    .sidebar-main {
        order: 0;
        width: 100%;
    }
    .page-title-wrapper.wrapper-content {
        width: 100%;
        padding: 20px 10px;
        border-left: 1px solid $color-border;
        border-right: 1px solid $color-border;
        @at-root .account.page-print .page-title-wrapper.wrapper-content {
            border: none;
            padding: 20px 0;
        }
        h1 {
            margin: 0;
        }
    }
    // refactor, DRY homepage tabs
    ul.account-tabs {
        width: 100%;
        display: flex;
        flex-flow: column nowrap;
        margin: 0;
        padding: 0;
        border: 1px solid $color-border;
        border-left: none;
        background: $color-light-grey2;
        @include min-screen($screen__s ) {
            flex-flow: row nowrap;
            height: 60px;
            border-left: 1px solid $color-border;
        }
        li {
            display: block;
            position: relative;
            padding: 10px 0 10px 20px; 
            margin: 0;
            border-left: 1px solid $color-border;
            @include min-screen($screen__s) {
                padding: 15px 14px 39px;
                border-left: none;
                border-top: 5px solid $color-light-grey2;
                border-right: 1px solid $color-border;
            }
            &.current {
                border-left: 5px solid $color-light-red;
                @include min-screen($screen__s) {
                    background: $color-white;
                    border-left: none;
                    border-top: 5px solid $color-light-red;
                    &:after {
                        content: '';
                        position: absolute;
                        top: 53px;
                        height: 5px;
                        width: 100%;
                        background: #fff;
                        left: 0;
                    }
                }
            }
        }
        a {
            text-decoration: none;
            font-weight: $font-weight__semibold;
        }
    }
}
// change order of items in account order detail
.sales-order-view .column.main {
    display: flex;
    flex-flow: column nowrap;
    .block-order-details-view {
        order: 0;
        border-left: 1px solid $color-border;
        border-right: 1px solid $color-border;
        padding: 0 10px;
        .block-title {
            margin-bottom: 10px;
        }
    }
    .block-order-additional-view {
        border-left: 1px solid $color-border;
        border-right: 1px solid $color-border;
        padding: 0 10px;
    }
    .order-details-items {
        order: 1;
        .order-title {
            border-left: 1px solid $color-border;
            border-right: 1px solid $color-border;
            padding: 20px 10px 10px;
        }
    }
    .block-content {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        .box-title {
            display: block;
            margin-bottom: 5px;
        }
        .box {
            width: 100%;
            font-size: $p__font-size__l;
            
            @include min-screen($screen__xxs) {
                width: 48%;
            }
            @include min-screen($screen__s) {
                width: 24%;
            }
            dl dt {
                font-weight: $font-weight__regular;
            }
        }
    }
}
