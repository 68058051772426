
.category-grid {
    margin-bottom: 20px;
    @include min-screen($screen__xs) {
        display: flex;
        flex-flow: row wrap;
    }
    li {
        @include min-screen($screen__xs) {
            margin-right: 10px;
        }
    }
    .btn-inverse {
        border-color: $color-red;
    }
}

.filter {
    width: 100%;
    margin-bottom: 20px;
    .filter-inner {
        border: 1px solid $color-border;
        background-color: $color-light-grey2;
    }
    &.active .filter-inner {
        border-bottom: none;
    }
    .block.filter {
        display: none;
        background: $color-white;
        border: 1px solid $color-border;
        padding: 30px 0 15px;
    }
    .block-layered-nav-inner {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        .layer-column {
            width: 100%;
            padding: 0 12px 30px;
            margin-bottom: 20px;
            border-bottom: 1px solid $color-border;

            &:last-child {
                border: none;
                margin: 0;
            }
            @include min-screen($screen__xs) {
                width: 49%;
                border: none;
            }
            @include min-screen($screen__m) {
                width: 24%;
                padding: 0 25px 10px 16px;
                border-right: 1px solid $color-border;
            }

            div {
                font-size: $p__font-size__l;
                margin-bottom: 5px;
                a {
                    text-decoration: none;
                }
                .available,
                .unavailable {
                    padding-left: 22px;
                    position: relative;
                    &:before {
                        position: absolute;
                        top: 0;
                        display: block;
                        margin-top: 1px;
                        width: 22px;
                        height: 22px;
                        content: '';
                        background-image: url(../images/assets/bgd-filter.png);
                        background-repeat: no-repeat;
                        background-position: 0 -300px;
                    }
                    &.active:before {
                        background-position: 0 -330px;
                    }
                }
                .unavailable {
                    color: $color-light-grey1;
                }
            }
        }
        .filter-category-breadcrumbs {
            padding: 0 25px 10px 16px;
        }
    }

    .showfilters {
        position: relative;
        display: block;
        padding: 10px 12px 11px;
        text-decoration: none;
        height: 45px;
        span {

            font-weight: $font-weight__semibold;
        }

        .icon-plus {
            position: absolute;
            right: 7px;
            top: 7px;
            text-align: center;

            &:before {
                margin: 0;
                line-height: 30px;
                text-align: center;
                font-size: 15px;
                text-indent: 0;
            }
        }

        &.active {
            display: block;

            .icon-plus:before {
                content: $icon-minus;
            }

            & + .block.filter {
                display: block;
            }
        }
    }
}

.block-subtitle {
    display: inline-block;
    margin-bottom: $indent__s;
}

.filter-current {
    .item {
        margin-bottom: $indent__s;
    }

    .filter-label {
        display: block;
        font-weight: $font-weight__bold;

        &:after {
            content: ':';
        }
    }

    + .filter-actions {
        margin-bottom: $indent__l;
    }
}

.filter-options-content {
    .filter-count-label {
        @include visually-hidden();
    }
}

.filter-options {
    display: flex;
    flex-flow: column wrap;
    margin: 0;

    .filter-item {

        width: 100%;
        min-height: 100px;
        padding: 0 15px;
        .filter-options-content {
            .item {
                a {
                    margin-bottom: 4px;
                    font-size: 0.875em;
                    text-decoration: none;
                }
            }
        }

        // removed temporary for demo until actual content/filters are imported

        &.size,
        &.climate,
        &.material,
        &.pattern {
            display: none;
        }

        &:last-child {
            border: 0;
        }
    }

    .filter-options-title {
        @include lib-heading(h4);
        margin: 0 0 $indent__s;
        word-break: break-all;
        font-size: 0.875em;
        text-transform: uppercase;
    }

    .filter-options-content {
        margin: 0 0 $indent__m;

        .item {
            margin-bottom: 3px;
        }
    }

    .count {
        &:before,
        &:after {
            position: relative;
            top: -1px;
        }

        &:before {
            content: '(';
        }

        &:after {
            content: ')';
        }
    }
}

.filtered {
    .items {
        margin: 15px 0;
    }

    .item {
        margin-bottom: 6px;
        padding-left: 22px;
        position: relative;

        .label {
            font-weight: $font-weight__bold;
        }

        .action.remove {
            left: -6px;
            position: absolute;
            top: 0;
        }
    }

    & + .actions {
        margin-bottom: 35px;
    }
}
