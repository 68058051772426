.fotorama-360-container {
    .product-360 {
        ._360grad-viewer {
            .fs-viewer-controls {
                height: 36px;

                li {
                    margin-bottom: 3px;

                    .fs-button {
                        box-shadow: none;

                        &:hover {
                            background: transparent;
                        }

                        svg {
                            width: 30px;
                            height: 30px;
                        }

                        .fs-icon {
                            stroke: $color-red;
                        }
                    }
                }
            }

            .fs-fullscreen-button {
                &:hover {
                    background: transparent;
                }

                .fs-icon {
                    stroke: $color-red;
                }
            }

            .fs-helpflex {
                width: 30px;
                height: 30px;
                right: 12px;
                left: inherit;
                box-shadow: none;
                margin-top: 3px;

                &:hover {
                    background: transparent;
                }

                &.fs-button.fs-help-button {
                    svg {
                        width: 30px;
                        height: 16px;

                        .fs-icon-fill {
                            fill: $color-red;
                        }
                    }
                }
            }

            .fs-viewer-image {
                top: 0 !important;
                height: 100% !important;
            }
        }
    }
}
