
.pagebuilder-mobile-hidden {
    display: none;
    @include min-screen($screen__m) {
        display: block;
    }
}

.pagebuilder-mobile-only {
    display: none;
    @include max-screen($screen__m) {
        display: block;
    }
}

.widget {
    &.block-products-list {
        &.grid {
            ol {
                display: grid;
                grid-template-columns: repeat(5, 1fr);
                @include max-screen($screen__m) {
                    grid-template-columns: repeat(3, 1fr);
                }
                @include max-screen($screen__s) {
                    grid-template-columns: repeat(2, 1fr);
                }
                padding-left: 0;
                li {
                    &.product-item {
                        width: 100%;
                        a {
                            &:hover {
                                text-decoration: underline;
                                color: $color-red;
                            }
                        }
                        .product-image-wrapper {
                            padding-bottom: 0 !important;
                        }
                    }
                }
            }
        }
    }
}

.tabbed-product-slider {
    &.widget-product-carousel {
        padding: 0;
        .slick-track {
            li {
                &.product-item {
                    border: 0;
                    width: 20%;
                    display: table-cell;
                    padding: 14px 14px 8px;
                    a {
                        &:hover {
                            text-decoration: underline;
                            color: $color-red;
                        }
                    }
                }
            }
        }
        ul {
            &.slick-dots {
                bottom: -25px;
            }
        }
    }
}

.ui-tabs {
    .ui-tabs-nav {
        overflow: hidden;
        margin-bottom: 10px;
        @include min-screen($screen__m) {
            float: left;
            margin-right: 10px;
        }
        .ui-tabs-tab {
            padding: 10px;
            margin-bottom: 1px;
            background: $color-light-grey2;
            @include max-screen($screen__m) {
                float: left;
                margin-right: 10px;
            }
            &.ui-tabs-active {
                a {
                    color: $color-red;
                    font-weight: bold;
                }
            }
            &:hover {
                background: $color-red;
                a {
                    color: $color-white;
                }
            }
            a {
                position: relative;
                text-decoration: none;
                span {
                    margin-left: 15px;
                    &:before {
                        content: '\00bb';
                        margin-right: 3px;
                        position: absolute;
                        top: -1px;
                        left: 3px;
                    }
                }
            }
        }
    }
}


.pagebuilder-banner-main-wrapper {
    a {
        text-decoration: none;
    }
    .pagebuilder-banner-button {
        @extend .btn-big;
        @extend .button;

        &.pagebuilder-button-primary {
            @extend .btn-red;
        }

        &.pagebuilder-button-secondary {
            @extend .btn-green;
        }
    }

    .pagebuilder-banner-wrapper {
        .pagebuilder-overlay {
            h1 {
                @extend .h1.light-headline;
                text-align: left;
            }
        }
    }
}

.pagebuilder-buttons-main-wrapper {
    .pagebuilder-button-primary {
        @extend .btn-big;
        @extend .button;
        @extend .btn-red;
    }

    .pagebuilder-button-secondary {
        @extend .btn-big;
        @extend .button;
        @extend .btn-green;
    }
}
.pagebuilder-video-wrapper {
    .pagebuilder-video-container {
        iframe {
            aspect-ratio: 1 / 1;
            width: 100%;
        }
    }
}

.sticky-wrapper {
    &[data-element=inner] {
        height: auto;
    }
}
div[data-content-type="list"] {
    ul {
        li {
            margin-left: 25px;
            position: relative;
            &:before {
                width: 25px;
                height: 25px;
                position: absolute;
                top: 1px;
                left: -25px;
                background: url('../images/assets/icn-check_green.png') center right 5px no-repeat;
                background-size: 20px 20px;
                content: ""
            }
        }
    }
}

.accordion-item {
    .accordion-item-collapsible,
    [data-role='collapsible'] {
        padding: 1rem 15px!important;
        position: relative;
        cursor: pointer;
        -ms-user-select: None;
        -moz-user-select: None;
        -webkit-user-select: None;
        user-select: None;

        &::before {
            left: -15px;
            top: -2px;
            position: relative;
            content: '›';
            display: inline-block;
        }

        &[aria-expanded='true']::before {
            transform: rotate(90deg);
        }
    }
    div[data-role='content'] {
        margin-left: 16px;
    }
}

figure[data-content-type=image][data-appearance=full-width] > img {
    width: 100%;
}

@include max-screen($screen__s) {
    .pagebuilder-column-group {
        display: block !important;

        > .pagebuilder-column {
            display: block !important;
            width: 100% !important;
        }
    }
}