//
//  Typography
//  _____________________________________________

h1,
.h1 {
    @include min-screen($screen__xs) {
        font-size: $heading__font-size__xl;
    }


    &.legend {
        border-bottom: 1px solid $color-border;
        margin: 15px 0 15px;
        padding: 4px 0;
    }

    &.light-headline {
        font-size: $heading__font-size__xxl;
        font-weight: $font-weight__light;
        text-transform: uppercase;
        color: $color-blue;
        text-align: center;

        &.white {
            color: $color-white;
        }
    }
}

h2,
.h2 {
    font-size: $heading__font-size__l;
    line-height: $line-height__s;
    margin-bottom: 15px;

    &.legend {
        border-bottom: 1px solid $color-border;
        margin: 15px 0 15px;
        padding: 4px 0 15px;

        .legend__inherit {
            display: inline-block;
            font-size: inherit;
        }
    }

    &.light-headline {
        font-size: $heading__font-size__xxl;
        font-weight: $font-weight__light;
        text-transform: uppercase;
        color: $color-blue;
        text-align: center;

        &.white {
            color: $color-white;
        }
    }
}

h3 {
    font-size: $p__font-size__xl;
    line-height: $line-height__l;
    margin-bottom: 5px;

    &.legend {
        border-bottom: 1px solid $color-border;
        margin: 15px 0 5px;
        padding: 4px 0;
    }

    &.with-bg {
        font-size: $heading__font-size__l;
        background: $color-light-green;
        text-transform: uppercase;
        font-weight: $font-weight__light;
        color: #fff;
        text-align: center;
        line-height: $line-height__xl !important;

        a {
            color: #fff;
            font-weight: $font-weight__light;

            &:hover {
                text-decoration: none;
            }
        }
    }

    &.light-headline {
        font-size: $heading__font-size__l + 12px;
        font-weight: $font-weight__light;
        text-transform: uppercase;
        text-align: center;
        margin-bottom: 20px;

        &.white {
            color: $color-white;
        }
    }
}

h4 {
    font-size: $p__font-size__l;
    margin-bottom: 5px;
}

h5 {
    font-size: $p__font-size__l;
    font-weight: $font-weight__bold;
    text-transform: uppercase;
    line-height: 1;
    margin-bottom: 10px;
}

h1 strong,
h1 a,
h2 strong,
h2 a,
h3 strong,
h3 a,
h4 strong,
h4 a,
h5 strong,
h5 a {
    font-weight: $font-weight__semibold;
    text-decoration: none;
}

p {
    font-size: $p__font-size__l;
    &.small {
        font-size: $p__font-size__l;
        margin-bottom: 10px;
    }

    &.text--white {
        color: $color-white;
    }

    &.required {
        color: $color-red;
        font-size: $p__font-size__m !important;
        margin-top: -15px;
        &.required--wide {
            @include min-screen($screen__xs) {
                width: 50%;
                margin-top: 0;
            }
        }
    }
}
address {
    font-size: $p__font-size__l;
} 
.cms-page-view .column.main ul:not([class]) li:not([class]) {
    font-size: $p__font-size__l;
    list-style: disc;
    margin-left: 17px;
}

.more {
    font-size: $p__font-size__l;
    text-decoration: none;

    span {
        text-decoration: underline;
        font-weight: $font-weight__regular;
    }

    &:before {
        content: '\00bb';
        margin-right: 3px;
    }

    &:visited {
        text-decoration: none;
    }
}

dl {
    margin: 0;

    dd {
        display: inline;
        margin: 0;

        &:after {
            display: block;
            content: '';
        }
    }

    dt {
        font-weight: $font-weight__semibold;
        display: inline-block;
        
    }
    &.info-list {
        dt {
            display: block;
            margin: 0;
        }
        dd {
            display: block;
            margin-bottom: 20px;
            font-size: $p__font-size__l;
        }
    }
}

.phone-link {
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }
}
