.nav-dropdown {
    left: 0;
    -webkit-overflow-scrolling: touch;  // Momentum Scrolling
    @include min-screen($screen__m) {
        display: flex;
        flex-flow: column nowrap;
    }
    * {
        -webkit-transform: translate3d(0, 0, 0); // Momentum Scrolling Fix
    }

    .nav-level-1 {
        margin: 0;
        @include min-screen($screen__m) {
            order: 1;
        }
        .main-cat {
            display: block;
            font-weight: $font-weight__semibold;
            font-size: $p__font-size__xl;
            margin-bottom: 12px;
            text-decoration: none;
            border-bottom: 1px solid $color-border;
            padding: 2px 0 12px 5px;
            @include min-screen($screen__m) {
                border-bottom: none;
                padding: 0;
            }
            @include max-screen($screen__m) {
                cursor: pointer;
            }
            &.is-active {
                border-bottom: none;
            }
            &:after {
                display: none;
                content: "\f078";
                float: right;
                margin-right: 10px;
                @include min-screen($screen__m) {
                    display: none;
                }
            }
            &.is-active:after {
                content: "\f077";
            }
            &.main-cat--link {
                @include min-screen($screen__m) {
                    display: none;
                }
                &:after {
                    display: none;
                }
            }

        }
        li {
            margin: 0;
            @include min-screen($screen__m) {
                float: left;
                width: 25%;
                padding: 0 15px;
            }
            &.new-cat {
                span {
                    position: relative;
                    &:after {
                        position: absolute;
                        right: -43px;
                        top: 0;
                        display: block;
                        width: 34px;
                        height: 19px;
                        border-radius: 11px;
                        border: 1px solid $color-green;
                        padding-top: 3px;
                        content: 'NEU';
                        font-family: Arial, 'Helvetica Neue', 'Helvetica', sans-serif;
                        font-size: $p__font-size__xs - 1px;
                        font-weight: $font-weight__bold;
                        text-align: center;
                        color: $color-green;
                    }
                }
            }
            .link {
                padding: 7px 0;
                width: 100%;
                display: block;
                text-decoration: none;
                font-size: $p__font-size__l;
                &:before {
                    content: '\00bb';
                    margin-right: 3px;
                }
            }
        }
    }
    .nav-level-2 {
        display: none;
        @include min-screen($screen__m) {
            display: block;
        }
        &.is-active {
            display: block;
            margin-bottom: 20px;
            @include max-screen($screen__m) {
                margin-top: -10px;
            }
        }
        li {
            display: block;
            width: 100%;
            border-bottom: 1px solid $color-border;
            padding: 0;
            @include min-screen($screen__m) {
                &:first-child {
                    border-top: 1px solid $color-border;
                }
            }
        }
    }
    .nav-special-wrapper {
        margin: -10px 0 0;
        @include min-screen($screen__m) {
            order: 0;
            display: flex;
            flex-flow: row nowrap;
            border-bottom: 1px solid $color-border;
            margin-bottom: 20px;
        }
        .nav-special {
            position: relative;
            border-bottom: 1px solid $color-border;
            font-weight: $font-weight__semibold;
            margin: 0;
            @include min-screen($screen__m) {
                border-bottom: none;
                width: 25%;
                padding: 0 15px;
            }
            &:last-child {
                border-bottom: none;
            }
            .icn-round-menu {
                top: 11px;
                padding-top: 3px;
                @include min-screen($screen__m) {
                    top: 14px;
                    left: 11px;
                }
            }
            a {
                display: block;
                text-decoration: none;
                padding: 13px 0 14px 40px !important;
                @include min-screen($screen__m) {
                    padding: 18px 0 16px 40px !important;
                }
            }
        }
    }
}
.customer-nav {
    margin-bottom: 0;
    li {
        text-align: right;
        border-bottom: 1px solid $color-border;
        padding: 10px 0;
        &:last-child {
            border-bottom: none;
        }
        a {
            padding: 0 15px;
            text-decoration: none;
        }
    }
    .inactive {
        color: $color-light-grey1;
        pointer-events: none;
    }
}
#horizontal-product-navigation-container {
    width: 100%;
    text-align: right;
}
.horizontal-product-navigation {
    margin-top: 15px;
    .horizontal-link {
        padding-left: 10px;
        padding-right: 10px;
    }
}

html.fonts-loaded {
    .nav-dropdown {
        .nav-level-1 {
            .main-cat {
                &:after {
                    display: block;
                    font-family: 'FontAwesome';
                    @include min-screen($screen__m) {
                        display: none;
                    }
                }
            }
        }
    }
}
