//
//  Fonts loaded
//  _____________________________________________

html.fonts-loaded {
  body,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  h1 small,
  h1 .small,
  h2 small,
  h2 .small,
  h3 small,
  h3 .small,
  h4 small,
  h4 .small,
  h5 small,
  h5 .small,
  h6 small,
  h6 .small,
  blockquote,
  .filter-options .filter-options-title,
  #designer-sidebar .designer-tab-content button,
  .fancybox-infobar__body,
  .fancybox-error p,
  .fancybox-caption {
    font-family: $font-family__base-loaded;
  }

  @import "../vendor/font-awesome/scss/core";
}
