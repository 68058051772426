.minicart-wrapper {
    height: auto !important;
}
.minicart-items-wrapper {
    height: auto !important;
}
.minicart-messages {
    .directorder-index-index & {
        display: none;
    }
    .wrapper-content {
        padding: 0;
    }
    .message {
        margin: 0;
        padding: 10px 20px 10px 40px;
    }

}
.minicart-content-wrapper {
    font-size: $p__font-size__l;
    .subtitle.empty {
        margin-top: 10px;
    }
    .block-content {
        padding: 10px 10px 10px 20px;
    }
    .minicart-items {
        padding: 0;
        margin: 10px 0 20px;
        .product-item {
            list-style-type: none;
            border-bottom: 1px solid $color-border;
            padding-bottom: 10px;
            display: flex;
            flex-flow: row nowrap;
            .product-item-color {
                display: flex;
                span {
                    display: block;
                    margin-top: 8px;
                }
            }
        }
        .product-item-photo-wrapper {
            width: calc(20% - 10px);
            margin-right: 10px;
        }
        .product-item-details {
            width: calc(53% - 20px);
            padding-right: 20px;
            a {
                text-decoration: none;
                font-weight: $font-weight__semibold;
            }
        }
        .details-qty {
            width: calc(22% - 20px);
            padding-right: 20px;
        }
        .product-item-pricing {
            width: calc(10% + 50px);
            font-size: $p__font-size__xl;
            font-weight: $font-weight__semibold;
            text-align: right;
        }
    }
    .actions {
        display: flex;
        flex-flow: column nowrap;
        justify-content: space-between;
        @include min-screen($screen__xxs) {
            flex-flow: row nowrap;
        }
        .primary,
        .secondary {
            width: auto;
            @include min-screen($screen__xxs) {
                width: 48%;
            }
        }
        .button {
            width: 100%;
            margin-bottom: 10px;
        }
    }
    .amazon-button-container {
        margin-top: 5px;
        .amazon-or-checkout-now {
            display: none;
        }
    }
}
