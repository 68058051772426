.message {
    position: relative;
    margin-bottom: 30px;
    padding: 10px 20px 10px 35px;
    background-color: $color-light-green;
    font-weight: $font-weight__semibold;
    font-size: $font-size__s;
    color: $color-white;
    .cms-home-page & {
        margin-top: 30px;
    }
    &:before {
        display: none;
        position: absolute;
        left: 10px;
        top: 11px;
        color: $color-white;
    }
    &.info {
        background-color: $color-yellow;
        &:before {
            content: "\f0eb";
        }
    }
    &.notice {
        background-color: $color-yellow;
        &:before {
            content: "\f0eb";
        }
    }
    &.error {
        background-color: $color-light-red;
        &:before {
            content: "\f071";
        }
    }
    &.warning {
        background-color: $color-yellow;
        color: $color-grey3;
        &:before {
            content: "\f071";
            color: $color-grey3;
        }
    }
    &.success {
        &:before {
            content: "\f00c";
        }
    }
    a {
        color: inherit;
    }
}
html.fonts-loaded {
    .message {
        &:before {
            display: block;
            font-family: 'FontAwesome';
        }
    }
}