#eKomiVFreport-fixed {
    top: 75% !important;
    display: none;
    @include min-screen($screen__xl) {
        display: block !important;
    }
    .page-print & {
        display: none !important;
    }
}

.ekomi-product-reviews {
    width: 100%;
    order: 3;
    @include min-screen($screen__s) {
        order: 4;
    }
    .rgrade {
        margin-top: 20px;
    }
    .ekomi-average-rating {
        .stars {
            margin-top: -2px;
        }
        label {
            font-size: $heading__font-size__m;
            font-weight: $font-weight__semibold;
        }
    }
    .rating-summary {
        font-size: $p__font-size__l;
        color: $color-grey2;
        margin-top: 5px;
    }
    .rating-info-bar {
        display: flex;
        flex-flow: column nowrap;
        @include min-screen($screen__xs) {
            flex-flow: row nowrap;
            margin-bottom: 30px;
        }
        .rating-bars {
            width: 235px;
            margin-bottom: 20px;
            @include min-screen($screen__xs) {
                margin-bottom: 0;
                margin-right: 50px;
            }
        }
        .rating-row {
            display: flex;
            flex-flow: row nowrap;
            justify-content: flex-end;

            margin-bottom: 3px;
            font-size: $p__font-size__s;
            label {
                margin-right: 10px;
                &:nth-child(3) {
                    width: 35px;
                    text-align: right;
                }
            }
        }
        .rating-bar {
            width: 100px;
            height: 14px;
            margin: 5px 10px 0;
            background-color: $color-light-grey4;
        }
        .rating-bar-percentage {
            background-color: $color-yellow;
            height: 14px;
        }
        .block-rating-info {
            font-size: $p__font-size__m;
            ul {
                margin-top: 20px;
                @include min-screen($screen__xs) {
                    margin-top: 0;
                    margin-left: 20px;
                }
            }
            li {
                margin-bottom: 0;
            }
        }
    }
    .reviews {
        color: $color-grey2;
        width: 100%;
        li {
            padding: 15px 0;
            margin-bottom: 0;
            border-bottom: 1px solid $color-border;
            &:last-of-type {
                border: none;
            }
        }
        .rating-box {
            margin-bottom: 5px;
        }
        .date {
            font-size: $p__font-size__m;
            line-height: 15px;
        }
        .stars-percentage {
            margin-top: -22px;
        }
        .stars:before,
        .stars-percentage:before {
            font-size: $p__font-size__l;
        }
        p {
            margin: 0;
            font-size: $p__font-size__l;
            color: $color-grey2;

            &:before {
                content: '\201E';
            }

            &:after {
                content: '\201D';
            }
        }
    }
}
