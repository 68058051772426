.badges-list {
    position: absolute;
    margin-bottom: 0;
    z-index: 1;
    list-style: none;
    &.badges-list--right {
        top: 10px;
        right: 15px;
        .products-list & {
            right: 0;
        }
        .amsearch-item & {
            top: 2px;
            right: 0;
        }
    }
    &.badges-list--left {
        top: 15px;
        left: 15px;
        .amsearch-item & {
            top: 2px;
            left: 0;
        }
    }
}
.badge {
    width: 33px;
    height: 33px;
    overflow: hidden;
    display: inline-block;
    .product.media & {
        width: 45px;
        height: 45px;
        &.badge--sustainable {
            .icn-sustainable {
                background-position: -6px 4px;
            }
        }
    }

    &.badge--sale,
    &.badge--new,
    &.badge--recommendation,
    &.badge--topseller {
        @include icn-round-bordered (33px, 33px, $color-light-red, $color-white);
        line-height: 2.1;
        background-color: $color-light-red;
        color: $color-white;
        .product.media & {
            @include icn-round-bordered (45px, 45px, $color-light-red, $color-white);
            line-height: 1.9;
            background-color: $color-light-red;
            color: $color-white;
            font-size: $p__font-size__xxl;
        }
        .amsearch-item & {
            width: 18px;
            height: 18px;
        }
    }
    &.badge--sustainable {
        .icn-sustainable {
            width: 100%;
            height: 100%;
        }
        .tabbed-product-slider-sustainable & {
            display: none;
        }
    }
    &.badge--sale {
        line-height: 2;
        .product.media & {
            line-height: 2.1;
        }
        .amasty-xlanding-sale-products & {
            display: none;
        }
        .amsearch-item & {
            line-height: 1.6;
            .icn-sale {
                font-size: $p__font-size__xs;
            }
        }
    }
    &.badge--new {
        line-height: 3.2;
        .amasty-xlanding-neuheiten &,
        .tabbed-product-slider-is-new & {
            display: none;
        }
        .amsearch-item & {
            .icn-new {
                line-height: 2.4;
                font-size: $p__font-size__xs - 3px;
            }
        }
    }

    &.badge--recommendation {
        line-height: 2.1;
    }
    &.badge--topseller {
        line-height: 1.6;
        font-size: 18px;
        .amasty-xlanding-topseller &,
        .tabbed-product-slider-topseller & {
            display: none;
        }
        .amsearch-item & {
            line-height: 1.4;
            font-size: 11px;
        }
    }
    &.badge--pricehit,
    &.badge--advertismentTip,
    &.badge--digitalprint,
    &.badge--tvAdvertisment {
        width: 45px;
        height: 45px;
        .product.media & {
            width: 60px;
            height: 60px;
        }
        .amsearch-item & {
            width: 22px;
            height: 22px;
            margin-bottom: 2px;
        }
    }
    &.badge--pricehit {
        background: $iconsprite no-repeat 45% 1%;
        background-size: 144%;
        .product.media & {
            background-size: 148%;
        }

    }
    &.badge--advertismentTip {
        background: $iconsprite no-repeat 41% 21.5%;
        background-size: 144%;
        .product.media & {
            background-size: 148%;
        }
        .amsearch-item & {
            background-size: 160%;
        }
    }
    &.badge--digitalprint {
        background: $iconsprite no-repeat 41% 98.3%;
        background-size: 138%;
        .product.media & {
            background-size: 136%;
        }
        .amsearch-item & {
            background-size: 100%;
        }
    }
    &.badge--tvAdvertisment {
        background: $iconsprite no-repeat 40% 32%;
        background-size: 144%;
        .product.media & {
            background-size: 141%;
        }
        .amsearch-item & {
            background-size: 180%;
        }
    }
}
