// sass-lint:disable gap
@function giftfinder-color($color-name) {
    @return var(--theme-color-#{$color-name});
}

.xlanding-widget-giftfinder {
    margin-top: 25px;
    margin-bottom: 60px;
    min-height: 272px;

    .GiftFinder {
        --theme-color-primary-600: #{$color-red};
        --theme-color-primary-300: #{lighten($color-red, 40%)};
        --theme-color-primary-50: #{lighten($color-red, 50%)};
        counter-reset: step;
        .QuizPicks {
            padding-bottom: 50px;
            margin-bottom: 0;
            @include min-screen($screen__xl) {
                overflow: hidden;
            }
        }

        .QuizSteps {
            background-color: #f6f6f6;
            border: 1px solid #c4c4c4;
            padding: 20px;
            padding-bottom: 5px;
            @include max-screen($screen__xs) {
                padding: 10px;
                padding-left: 3px;
            }
        }

        .QuizRecs__Title {
            margin: 50px 0 65px;
        }

        .Steps {
            --steps-color-dark: #ddd;
            --steps-color-light: #ddd;
            .Step {
                margin-bottom: 5px;
                .Step__TailShadow {
                    //position: relative;
                    &:before {
                        content: "\A";
                        border-style: solid;
                        border-width: 4px;
                        border-color: #c4c4c4 transparent transparent transparent;
                        position: absolute;
                        left: -3px;
                        bottom: 0;
                        z-index: 1;
                    }
                }
                .Step__Icon {
                    line-height: 26px;
                    background-color: #333333;
                    border-radius: 16px;
                    color: #fff;
                    padding: 11px;
                    height: 30px;
                    width: 30px;
                    line-height: 24px;
                    margin: 0 10px 0 2px;
                    &:before {
                        counter-increment: step;
                        content: counter(step);
                        box-shadow: inset 0 0 0 2px var(--steps-color-icon);
                        border-radius: 50%;
                        height: 30px;
                        width: 30px;
                        padding-top: 3px;
                        font-size: 14px;
                        line-height: 24px;
                    }

                    & > div {
                        display: none;
                    }
                }
                .Wrap {
                    gap: 5px;
                }
                .Tags {
                    --tags-color-text-subdued: #333;
                    --tags-color-text: #fff;
                    --tags-color-button-subdued: #fff;
                    --tags-color-button: var(--theme-color-primary-600);
                    --tags-color-button-border-shadowy: #eee;
                    --tags-color-button-border-subdued: #8a8a8a;
                    --tags-color-button-border: var(--theme-color-primary-600);
                    .Tag {
                        border-radius: 2px;
                        font-size: 16px;
                        height: 36px;
                        padding: 0 10px;
                        font-weight: normal;
                        &:hover {
                            color: var(--theme-color-primary-600);
                        }
                        &.Tag--active {
                            &:hover {
                                color: var(--tags-color-text);
                            }
                        }
                        &.Tag--image {
                            height: 200px;
                        }
                    }
                }
                .Step__Content {
                    padding-bottom: 20px;
                    .Step__TitleWrapper {
                        align-items: center;
                        min-height: 30px;
                        margin-bottom: 4px;
                        .Step__Title {
                            font-weight: 600;
                            line-height: 22px !important;
                            padding-right: 8px;
                        }
                    }

                }
                &.Step--wait {
                    .Step__Icon {
                        background-color: #c4c4c4;
                        &:before {
                            box-shadow: none;
                        }
                    }
                }
                .Step__Description {
                    padding: 12px 0 12px !important;
                }
                &.Step-topic {
                    .Wrap {
                        gap: 7px;
                    }
                }
                &.Step-topic .Tag.Tag--image {
                    border-color: #D3D3D3;
                    box-shadow: none;

                    &.Tag--active {
                        border: 2px solid #CD0D1A;
                    }
                }
                &.Step-recommendations {
                    .Step__Content {
                        padding-bottom: 0;
                    }
                    .Step__Description {
                        display: none;
                    }
                    .Step__Soffix {
                        display: none;
                    }
                }

            }
        }
    }

    .NanaProductCard.ProductCard {
        .ProductCard__AddToCartButton {
            display: none;
        }
        .ProductCard__Content {
            .ProductCard__Title {
                text-align: left;
                font-size: 16px !important;

            }
            .ProductCard__Prices {
                justify-content: left;
                font-size: 20px;
                margin: 5px 0;
            }
        }

    }

    .ProductSlider {
        .ProductSwiper {
            --swiper-pagination-bullet-size: 12px;
            --swiper-pagination-bullet-horizontal-gap: 5px;

            .swiper {
                margin-bottom: 25px;
            }

            .swiper-pagination {
                bottom: -40px;
                --swiper-pagination-bullet-size: 15px;
                .swiper-pagination-bullet {
                    background-color: #f6f6f6;
                    border: 1px solid #1b1b1b;
                    margin: 0 3px;
                }
                .swiper-pagination-bullet-active {
                    background-color: var(--theme-color-primary-600);
                    border: 1px solid var(--theme-color-primary-600);
                }
            }

            .swiper-button-prev,
            .swiper-button-next {
                @include icn-round-bordered(45px, 45px, $color-border, rgba(255, 255, 255, 0.95));
                position: absolute;
                display: block;
                cursor: pointer;
                top: 56%;
                transform: translate(0, -50%);
                padding: 0;
                outline: none;
                z-index: 1;
                font-size: 0;
                &:hover,
                &:focus {
                    background: $color-white;
                    border: 1px solid $color-border;
                }

            }
            .swiper-button-prev {
                left: 0;
                .touch & {
                    left: -20px;
                }
            }
            .swiper-button-next {
                right: 0;
                .touch & {
                    right: -20px;
                }
            }
            .swiper-button-prev:before,
            .swiper-button-next:before {
                position: absolute;
                left: 15px;
                top: 8px;
                content: "\e801";
                font-family: $icons__font-name;
                font-size: 20px;
                color: $color-grey4;
            }

            .swiper-button-prev:before {
                content: "\e802";
                left: 14px;
            }

            .swiper-button-prev:hover:before,
            .swiper-button-next:hover:before {
                color: $color-grey1;
            }
            .swiper-button-prev:after,
            .swiper-button-next:after {
                display: none;
            }
        }
    }

    .ScrollDownIndicator {
        --arrow-color: #80bb3f;
        margin-top: 20px;
        margin-bottom: 10px;
        border-left-width: 23px;
        border-right-width: 23px;
        border-top-width: 15px;
    }

    .GiftGrid,
    .ProductGrid {
        grid-template-columns: repeat(auto-fit, minmax(200px, max-content));
        margin-top: 20px;
    }

    .ProductCard {
        outline: 1px solid #d3d3d3;
    }
}
