.fieldset {
    border: none;
    padding: 0;
    margin: 0 0 20px;
    .legend {
        width: 100%;
        float: left;
        margin: 0 0 20px;
        padding: 0 0 10px;
        border-bottom: 1px solid $color-border;
        font-size: $heading__font-size__l;
        font-weight: $font-weight__semibold;
        + * {
            clear: both;
        }
    }
    .field {
        margin-bottom: 20px;
    }
    .inputrow {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        .field {
            width: 100%;
            @include min-screen($screen__xs) {
                width: 48%;
            }
        }
    }
    .mage-error {
        font-size: $p__font-size__m;
        color: $color-dark-red;
        margin-top: 3px;
        &:before {
            font-family: $icons__font-name;
            content: '\f176';
            margin-right: 5px;
        }
    }
}
.password-strength-meter {
    font-size: $p__font-size__m;
    .password-weak & {
        color: $color-dark-red;
    }
    .password-medium & {
        color: $color-orange;
    }
    .password-strong &,
    .password-very-strong & {
        color: $color-green;
    }
}
label,
.label {
    font-size: $p__font-size__l;
    font-weight: $font-weight__semibold;
}
.required .label:after,
._required .label:after {
    display: inline-block;
    content: '* Pflichtfeld';
    color: $color-dark-red;
    margin-left: 3px;
    font-size: $p__font-size__xs - 1px;
    transform: translateY(-8px);
}
.required-fields {
    margin: 10px 0;
}
input[type="text"],
input[type="password"],
input[type="url"],
input[type="tel"],
input[type="search"],
input[type="number"],
input[type="datetime"],
input[type="email"],
textarea {
    width: 100%;
    padding: 9px 8px;
    font-size: $p__font-size__l;
    color: $color-grey1;
    border: 1px solid $color-border;
    background-color: $color-white;
    text-align: left;
    outline: none;
    @include inset-drop-shadow-top-left;
    &.mage-error {
        border: 1px dashed $color-light-red;
        background: #faebe7;
    }
}
input[type="radio"],
input[type="checkbox"] {
    margin-right: 10px;
    cursor: pointer;
    + label {
        cursor: pointer;
    }
}
select {
    padding: 9px 8px;
    font-size: $p__font-size__l;
    color: $color-grey1;
    border: 1px solid $color-border;
    background-color: $color-white;
    border-radius: 2px;
    height: 40px;
    padding: 6px 50px 6px 10px;
    @include inset-drop-shadow-top-left;
    appearance: none;
    background-image: linear-gradient(to right, $color-border, $color-border), linear-gradient(45deg, transparent 50%, $color-grey4 50%), linear-gradient(135deg, $color-grey4 50%, transparent 50%);
    background-position: right 40px top,
    calc(100% - 20px) 17px,
    calc(100% - 15px) 17px;
    background-size: 1px 100%, 5px 5px, 5px 5px;
    background-repeat: no-repeat;
}
select::-ms-expand {
    display: none;
}
.form-inline {
    position: relative;
    @include min-screen($screen__xs) {
        display: flex;
        flex-flow: row wrap;
        align-items: flex-start;
    }
    input {
        @include min-screen($screen__xs) {
            padding: 11px 8px 12px;
            border-right: none;
            max-width: 290px;
            order: 0;
        }
    }
    .button {
        margin-top: 10px;
        width: 100%;
        @include min-screen($screen__xs) {
            margin: 0;
            width: auto;
            border-radius: 0 2px 2px 0;
            padding: 12px 50px;
            order: 1;

        }
    }
    #search_sku-error {
        width: 100%;
        @include min-screen($screen__xs) {
            order: 2;
        }
    }
}
// form styling for opc
.onestepcheckout-index-index {
    .field-tooltip-content {
        margin-top: 5px;
        font-size: $p__font-size__m;
        font-weight: $font-weight__semibold;
        color: $color-grey2;
    }
    #tooltip-label, .field-tooltip .label {
        display: none;
    }
    .field.mp-12 {
        @include min-screen($screen__xs) {
            width: 100%;
            float: left;
            input {
                width: 98%;
            }
            input[type="checkbox"] {
                width: auto;
            }
        }
    }
    .field.mp-6 {
        @include min-screen($screen__xs) {
            width: 50%;
            float: left;
            &:after {
                display: block;
                content: "";
                clear: both;
            }
            input {
                width: 96%;
            }
        }
        .message {
            margin: 10px 0 0;
            @include min-screen($screen__xs) {
                width: 96%;
            }
        }
    }
    div[name="shippingAddress.custom_attributes.salutation"],
    div[name="billingAddress.custom_attributes.salutation"] {
        width: 50%;
    }
    .field.osc-password,
    .field.confirmation {
        @include min-screen($screen__xs) {
            float: none;
            width: 100%;
        }
    }
    fieldset.field.street {
        border: none;
        padding: 0;
        margin: 0;
        .field._required label,
        .field.additional {
            display: none;
        }
    }

    .field input:valid:not(:placeholder-shown),
    .field input.valid {
        background: #fff url('../images/assets/icn-check.gif') center right 5px no-repeat;
        background-size: 25px 25px;
    }
    .field._error input {
        border: 1px dashed $color-light-red;
        background: #faebe7 !important;
    }

    .field-error {
        font-size: $p__font-size__m;
        color: $color-dark-red;
        margin-top: 3px;
        &:before {
            font-family: $icons__font-name;
            content: '\f176';
            margin-right: 5px;
        }
    }
}
// form styling for cms newsletter
.cms-newsletter {
    .column.main {
        p,
        .standard-list {
            font-size: $p__font-size__l;
        }
        .standard-list {
            list-style-type: disc;
            padding-left: 20px;
        }
        .required {
            em {
                display: none;
            }
            &:after {
                content: '*';
                color: $color-dark-red;
                margin-left: 3px;
            }
        }
        .fields {
            @include min-screen($screen__xs) {
                display: flex;
                flex-flow: row nowrap;
                justify-content: space-between;
            }
            .field {
                @include min-screen($screen__xs) {
                    width: 48.5%;
                }
            }
        }
    }
}

.form.contact {
    .actions-toolbar {
        border-top: 1px solid $color-border;
        padding-top: 20px;
    }
}

.carddesigner {
    .field.configurable {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        .label {
            font-size: $p__font-size__xl;
            width: 78px;
        }
        .control {
            width: calc(100% - 78px);
            select {
                width: 100%;
            }
        }
    }
}
